import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import NavigationTabs, { NavigationTab } from 'src/components/NavigationTabs';
import TabPanel from 'src/components/TabPanel';
import { IDashboardParams } from 'src/interfaces/IEditParams';

import GoalsTab from './GoalsTab';
import OpportunitiesTab from './OpportunitiesTab';

const Dashboard: FC = () => {
  const params = useParams<IDashboardParams>();

  const GoalsTabs: NavigationTab[] = [
    { name: 'Oportunidades', slug: '', Component: OpportunitiesTab },
    {
      name: 'Metas',
      slug: 'metas',
      Component: GoalsTab,
    },
  ];

  return (
    <Container maxWidth={false} sx={{ py: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NavigationTabs baseUrl="/dashboard" tabs={GoalsTabs} />
        </Grid>

        <Grid item xs={12}>
          {GoalsTabs.map(({ name, slug, Component }) => (
            <TabPanel key={name} show={slug === (params.selectedTab || '')}>
              <Component />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
