export const COMMON_QUERY_KEYS = {
  DASHBOARD: 'common-dashboard',
  PERMISSIONS: 'common-permissions',
  ROLES: 'common-roles',
  USERS: 'common-users',
  DEPARTMENTS: 'common-departments',
  LEADS: 'common-leads',
};

export const ADMIN_QUERY_KEYS = {
  PERMISSIONS: 'admin-permissions',
  ROLES: 'admin-roles',
  USERS: 'admin-users',
  DEPARTMENTS: 'admin-departments',
  LEADS: 'admin-leads',
};
