import { combineReducers } from 'redux';
import attendance from 'src/modules/attendance/store';

import app from './app';
import auth from './auth';
import dashboard from './dashboard';

const reducers = combineReducers({
  app,
  auth,
  dashboard,
  attendance,
});

export default reducers;
