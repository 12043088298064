import { Typography } from '@mui/material';
import { GridColTypeDef } from '@mui/x-data-grid';
import { formatDate } from 'src/utils/helpers';
import masks from 'src/utils/masks';
import { fShortenNumberInt } from 'src/utils/number';

export const columnDate: GridColTypeDef = {
  type: 'date',
  minWidth: 120,
  headerAlign: 'center',
  align: 'center',
  valueFormatter: ({ value }) => formatDate(value, 'dd/MM/yyyy'),
};

export const columnDateTime: GridColTypeDef = {
  type: 'datetime',
  minWidth: 120,
  headerAlign: 'center',
  align: 'center',
  renderCell({ value }) {
    return (
      <div>
        <Typography variant="body2" align="center">
          {formatDate(value, 'dd/MM/yyyy')}
        </Typography>
        <Typography variant="body2" align="center">
          {formatDate(value, 'HH:mm')}
        </Typography>
      </div>
    );
  },
};

export const columnPercent: GridColTypeDef = {
  type: 'percent',
  valueFormatter: ({ value }) => {
    const percent = Number(value) / 100;
    return `${percent || 0}%`;
  },
};

export const columnPhone: GridColTypeDef = {
  type: 'phone',
  valueFormatter: ({ value }) => {
    return masks.phoneOrCellphone(value ? String(value) : '');
  },
};

export const columnCurrency: GridColTypeDef = {
  type: 'monetary',
  headerAlign: 'center',
  align: 'center',
  minWidth: 120,
  valueFormatter: ({ value }) => {
    return fShortenNumberInt(value);
  },
};
