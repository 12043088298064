import React from 'react';
import { Props as ChartProps } from 'react-apexcharts';

import Apexchart from 'src/components/Apexchart';
import { formatIntToMoney } from 'src/helpers/number';

type StepsAmountChartProps = {
  series?: ChartProps['series'];
};

const StepsAmountChart: React.FC<StepsAmountChartProps> = ({ series }) => {
  return (
    <Apexchart
      type="bar"
      options={{
        title: { text: 'Valor em Aberto por Etapa', align: 'center' },
        chart: {
          id: 'steps-amount',
          defaultLocale: 'BR',
        },
        plotOptions: {
          bar: { horizontal: true, isFunnel: true },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          dropShadow: {
            enabled: true,
          },
          formatter(value, options) {
            return `${
              options.w.globals.seriesNames[options.seriesIndex]
            }: ${formatIntToMoney(Number(value))}`;
          },
        },
        tooltip: {
          y: {
            formatter(value) {
              return formatIntToMoney(Number(value));
            },
          },
        },
      }}
      series={series || []}
    />
  );
};

export default StepsAmountChart;
