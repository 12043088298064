import { FC, useState } from 'react';

import Content from './Content';
import { OpportunitiesContext, OpportunitiesTotals } from './context';
import Header from './Header';
import { Container } from './styles';

const Opportunities: FC = () => {
  const [needsReload, setNeedsReload] = useState(false);
  const [opportunitiesTotals, setOpportunitiesTotals] =
    useState<OpportunitiesTotals>({ qty: 0, sum: 0 });

  return (
    <OpportunitiesContext.Provider
      value={{
        needsReload,
        setNeedsReload,
        opportunitiesTotals,
        setOpportunitiesTotals,
      }}
    >
      <Container maxWidth={false}>
        <Header />

        <Content />
      </Container>
    </OpportunitiesContext.Provider>
  );
};

export default Opportunities;
