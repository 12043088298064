import { ChangeEvent, FC, useEffect, useState, FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AppBar, AppBarProps, Tab, Tabs, TabsProps } from '@mui/material';

export type NavigationTab<P = any> = {
  name: string;
  slug?: string;
  Component: FunctionComponent<P>;
  disabled?: boolean;
};

export type NavigationTabsProps = {
  baseUrl?: string;
  tabs: NavigationTab[];
  selectedTabParam?: string;
  navigationBehavior?: 'replace' | 'push' | 'state';
  appBarProps?: AppBarProps;
} & TabsProps;

const NavigationTabs: FC<NavigationTabsProps> = ({
  baseUrl = '',
  tabs,
  selectedTabParam = 'selectedTab',
  navigationBehavior = 'replace',
  appBarProps,
  onChange,
  ...rest
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const params = useParams<any>();
  const history = useHistory();

  const handleTabChange = (e: ChangeEvent<any>, newValue: number) => {
    if (navigationBehavior === 'state') {
      setCurrentTab(newValue);
      if (onChange) onChange(e, newValue);
      return;
    }

    let tabUrl = baseUrl;
    if (newValue > 0) {
      tabUrl = tabUrl.concat(`/${tabs[newValue].slug}`);
    }

    if (onChange) onChange(e, newValue);

    switch (navigationBehavior) {
      case 'push':
        history.push(tabUrl);
        break;
      case 'replace':
      default:
        history.replace(tabUrl);
        break;
    }
  };

  useEffect(() => {
    if (navigationBehavior === 'state') return;

    if (params[selectedTabParam]) {
      setCurrentTab(
        tabs.findIndex((tab) => tab.slug === params[selectedTabParam]),
      );
    } else {
      setCurrentTab(0);
    }
  }, [navigationBehavior, selectedTabParam, params, tabs]);

  return (
    <AppBar color="default" position="relative" {...appBarProps}>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        {...rest}
        value={currentTab}
        onChange={handleTabChange}
      >
        {tabs.map(({ name, disabled }) => (
          <Tab key={name} label={name} disabled={disabled} />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default NavigationTabs;
