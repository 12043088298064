import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';

import { InputPhoneNumberRef, InputPhoneNumberType } from './interfaces';

const InputPhoneNumber: FC<InputPhoneNumberType> = ({
  name,
  label,
  ddi = true,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<InputPhoneNumberRef>({
    nationalNumber: '',
    fullNumber: '',
  });
  const [phone, setPhone] = useState<string>();

  const {
    fieldName,
    registerField,
    error,
    defaultValue = '',
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: InputPhoneNumberRef) => {
        return ddi ? ref.fullNumber : ref.nationalNumber;
      },
      setValue: (_, value = '') => {
        setPhone(value);
        clearError();
      },
      clearValue: (_, newValue = '') => {
        setPhone(newValue);
        clearError();
      },
    });
  }, [fieldName, registerField, ddi, clearError]);

  useEffect(() => {
    if (defaultValue && !inputRef.current.fullNumber) {
      setPhone(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    const parsedPhone = parsePhoneNumberFromString(phone || '');
    const nationalNumber = parsedPhone?.nationalNumber || '';
    const fullNumber = parsedPhone?.number || '';
    inputRef.current.nationalNumber = nationalNumber;
    inputRef.current.fullNumber = fullNumber;
  }, [phone]);

  const handleChange: NonNullable<MuiTelInputProps['onChange']> = useCallback(
    (value, info) => {
      setPhone(value);

      clearError();

      if (onChange) onChange(value, info);
    },
    [onChange, clearError],
  );

  return (
    <MuiTelInput
      inputRef={inputRef}
      name={fieldName}
      label={label}
      value={phone}
      onChange={handleChange}
      defaultCountry="BR"
      preferredCountries={['BR']}
      langOfCountryName="pt"
      forceCallingCode
      focusOnSelectCountry
      error={!!error}
      helperText={error}
      aria-describedby={fieldName}
      fullWidth
      {...rest}
    />
  );
};

export default InputPhoneNumber;
