import { FC, useEffect, useRef } from 'react';

import {
  EmojiPeople,
  HourglassEmptyOutlined,
  PaidOutlined,
  PersonAdd,
  PriceChange,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from '@mui/icons-material/Refresh';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import AutocompleteUsers from 'src/components/Form/_Common/AutocompleteUsers';
import DatePickerOperator from 'src/components/Form/DatePickerOperator';
import { useStoreDispatch, useStoreSelector } from 'src/hooks/store';
import AutocompleteFunnels from 'src/modules/attendance/components/Form/_common/AutocompleteFunnels';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';
import OpportunitiesSeizingChart from 'src/pages/Private/Dashboard/OpportunitiesTab/OpportunitiesSeizingChart';
import OpportunitiesGeneralStatusChart from 'src/pages/Private/Dashboard/OpportunitiesTab/OpportunitiesStatusChart';
import OpportunitiesWithoutAttendanceChart from 'src/pages/Private/Dashboard/OpportunitiesTab/OpportunitiesWithoutAttendanceChart';
import ReasonsGainChart from 'src/pages/Private/Dashboard/OpportunitiesTab/ReasonsGainChart';
import ReasonsLostChart from 'src/pages/Private/Dashboard/OpportunitiesTab/ReasonsLostChart';
import SummaryCard from 'src/pages/Private/Dashboard/SummaryCard';
import api from 'src/services/api';
import { DashboardActions } from 'src/store/ducks/dashboard';
import { objectToUrlParams } from 'src/utils/helpers';
import { fCurrencyInt, fShortenNumber } from 'src/utils/number';
import yupValidate from 'src/utils/yupValidate';
import DashboardFilterSchema from 'src/validators/Dashboard/filter.schema';

const OpportunitiesTab: FC = () => {
  const filterFormRef = useRef<FormHandles>(null);
  const filters = useStoreSelector((store) => store.dashboard.filters);
  const {
    data: response,
    isRefetching,
    refetch,
  } = api.admin.common.useDashboardQuery({
    queryOptions: {
      enabled: false,
    },
    requestConfig: {
      params: objectToUrlParams(filters),
    },
  });
  const dispatch = useStoreDispatch();

  async function handleFilter(formData) {
    const { success, errors } = await yupValidate(
      DashboardFilterSchema,
      formData,
    );

    if (!success) {
      filterFormRef.current?.setErrors(errors);
      return;
    }

    dispatch(DashboardActions.dashboardSetFilters(formData));
  }

  async function handleClearFilter() {
    dispatch(DashboardActions.dashboardCleanFilters());
  }

  useEffect(() => {
    setTimeout(() => {
      filterFormRef.current?.setData(filters);
      filterFormRef.current?.setFieldValue(
        'period.value_end',
        filters.period?.value_end,
      );
    }, 500);

    refetch();
  }, [filters, refetch]);

  if (!response) return null;

  return (
    <Grid2 container spacing={2} disableEqualOverflow>
      <Grid2 xs={12}>
        <Card>
          <Form ref={filterFormRef} onSubmit={handleFilter}>
            <CardContent>
              <Grid2 container spacing={1}>
                <Grid2 xs={12} lg={6}>
                  <AutocompleteFunnels
                    name="funnels"
                    label="Funis"
                    requestConfig={{
                      params: { user_has_access: true },
                    }}
                    multiple
                  />
                </Grid2>

                <Can
                  permissions={[ATTENDANCE_PERMISSIONS.OPPORTUNITIES.LIST_ALL]}
                  behavior="hide"
                >
                  <Grid2 xs={12} lg={6}>
                    <AutocompleteUsers
                      name="attendants"
                      label="Usuários"
                      multiple
                    />
                  </Grid2>
                </Can>

                <Grid2 xs={12} lg={5}>
                  <DatePickerOperator name="period" />
                </Grid2>
              </Grid2>
            </CardContent>

            <CardActions style={{ justifyContent: 'end' }}>
              <Button
                color="error"
                variant="text"
                startIcon={<CloseIcon fontSize="inherit" />}
                onClick={handleClearFilter}
                disabled={isRefetching}
              >
                Limpar
              </Button>
              <Button
                color="primary"
                variant="contained"
                startIcon={<FilterIcon fontSize="inherit" />}
                type="submit"
                loading={isRefetching}
              >
                Atualizar
              </Button>
            </CardActions>
          </Form>
        </Card>
      </Grid2>

      <Grid2 xs={12} container spacing={1}>
        <Grid2 display="flex" xs={12} sm={4} md={2.4}>
          <SummaryCard
            title="Novos Leads"
            total={fShortenNumber(response?.data.qtyNewLeads || 0)}
            icon={<PersonAdd fontSize="large" color="success" />}
          />
        </Grid2>
        <Grid2 display="flex" xs={12} sm={4} md={2.4}>
          <SummaryCard
            title="Oportunidades sem atendimento"
            total={fShortenNumber(
              response?.data.qtyOpportunitiesWithoutAttendance || 0,
            )}
            icon={<EmojiPeople fontSize="large" />}
          />
        </Grid2>
        <Grid2 display="flex" xs={12} sm={4} md={2.4}>
          <SummaryCard
            title="Oportunidades Fechadas"
            total={fCurrencyInt(response?.data.qtyAmountGainOpportunity || 0)}
            icon={<PaidOutlined fontSize="large" color="success" />}
          />
        </Grid2>
        <Grid2 display="flex" xs={12} sm={4} md={2.4}>
          <SummaryCard
            title="Oportunidade em aberto"
            total={fCurrencyInt(response?.data.qtyAmountOpenOpportunity || 0)}
            icon={<HourglassEmptyOutlined fontSize="large" />}
          />
        </Grid2>
        <Grid2 display="flex" xs={12} sm={4} md={2.4}>
          <SummaryCard
            title="Ticket Médio"
            total={fCurrencyInt(
              response?.data.qtyAverageTicketOpportunity || 0,
            )}
            icon={<PriceChange fontSize="large" />}
          />
        </Grid2>
      </Grid2>

      <Grid2 xs={12} sm={8} md={4}>
        <Card>
          <CardContent>
            <OpportunitiesGeneralStatusChart
              data={response?.data.opportunitiesStatus}
            />
          </CardContent>
        </Card>
      </Grid2>

      <Grid2 xs={12} md={8}>
        <Card>
          <CardHeader
            subheader="Motivos"
            subheaderTypographyProps={{ textAlign: 'center' }}
          />
          <CardContent>
            <Grid2 container>
              <Grid2 xs={12} sm={6}>
                <ReasonsGainChart data={response?.data.reasonsGain} />
              </Grid2>

              <Grid2 xs={12} sm={6}>
                <ReasonsLostChart data={response?.data.reasonsLost} />
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      </Grid2>

      <Grid2 xs={12} sm={6}>
        <Card>
          <CardContent>
            <OpportunitiesSeizingChart
              data={response?.data.opportunitiesSeizing}
            />
          </CardContent>
        </Card>
      </Grid2>

      <Grid2 xs={12} sm={6}>
        <Card>
          <CardContent>
            <OpportunitiesWithoutAttendanceChart
              data={response?.data.opportunitiesWithoutAttendance}
            />
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default OpportunitiesTab;
