import { FC, useCallback, useEffect, useRef, useState } from 'react';

import TextField from '@mui/material/TextField';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useField } from '@unform/core';
import { parseJSON } from 'date-fns';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import { formatDate } from 'src/utils/helpers';

import { DateTimePickerType, InputRef } from './interfaces';

const DateTimePicker: FC<DateTimePickerType> = ({
  name,
  inputFormat = 'dd/MM/yyyy HH:mm',
  outputFormat = 'yyyy-MM-dd HH:mm',
  returnAsDate,
  disableText,
  inputProps,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<InputRef>(null);

  const { defaultValue, error, fieldName, registerField, clearError } =
    useField(name);

  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        if (!ref.current?.selectedDate) return null;
        if (!isValid(ref.current.selectedDate)) return null;

        if (returnAsDate) return ref.current.selectedDate;
        return formatDate(ref.current.selectedDate.toISOString(), outputFormat);
      },
      setValue: (_, value) => {
        const parsed = parseISO(value);
        setDate(isValid(parsed) ? parsed : null);
        clearError();
      },
      clearValue: (_, newValue) => {
        const parsed = parseISO(newValue);
        setDate(isValid(parsed) ? parsed : null);
        clearError();
      },
    });
  }, [
    fieldName,
    registerField,
    onChange,
    clearError,
    returnAsDate,
    outputFormat,
  ]);

  // Default value
  useEffect(() => {
    if (typeof defaultValue === 'string') {
      const value = parseJSON(defaultValue);
      setDate(isValid(value) ? value : null);
    } else if (defaultValue instanceof Date) {
      setDate(defaultValue);
    } else {
      setDate(null);
    }
  }, [defaultValue, inputFormat]);

  // date change
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.selectedDate = date;

      if (onChange) onChange(date);
    }
  }, [date, onChange]);

  // On change
  const handleChange = useCallback(
    (value: Date | null) => {
      clearError();
      setDate(isValid(value) ? value : null);
    },
    [clearError],
  );

  return (
    <MuiDateTimePicker
      inputRef={inputRef}
      PopperProps={{
        anchorEl: () => window.document.getElementsByName(fieldName).item(0),
      }}
      value={date}
      onChange={handleChange}
      inputFormat={inputFormat}
      renderInput={(renderProps) => (
        <TextField
          {...renderProps}
          placeholder="dd/mm/aaaa"
          fullWidth
          {...inputProps}
          autoComplete="off"
          name={fieldName}
          disabled={disableText}
          error={!!error}
          helperText={error}
        />
      )}
      {...rest}
    />
  );
};

export default DateTimePicker;
