import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import BackButton from 'src/components/BackButton';
import AutocompleteDepartments from 'src/components/Form/_Common/AutocompleteDepartments';
import AutocompleteRoles from 'src/components/Form/_Common/AutocompleteRoles';
import CheckboxPermissions from 'src/components/Form/_Common/CheckboxPermissions';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import InputFile from 'src/components/Form/InputFile';
import InputPhoneNumber from 'src/components/Form/InputPhoneNumber';
import TextField from 'src/components/Form/TextField';
import RolePermissions from 'src/components/RolePermissions';
import { STATUSES } from 'src/constants';
import { BASE_PERMISSIONS } from 'src/constants/permissions/base';
import { useStoreSelector } from 'src/hooks/store';
import { ISaveUser } from 'src/interfaces/forms/ISaveUser';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IRole, IUser } from 'src/interfaces/models';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import {
  UserStoreSchema,
  UserUpdateSchema,
} from 'src/validators/User/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const loggedUser = useStoreSelector((state) => state.auth.user);
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } =
    useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [userStatus, setUserStatus] = useState<string>('');
  const [currentPermTab, setCurrentPermTab] = useState(0);
  const [selectedRole, setSelectedRole] = useState<IRole>();
  const history = useHistory();
  const params = useParams<IEditParams>();

  const userSchema = params.id ? UserUpdateSchema : UserStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const userResponse = await api.admin.users.getUser(params.id);
        const { permissions, departments, ...user } = userResponse.data;
        const userPermissions: boolean[] = [];
        permissions?.forEach((perm) => {
          userPermissions[perm.id] = true;
        });

        setUserStatus(user.status);

        setTimeout(() => {
          formRef.current?.setData({
            ...user,
            permissions: userPermissions,
            departments: departments?.map((department) => department.id),
          });
        });
      } else {
        formRef.current?.setData({
          change_password: true,
        });
      }
    } catch (error) {
      handleErrors(error, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<ISaveUser> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(userSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const permissions: number[] = [];
      formData.permissions.forEach((selected, index) => {
        if (selected) permissions.push(index);
      });

      let newUser: IUser;
      if (params.id) {
        const response = await api.admin.users.updateUser(
          params.id,
          objectToFormData({
            ...data,
            permissions,
          }),
        );
        newUser = response.data;
      } else {
        const response = await api.admin.users.storeUser(
          objectToFormData({
            ...data,
            permissions,
          }),
        );
        newUser = response.data;
      }

      toast.success('Dados salvos com sucesso!');
      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/usuarios/${newUser.id}`);
      }
    } catch (error) {
      handleErrors(error, 'Erro', userSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await api.admin.users.destroyUser(params.id);

      toast.success('Usuário desativado!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao desativar usuário');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await api.admin.users.restoreUser(params.id);

      toast.success('Usuário restaurado!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao atualizar usuário.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  async function onChangeRole(option: AutocompleteValue<IRole>) {
    try {
      if (!option || Array.isArray(option)) {
        setCurrentPermTab(0);
        return setSelectedRole(undefined);
      }
      const { data } = await api.admin.common.getRole(option.value);
      setSelectedRole(data);
    } catch (error) {
      handleErrors(error);
    }
  }

  return (
    <Container maxWidth="xl">
      <Form
        ref={formRef}
        permissions={{
          store: [BASE_PERMISSIONS.USERS.STORE],
          update: [BASE_PERMISSIONS.USERS.UPDATE],
          destroy: [BASE_PERMISSIONS.USERS.DESTROY],
          restore: [BASE_PERMISSIONS.USERS.RESTORE],
        }}
        resourceName="Função"
        onSave={handleSave}
        onDestroy={handleDestroy}
        onRestore={handleRestore}
        onCancel={handleCancel}
        loading={loading || layoutLoading}
        isUpdating={!!params.id}
        isActive={userStatus === STATUSES.ACTIVE}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Novo'} Usuário
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField name="name" label="Nome Completo" required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="email" label="E-mail" type="email" />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField name="login" label="Login" required />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      name="password"
                      label="Senha"
                      required
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      name="password_confirmation"
                      label="Confirmar Senha"
                      required
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Grid
                      container
                      style={{ height: '100%' }}
                      alignItems="center"
                    >
                      <CheckBox name="change_password" label="Mudar Senha" />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AutocompleteRoles
                      name="role_id"
                      label="Função"
                      onChange={onChangeRole}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid
                      container
                      style={{ height: '100%' }}
                      alignItems="center"
                    >
                      <CheckBox
                        name="is_admin"
                        label="Administrador"
                        disabled={!loggedUser?.is_admin}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteDepartments
                      name="departments"
                      label="Departamentos"
                      multiple
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <InputFile name="photo" label="Foto" canDelete />
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              name="document"
                              label="CPF / CNPJ"
                              mask="cpfOrCnpj"
                              returnUnmasked
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              name="state_registration"
                              label="Inscrição Estadual"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              name="company_name"
                              label="Razão Social"
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputPhoneNumber name="phone" label="Telefone" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField name="responsible" label="Responsável" />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="zip_code"
                      label="CEP"
                      mask="zip_code"
                      returnUnmasked
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <TextField name="address" label="Endereço" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Divider variant="middle">
              <Typography variant="h6" align="center">
                Permissões
              </Typography>
            </Divider>
          </Grid>

          <Grid item xs={12} justifyContent="center">
            <Grid container justifyContent="center">
              <Typography variant="subtitle1" align="center" fontWeight="bold">
                Como Funciona?
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Typography variant="subtitle2" align="justify">
                O usuário irá receber a <strong>soma</strong> das permissões{' '}
                <strong>personsalidadas</strong> e da{' '}
                <strong>função vinculada</strong>, ou seja, o acesso será
                liberado se as personalizadas permitirem <strong>OU</strong> se
                a função permitir.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Tabs
              value={currentPermTab}
              onChange={(_, value) => setCurrentPermTab(value)}
              centered
            >
              <Tab
                label="Personalizadas"
                id="user-perm-tab"
                aria-controls="user-perm-tabpanel"
              />
              <Tab
                label={`Função ${selectedRole ? `(${selectedRole.name})` : ''}`}
                id="role-perm-tab"
                aria-controls="role-perm-tabpanel"
                disabled={!selectedRole}
              />
            </Tabs>

            <div
              role="tabpanel"
              hidden={currentPermTab !== 0}
              style={{ paddingTop: 16 }}
            >
              <CheckboxPermissions
                formRef={formRef}
                description="As permissões personalizadas são individuais ao usuário e que podem ser alteradas conforme a necessidade de cada um particularmente. As alterações nas permissões personalizadas não refletem nas permissões de função."
              />
            </div>

            <div
              role="tabpanel"
              hidden={currentPermTab !== 1}
              style={{ paddingTop: 16 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} container justifyContent="center">
                  <Typography variant="subtitle2" align="justify">
                    Aqui você verá as permissões da função atribuida ao usuário
                    no formulário acima. Para modificar essas permissões é
                    possível mudar a função do usuário ou ir a tela de cadastro
                    da função e editá-la.
                  </Typography>
                </Grid>

                {selectedRole && (
                  <Grid item xs={12} padding={0}>
                    <RolePermissions role={selectedRole} />
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default Save;
