import React, { PropsWithChildren, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  OmnichannelContext,
  Upload,
} from 'src/components/Omnichannel/ContextProvider/types';
import { ZApiInstance } from 'src/modules/whatsapp/@types/models';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import { Chat, ChatMessage } from 'src/services/websocket/omnichannel/types';

type ContextProviderProps = {
  zApiInstance: ZApiInstance;
  currentChat?: Chat;
} & PropsWithChildren;

const ContextProvider: React.FC<ContextProviderProps> = ({
  zApiInstance,
  currentChat,
  children,
}) => {
  const [connected, setConnected] = useState(false);
  const [chats, setChats] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<Chat | undefined>(
    currentChat,
  );
  const [selectedChatMessages, setSelectedChatMessages] = useState<
    ChatMessage[]
  >([]);
  const [answerMessage, setAnswerMessage] = useState<ChatMessage>();
  const [upload, setUpload] = useState<Upload | null>(null);

  useEffect(() => {
    WSOmnichannel.connect(zApiInstance.id);

    WSOmnichannel.socket?.on('connect', () => {
      setConnected(true);
    });

    WSOmnichannel.socket?.on('connect_error', () => {
      setConnected(false);
      toast.error('Não foi possível conectar ao WebSocket');
    });

    // WSOmnichannel.socket?.on('message_received', (message) => {
    //   setChats((data) =>
    //     data
    //       .map((chat) => {
    //         if (chat._id === message.chat) {
    //           chat.last_message = message;
    //           if (message.direction === 'incoming') {
    //             NotificationUtils.createNotification({
    //               title: `Mensagem de ${chat.client?.username}`,
    //               options: {
    //                 body: OminichannelUtils.getChatMessageText(message),
    //               },
    //             });
    //           }
    //         }

    //         return chat;
    //       })
    //       .sort((a, b) => {
    //         const aSended = a.last_message?.sended_at || '';
    //         const bSended = b.last_message?.sended_at || '';

    //         if (aSended > bSended) {
    //           return -1;
    //         }
    //         if (aSended < bSended) {
    //           return 1;
    //         }
    //         return 0;
    //       }),
    //   );
    // });

    return () => {
      WSOmnichannel.disconnect();
    };
  }, [zApiInstance]);

  // useEffect(() => {
  //   if (!chats.find((chat) => chat._id === selectedChat?._id)) {
  //     setSelectedChat(undefined);
  //   }
  // }, [chats, selectedChat]);

  useEffect(() => {
    setUpload(null);
  }, [selectedChat]);

  return (
    <OmnichannelContext.Provider
      value={{
        connected,
        zApiInstance,
        chats,
        setChats,
        selectedChat,
        setSelectedChat,
        selectedChatMessages,
        setSelectedChatMessages,
        answerMessage,
        setAnswerMessage,
        upload,
        setUpload,
      }}
    >
      {children}
    </OmnichannelContext.Provider>
  );
};

export default ContextProvider;
