import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { Goal } from 'src/modules/attendance/@types/models';
import {
  ATTENDANCE_ADMIN_QUERY_KEYS,
  ATTENDANCE_COMMON_QUERY_KEYS,
} from 'src/modules/attendance/constants/query';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

import * as users from './users';

async function getGoals(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Goal>>(
    '/attendance/admin/goals',
    config,
  );

  return response;
}

function useGoalsQuery(data?: IApiUseQueryData<Goal>) {
  return useQuery<IApiUseQueryFnData<Goal>>(
    [ATTENDANCE_ADMIN_QUERY_KEYS.GOALS, data?.requestConfig?.params],
    () => getGoals(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateGoalsQueries() {
  queryClient.invalidateQueries([ATTENDANCE_COMMON_QUERY_KEYS.GOALS]);
  queryClient.invalidateQueries([ATTENDANCE_ADMIN_QUERY_KEYS.GOALS]);
}

async function storeGoal(data: object | FormData, config?: AxiosRequestConfig) {
  const response = await apiAxios.post<Goal>(
    '/attendance/admin/goals',
    data,
    config,
  );

  invalidateGoalsQueries();

  return response;
}

async function getGoal(id: string | number, config?: AxiosRequestConfig) {
  const response = await apiAxios.get<Goal>(
    `/attendance/admin/goals/${id}`,
    config,
  );

  return response;
}

async function updateGoal(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<Goal>(
    `/attendance/admin/goals/${id}`,
    data,
    config,
  );

  invalidateGoalsQueries();

  return response;
}

async function destroyGoal(id: string | number, config?: AxiosRequestConfig) {
  const response = await apiAxios.delete<boolean>(
    `/attendance/admin/goals/${id}`,
    config,
  );

  invalidateGoalsQueries();

  return response;
}

async function restoreGoal(id: string | number, config?: AxiosRequestConfig) {
  const response = await apiAxios.post<boolean>(
    `/attendance/admin/goals/${id}/restore`,
    config,
  );

  invalidateGoalsQueries();

  return response;
}

async function archiveGoal(id: string | number, config?: AxiosRequestConfig) {
  const response = await apiAxios.post<boolean>(
    `/attendance/admin/goals/${id}/archive`,
    config,
  );

  invalidateGoalsQueries();

  return response;
}

const goals = {
  getGoals,
  useGoalsQuery,
  storeGoal,
  getGoal,
  updateGoal,
  destroyGoal,
  restoreGoal,
  archiveGoal,
  users,
};

export default goals;
