import React, { forwardRef } from 'react';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {
  Avatar,
  CardActionArea,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { isAfter } from 'date-fns';
import { formatIntToMoney } from 'src/helpers/number';
import { Opportunity } from 'src/modules/attendance/@types/models';
import OpportunityUnread from 'src/modules/attendance/components/OpportunityUnread';
import { formatDate } from 'src/utils/helpers';

import { Container, LoadingContainer } from './styles';

type OpportunityCardProps = {
  opportunity: Opportunity;
  onOpen?: (opportunity: Opportunity) => void;
  dragProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  loading?: boolean;
};

const OpportunityCard: React.ForwardRefRenderFunction<
  HTMLDivElement,
  OpportunityCardProps
> = ({ opportunity, onOpen, dragProps, dragHandleProps, loading }, ref) => {
  function handleClick(opportunity: Opportunity) {
    if (onOpen) onOpen(opportunity);
  }

  function renderOpportunityWaitingIcon() {
    if (!opportunity.is_waiting || !opportunity.wait_until) return null;

    const currentDate = new Date();
    const waitUntilDate = new Date(opportunity.wait_until);
    const waitIsFinished = isAfter(currentDate, waitUntilDate);

    return (
      <Grid item>
        <Tooltip
          title={`Em espera até ${formatDate(
            opportunity.wait_until,
            'dd/MM/yyyy HH:mm',
          )}`}
        >
          {waitIsFinished ? (
            <PriorityHighIcon color="error" />
          ) : (
            <AccessTimeIcon />
          )}
        </Tooltip>
      </Grid>
    );
  }

  return (
    <CardActionArea onClick={() => handleClick(opportunity)} disabled={loading}>
      <Container
        sx={{ p: 1 }}
        opportunity={opportunity}
        {...dragProps}
        {...dragHandleProps}
        ref={ref}
      >
        {loading && (
          <LoadingContainer>
            <CircularProgress size={24} />
          </LoadingContainer>
        )}
        <Typography>{opportunity.name}</Typography>
        <Typography fontWeight="bold" variant="subtitle2">
          {opportunity.lead?.name}
        </Typography>

        <Grid container columnSpacing={1} marginTop={0.5} alignItems="center">
          {opportunity.attendant && (
            <Grid item>
              <Tooltip title={opportunity.attendant.name}>
                <Avatar
                  sx={{ width: 24, height: 24, position: 'inherit' }}
                  src={opportunity.attendant.photo}
                />
              </Tooltip>
            </Grid>
          )}

          <Grid item xs>
            <Typography
              marginLeft={opportunity.attendant ? 1 : 0}
              variant="subtitle2"
            >
              {formatIntToMoney(opportunity.amount)}
            </Typography>
          </Grid>

          <OpportunityUnread opportunity={opportunity} />

          {renderOpportunityWaitingIcon()}
        </Grid>
      </Container>
    </CardActionArea>
  );
};

export default forwardRef(OpportunityCard);
