import { FC, useCallback, useEffect, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from '@mui/icons-material/Refresh';
import { Card, CardActions, CardContent, Grid } from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { sub } from 'date-fns';
import _ from 'lodash';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import DatePickerOperator, {
  DatePickerOperatorValue,
} from 'src/components/Form/DatePickerOperator';
import { useStoreDispatch, useStoreSelector } from 'src/hooks/store';
import { Funnel } from 'src/modules/attendance/@types/models';
import AutocompleteFunnelAttendants from 'src/modules/attendance/components/Form/_common/AutocompleteFunnelAttendants';
import AutocompleteFunnels from 'src/modules/attendance/components/Form/_common/AutocompleteFunnels';
import OpportunitiesAmountChart from 'src/modules/attendance/pages/Private/Dashboard/charts/OpportunitiesAmount';
import OpportunitiesStatusChart from 'src/modules/attendance/pages/Private/Dashboard/charts/OpportunitiesStatusChart';
import StepsAmountChart from 'src/modules/attendance/pages/Private/Dashboard/charts/StepsAmountChart';
import StepsOpportunitiesChart from 'src/modules/attendance/pages/Private/Dashboard/charts/StepsOpportunitiesChart';
import { useDashboardQuery } from 'src/modules/attendance/services/api/admin/dashboard';
import { AttendanceFunnelActions } from 'src/modules/attendance/store/ducks/funnel';
import DashboardFilterSchema from 'src/modules/attendance/validators/Dashboard/filter.schema';
import { objectToUrlParams, removeEmpty } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';

import { Container } from './styles';

type DashboardFilters = {
  funnel_id?: number;
  attendants?: number[];
  period?: DatePickerOperatorValue;
};

const Dashboard: FC = () => {
  const { selectedFunnel } = useStoreSelector(
    (state) => state.attendance.funnel,
  );
  const dispatch = useStoreDispatch();
  const filterFormRef = useRef<FormHandles>(null);
  const [filters, setFilters] = useState<DashboardFilters>({
    funnel_id: selectedFunnel?.id,
    period: {
      operator: 'between',
      value: sub(new Date(), { days: 7 }),
      value_end: new Date(),
    },
  });
  const { isLoading, data, refetch } = useDashboardQuery({
    queryOptions: {
      enabled: false,
    },
    requestConfig: {
      params: objectToUrlParams(filters),
    },
  });

  const handleSelectFunnel = useCallback(
    (value: AutocompleteValue<Funnel>) => {
      if (!value) {
        dispatch(AttendanceFunnelActions.attendanceFunnelSelectFunnel(null));
      } else if (value && !_.isArray(value) && value.data) {
        dispatch(
          AttendanceFunnelActions.attendanceFunnelSelectFunnel(value.data),
        );
      }
    },
    [dispatch],
  );

  async function handleFilter(formData: DashboardFilters) {
    const { success, errors, data } = await yupValidate(
      DashboardFilterSchema,
      formData,
    );

    if (!success) {
      filterFormRef.current?.setErrors(errors);
      return;
    }

    setFilters(removeEmpty(data));
  }

  function clearFilter() {
    filterFormRef.current?.setData({ funnel_id: selectedFunnel?.id });
  }

  useEffect(() => {
    if (selectedFunnel)
      setFilters((state) => ({
        ...state,
        funnel_id: selectedFunnel.id,
      }));
  }, [selectedFunnel]);

  useEffect(() => {
    if (filters.funnel_id) refetch();
  }, [refetch, filters]);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Form
              ref={filterFormRef}
              onSubmit={handleFilter}
              initialData={filters}
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={3}>
                    <AutocompleteFunnels
                      name="funnel_id"
                      label="Funil a ser visualizado"
                      onChange={handleSelectFunnel}
                      requestConfig={{
                        params: { user_has_access: true },
                      }}
                    />
                  </Grid>

                  {selectedFunnel && (
                    <Can permissions={[]} behavior="hide">
                      <Grid item xs={12} sm={8} md={9} lg={4}>
                        <AutocompleteFunnelAttendants
                          name="attendants"
                          label="Atendentes"
                          funnelId={selectedFunnel.id}
                          multiple
                        />
                      </Grid>

                      <Grid item xs={12} lg={5}>
                        <DatePickerOperator name="period" />
                      </Grid>
                    </Can>
                  )}
                </Grid>
              </CardContent>

              <CardActions style={{ justifyContent: 'end' }}>
                <Button
                  color="error"
                  variant="text"
                  startIcon={<CloseIcon fontSize="inherit" />}
                  onClick={clearFilter}
                  disabled={isLoading}
                >
                  Limpar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<FilterIcon fontSize="inherit" />}
                  type="submit"
                  loading={isLoading}
                >
                  Atualizar
                </Button>
              </CardActions>
            </Form>
          </Card>
        </Grid>

        {selectedFunnel && (
          <>
            <Grid item xs={12} md={6} xl={4}>
              <Card>
                <CardContent>
                  <StepsOpportunitiesChart
                    series={data?.data.stepsOpportunties}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} xl={4}>
              <Card>
                <CardContent>
                  <StepsAmountChart series={data?.data.stepsAmount} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} xl={4}>
              <Card>
                <CardContent>
                  <OpportunitiesStatusChart
                    data={data?.data.opportunitiesStatus}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} xl={4}>
              <Card>
                <CardContent>
                  <OpportunitiesAmountChart
                    data={data?.data.opportunitiesAmount}
                  />
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default Dashboard;
