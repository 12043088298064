import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { GridColumns } from '@mui/x-data-grid';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import TextField from 'src/components/Form/TextField';
// import Table from 'src/components/Table';
// import { TableProps } from 'src/components/Table/interfaces';
import Link from 'src/components/Link';
import LinkButton from 'src/components/LinkButton';
import { INITIAL_INFO } from 'src/hooks/useApiPagination/interfaces';
import { Lead } from 'src/interfaces/models';
import api from 'src/services/api';
import { handleErrors } from 'src/utils/errors';

import { SearchLeadDialogProps, SearchLeadDialogRef } from './types';

const SearchLeadDialog: ForwardRefRenderFunction<
  SearchLeadDialogRef,
  SearchLeadDialogProps
> = ({ onClose, onSelectLead, defaultQueryParams, loading, ...rest }, ref) => {
  const searchFormRef = useRef<FormHandles>(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Lead[]>([]);
  const [info, setInfo] = useState(INITIAL_INFO);
  const [filters, setFilters] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => setOpen(true),
    hide: () => setOpen(false),
  }));

  const columns: GridColumns<Lead> = [
    { field: 'id', headerName: 'ID', sortable: false },
    {
      field: 'name',
      headerName: 'Cliente',
      flex: 1,
      sortable: false,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">{row.name}</Typography>
            {row.email && <Typography variant="body2">{row.email}</Typography>}
          </div>
        );
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      minWidth: 120,
      renderCell({ row }) {
        return (
          <>
            <IconButton
              size="medium"
              color="success"
              onClick={() => onSelectLead(row)}
              title="Selecionar"
              disabled={loading}
            >
              <CheckIcon fontSize="inherit" />
            </IconButton>
            <Link to={`/leads/${row.id}`} target="_blank">
              <IconButton
                size="medium"
                color="primary"
                title="Editar"
                disabled={loading}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Link>
          </>
        );
      },
    },
  ];

  const handleClose = useCallback<
    NonNullable<SearchLeadDialogProps['onClose']>
  >(
    (event, reason) => {
      setOpen(false);

      if (onClose) onClose(event, reason);
    },
    [onClose],
  );

  const getLeads = useCallback(
    async (page = 1, perPage = 10, filters = {}) => {
      try {
        setSearchLoading(true);

        const { data: responseData } = await api.admin.common.getLeads({
          params: {
            page,
            per_page: perPage,
            ...defaultQueryParams,
            ...filters,
          },
        });

        setInfo(responseData.meta || INITIAL_INFO);
        setData(responseData.data || []);
      } catch (error) {
        handleErrors(error, 'Ocorreu um erro na busca dos dados.');
      } finally {
        setSearchLoading(false);
      }
    },
    [defaultQueryParams],
  );

  const handlePageChange: NonNullable<DataGridProps['onPageChange']> = async (
    page,
  ) => {
    await getLeads(page + 1, info.per_page, filters);
  };

  const handlePageSizeChange: NonNullable<
    DataGridProps['onPageSizeChange']
  > = async (pageSize) => {
    await getLeads(info.current_page, pageSize, filters);
  };

  const handleSubmit: SubmitHandler = async (formData) => {
    setFilters(formData);

    await getLeads(1, 10, formData);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" {...rest}>
      <DialogTitle>Selecionar Cliente</DialogTitle>

      <Form ref={searchFormRef} onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={9}>
              <TextField
                name="search"
                placeholder="Nome / E-email / Telefone"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<SearchIcon />}
                fullWidth
                onClick={() => searchFormRef.current?.submitForm()}
              >
                Pesquisar
              </Button>
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                autoHeight
                components={{ Toolbar: undefined }}
                disableSelectionOnClick
                rows={data}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={searchLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <LinkButton
                to="/leads/novo"
                target="_blank"
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
              >
                Lead
              </LinkButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

export default forwardRef(SearchLeadDialog);
