import { FC, useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import WebhookIcon from '@mui/icons-material/Webhook';
import { DialogContent, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import CustomDialog from 'src/components/CustomDialog';
import { CustomDialogRef } from 'src/components/CustomDialog/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import CustomMenuItem from 'src/components/Table/CustomMenuItem';
import StatusIcon from 'src/components/Table/StatusIcon';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { ZApiInstance } from 'src/modules/whatsapp/@types/models';
import { Z_API_INSTANCE_STATUS_LABEL } from 'src/modules/whatsapp/constants';
import { WHATSAPP_PERMISSIONS } from 'src/modules/whatsapp/constants/permissions';
import whatsappApi from 'src/modules/whatsapp/services/api';
import ZApiInstanceFilterSchema from 'src/modules/whatsapp/validators/ZApiInstance/filter.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleErrors } from 'src/utils/errors';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const {
    handlePageChange,
    handlePageSizeChange,
    data: response,
    isLoading,
  } = useApiQueryPagination(
    whatsappApi.admin.zApiInstances.useZApiInstancesQuery,
  );
  const [qrCodeImage, setQrCodeImage] = useState('');
  const connectDialogRef = useRef<CustomDialogRef>(null);

  async function checkConnection(id: number) {
    try {
      startLayoutLoading();
      const response =
        await whatsappApi.admin.zApiInstances.checkZApiInstanceConnection(id);

      if (response.data.connected) {
        return toast.success('Instância Conectada!');
      }

      setQrCodeImage(response.data.value);
      connectDialogRef.current?.show();
    } catch (error) {
      handleErrors(error, 'Erro ao verificar conexão.');
    } finally {
      stopLayoutLoading();
    }
  }

  async function updateWebhooks(id: number) {
    try {
      startLayoutLoading();
      await whatsappApi.admin.zApiInstances.updateZApiInstanceWebhooks(id);

      toast.success('Webhooks Atualizados!');
    } catch (error) {
      handleErrors(error, 'Erro ao verificar conexão.');
    } finally {
      stopLayoutLoading();
    }
  }

  async function disconnect(id: number) {
    try {
      startLayoutLoading();
      await whatsappApi.admin.zApiInstances.disconnectZApiInstance(id);

      toast.success('Instância Desconectada!');
    } catch (error) {
      handleErrors(error, 'Erro ao desconectar.');
    } finally {
      stopLayoutLoading();
    }
  }

  const columns: GridColDef<ZApiInstance>[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome', flex: 1 },
    { field: 'instance_id', headerName: 'ID Instância', flex: 1 },
    {
      field: 'instance_status',
      headerName: 'Status Instância',
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
      valueGetter({ value }) {
        return Z_API_INSTANCE_STATUS_LABEL[value];
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resourceName="Instância WhatsApp"
            resourceId={id}
            resourceStatus={row.status}
            editLink={`/whatsapp/instancias/${id}`}
            editPermissions={[WHATSAPP_PERMISSIONS.Z_API_INSTANCES.UPDATE]}
            destroyApiEndpoint={
              whatsappApi.admin.zApiInstances.destroyZApiInstance
            }
            destroyPermissions={[WHATSAPP_PERMISSIONS.Z_API_INSTANCES.DESTROY]}
            restoreApiEndpoint={
              whatsappApi.admin.zApiInstances.restoreZApiInstance
            }
            restorePermissions={[WHATSAPP_PERMISSIONS.Z_API_INSTANCES.RESTORE]}
            descriptionPrefix={`A Instância ${row.name}`}
            startItems={
              <Can permissions={[WHATSAPP_PERMISSIONS.Z_API_INSTANCES.LIST]}>
                <CustomMenuItem
                  text="Verificar Conexão"
                  Icon={PhonelinkIcon}
                  onClick={() => checkConnection(row.id)}
                />

                <CustomMenuItem
                  text="Atualizar Webhooks"
                  Icon={WebhookIcon}
                  onClick={() => updateWebhooks(row.id)}
                />
              </Can>
            }
            endItems={
              <Can permissions={[WHATSAPP_PERMISSIONS.Z_API_INSTANCES.LIST]}>
                <CustomMenuItem
                  text="Desconectar"
                  Icon={PowerSettingsNewIcon}
                  color="primary"
                  onClick={() => disconnect(row.id)}
                  iconProps={{ color: 'error' }}
                  textProps={{ color: 'error' }}
                />
              </Can>
            }
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Instâncias WhatsApp</Typography>

            <Can permissions={[WHATSAPP_PERMISSIONS.Z_API_INSTANCES.STORE]}>
              <LinkButton
                to="/whatsapp/instancias/novo"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={response?.data.data || []}
                pageSize={response?.data.meta.per_page}
                rowCount={response?.data.meta.total}
                columns={columns}
                loading={isLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                showFilters
                filterProps={{
                  yupSchema: ZApiInstanceFilterSchema,
                  children: <Filters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <CustomDialog
        ref={connectDialogRef}
        title="Conectar WhatsApp"
        titleProps={{ align: 'center' }}
      >
        <DialogContent>
          <div style={{ textAlign: 'center' }}>
            <img src={qrCodeImage} alt="QrCode" />
          </div>
        </DialogContent>
      </CustomDialog>
    </Container>
  );
};

export default List;
