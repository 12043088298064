import React, { useState } from 'react';

import { Grid, TextFieldProps, Typography } from '@mui/material';
import { Scope } from '@unform/core';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import DatePicker from 'src/components/Form/DatePicker';
import { operators } from 'src/utils/helpers';

export type DatePickerOperatorValue = {
  operator: string;
  value: string | Date;
  value_end?: string | Date;
};

type DatePickerOperatorProps = {
  name: string;
  label?: string;
  size?: TextFieldProps['size'];
};

const DatePickerOperator: React.FC<DatePickerOperatorProps> = ({
  name,
  label,
  size,
}) => {
  const [between, setBetween] = useState(false);
  const [startDate, setStartDate] = useState<Date>();

  function onOperatorChange(option: AutocompleteValue) {
    let isBetween = false;
    if (!Array.isArray(option)) {
      isBetween = option?.value === 'between';
    }
    setBetween(isBetween);
  }

  return (
    <Grid container spacing={1}>
      <Scope path={name}>
        {label && (
          <Grid item xs={12}>
            <Typography variant="body1">{label}</Typography>
          </Grid>
        )}
        <Grid item xs={4}>
          <Autocomplete
            name="operator"
            label="Operador"
            options={operators}
            onChange={onOperatorChange}
            fullWidth
            size={size}
          />
        </Grid>

        <Grid item xs={between ? 4 : 8}>
          <DatePicker
            name="value"
            label={between ? 'De' : 'Data'}
            inputProps={{ size }}
            onChange={(date) => setStartDate(date || undefined)}
          />
        </Grid>

        {between && (
          <Grid item xs={4}>
            <DatePicker
              name="value_end"
              label="Até"
              inputProps={{ size }}
              minDate={startDate}
            />
          </Grid>
        )}
      </Scope>
    </Grid>
  );
};

export default DatePickerOperator;
