import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { ADMIN_QUERY_KEYS, COMMON_QUERY_KEYS } from 'src/constants/query';
import { IRole } from 'src/interfaces/models';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getRoles(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<IRole>>(
    '/admin/roles',
    config,
  );

  return response;
}

export function useRolesQuery(data?: IApiUseQueryData<IRole>) {
  return useQuery<IApiUseQueryFnData<IRole>>(
    [ADMIN_QUERY_KEYS.ROLES, data?.requestConfig?.params],
    () => getRoles(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateRolesQueries() {
  queryClient.invalidateQueries([COMMON_QUERY_KEYS.ROLES]);
  queryClient.invalidateQueries([ADMIN_QUERY_KEYS.ROLES]);
}

export async function storeRole(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<IRole>('/admin/roles', data, config);

  invalidateRolesQueries();

  return response;
}

export async function getRole(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IRole>(`/admin/roles/${id}`, config);

  return response;
}

export async function updateRole(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<IRole>(
    `/admin/roles/${id}`,
    data,
    config,
  );

  invalidateRolesQueries();

  return response;
}

export async function destroyRole(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(`/admin/roles/${id}`, config);

  invalidateRolesQueries();

  return response;
}

export async function restoreRole(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/admin/roles/${id}/restore`,
    config,
  );

  invalidateRolesQueries();

  return response;
}
