import React from 'react';

import { Grid } from '@mui/material';
import Omnichannel from 'src/components/Omnichannel';
import { Opportunity } from 'src/modules/attendance/@types/models';
import { Chat } from 'src/services/websocket/omnichannel/types';

type BodyItemChatProps = {
  opportunity: Opportunity;
  chat?: Chat;
};

const BodyItemChat: React.FC<BodyItemChatProps> = ({ opportunity, chat }) => {
  if (!opportunity.step?.funnel?.zApiInstance || !chat) return null;

  return (
    <Grid item display="flex" xs={12} sm>
      <Omnichannel.ContextProvider
        zApiInstance={opportunity.step.funnel.zApiInstance}
        currentChat={chat}
      >
        <Omnichannel.ChatContent hideHeader />
      </Omnichannel.ContextProvider>
    </Grid>
  );
};

export default BodyItemChat;
