import NotificationSound from 'src/assets/sounds/bubble-pop-up.wav';

const notificationAudio = new Audio(NotificationSound);

async function requestPermission() {
  try {
    const response = await Notification.requestPermission();
    return response === 'granted';
  } catch (error) {
    return false;
  }
}

function hasPermission() {
  return Notification.permission === 'granted';
}

interface CreateNotificationData {
  title: string;
  options?: NotificationOptions;
}
function createNotification({ title, options }: CreateNotificationData) {
  if (hasPermission()) {
    const notification = new Notification(title, {
      icon: '/favicon.ico',
      ...options,
    });
    notificationAudio.play();
    return notification;
  }
}

const NotificationUtils = {
  requestPermission,
  hasPermission,
  createNotification,
};

export default NotificationUtils;
