import { AxiosRequestConfig } from 'axios';
import { IUser } from 'src/interfaces/models';
import apiAxios from 'src/services/api/axios';

export async function getProfile(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IUser>('/admin/me', config);

  return response;
}

export async function updateProfile(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<IUser>('/admin/me', data, config);

  return response;
}
