import { createActions, createReducer } from 'reduxsauce';
import { AuthTypes } from 'src/store/ducks/auth';

import {
  AttendanceFunnelCreators,
  IAttendanceFunnelState,
  IAttendanceFunnelTypes,
  IAttendanceFunnelChangeView,
  IAttendanceFunnelSelectFunnel,
} from './types';

/* Types & Actions Creators */
export const {
  Types: AttendanceFunnelTypes,
  Creators: AttendanceFunnelActions,
} = createActions<IAttendanceFunnelTypes, AttendanceFunnelCreators>({
  attendanceFunnelChangeView: ['view'],
  attendanceFunnelSelectFunnel: ['selectedFunnel'],
});

/* Initial State */
const INITIAL_STATE: IAttendanceFunnelState = {
  selectedFunnel: null,
  view: 'column',
};

/* Reducers */
const attendanceFunnelChangeView = (
  state: IAttendanceFunnelState,
  { view }: IAttendanceFunnelChangeView,
) => ({
  ...state,
  view,
});

const attendanceFunnelSelectFunnel = (
  state: IAttendanceFunnelState,
  { selectedFunnel }: IAttendanceFunnelSelectFunnel,
) => ({
  ...state,
  selectedFunnel,
});

const attendanceFunnelClean = (state: IAttendanceFunnelState) => ({
  ...state,
  selectedFunnel: null,
});

/* Reducers to types */
const reducer = createReducer(INITIAL_STATE, {
  [AttendanceFunnelTypes.ATTENDANCE_FUNNEL_CHANGE_VIEW]:
    attendanceFunnelChangeView,
  [AttendanceFunnelTypes.ATTENDANCE_FUNNEL_SELECT_FUNNEL]:
    attendanceFunnelSelectFunnel,
  [AuthTypes.AUTH_LOGOUT]: attendanceFunnelClean,
});

export default reducer;
