import BadgeIcon from '@mui/icons-material/Badge';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import { BASE_PERMISSIONS } from 'src/constants/permissions/base';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';
import { attendanceMenu } from 'src/modules/attendance/routes/menu';

const MenuItems: ISideMenuItem[] = [
  {
    primaryText: 'Dashboard',
    route: '/dashboard',
    icon: DashboardIcon,
  },
  {
    primaryText: 'Leads',
    permissions: [BASE_PERMISSIONS.LEADS.LIST],
    route: '/leads',
    icon: PermContactCalendarIcon,
  },
  attendanceMenu,
  {
    primaryText: 'Sistema',
    icon: SettingsIcon,
    subItems: [
      {
        primaryText: 'Usuários',
        route: '/usuarios',
        icon: GroupIcon,
        permissions: [BASE_PERMISSIONS.USERS.LIST],
      },
      {
        primaryText: 'Funções',
        route: '/funcoes',
        icon: WorkIcon,
        permissions: [BASE_PERMISSIONS.ROLES.LIST],
      },
      {
        primaryText: 'Departamentos',
        route: '/departamentos',
        icon: BadgeIcon,
        permissions: [BASE_PERMISSIONS.DEPARTMENTS.LIST],
      },
    ],
  },
];

export default MenuItems;
