import { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import AutocompleteDepartments from 'src/components/Form/_Common/AutocompleteDepartments';
import AutocompleteRoles from 'src/components/Form/_Common/AutocompleteRoles';
import Autocomplete from 'src/components/Form/Autocomplete';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';

const statusItems = [
  { key: 1, label: 'Ativo', value: STATUSES.ACTIVE },
  { key: 2, label: 'Inativo', value: STATUSES.INACTIVE },
];

const UserFilters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteRoles name="role_id" label="Função" size="small" />
      </Grid>
      <Grid item xs={6}>
        <CheckBox
          name="is_admin"
          label="Administrador"
          size="small"
          color="primary"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField name="login" label="Login" size="small" />
      </Grid>
      <Grid item xs={6}>
        <TextField name="name" label="Nome" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="email" label="E-mail" size="small" />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteDepartments
          name="departments"
          label="Departamentos"
          size="small"
          multiple
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="status"
          label="Status"
          options={statusItems}
          size="small"
          multiple
        />
      </Grid>
    </>
  );
};

export default UserFilters;
