import styled, { css } from 'styled-components';

import MuiDrawer from '@mui/material/Drawer';
import { SIDE_NAVIGATION_WIDTH } from 'src/routes/Private/Layout/styles';

export const Drawer = styled(MuiDrawer)`
  width: ${SIDE_NAVIGATION_WIDTH}px;
  flex-shrink: 0;

  > .MuiDrawer-paper {
    width: ${SIDE_NAVIGATION_WIDTH}px;
  }
`;

export const ToolbarIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  min-height: 56px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      min-height: 64px;
    }
  `}
`;
