import styled from 'styled-components';

import MuiDivider from '@mui/material/Divider';
import MuiTypography from '@mui/material/Typography';

export const ModuleTitle = styled(MuiTypography)`
  text-transform: capitalize;
`;

export const TitleDivider = styled(MuiDivider)`
  flex-grow: 1;
`;
