import { FC } from 'react';

import Sms from './Sms';
import WhatsApp from './WhatsApp';

// import { Container } from './styles';

interface MessageSenderProps {
  type: string;
}

const MessageSender: FC<MessageSenderProps> = ({ type }) => {
  switch (type) {
    case 'whatsapp':
      return <WhatsApp />;
    case 'sms':
      return <Sms />;
    default:
      return <span>{type}</span>;
  }
};

export default MessageSender;
