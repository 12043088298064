import yup from 'src/libs/yup';

const UserFilterSchema = yup.object().shape({
  role_id: yup.number().nullable().label('Função'),
  is_admin: yup.boolean().nullable().label('É admin'),
  name: yup.string().label('Nome'),
  email: yup.string().label('E-mail'),
  login: yup.string().label('Login'),
  status: yup.array().of(yup.string()).nullable().label('Status'),
  departments: yup.array().of(yup.number()).nullable().label('Departamentos'),
});

export default UserFilterSchema;
