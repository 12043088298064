import styled from 'styled-components';

import MuiContainer from '@mui/material/Container';
import MuiDivider from '@mui/material/Divider';

export const Container = styled(MuiContainer)`
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const PermissionGroupDivider = styled(MuiDivider)`
  flex-grow: 1;
`;
