export const ATTENDANCE_PERMISSIONS = {
  REASONS: {
    LIST: 'list-reasons',
    STORE: 'store-reasons',
    UPDATE: 'update-reasons',
    DESTROY: 'destroy-reasons',
    RESTORE: 'restore-reasons',
  },
  FUNNELS: {
    LIST: 'list-funnels',
    STORE: 'store-funnels',
    UPDATE: 'update-funnels',
    DESTROY: 'destroy-funnels',
    RESTORE: 'restore-funnels',
  },
  GOALS: {
    LIST: 'list-goals',
    STORE: 'store-goals',
    UPDATE: 'update-goals',
    DESTROY: 'destroy-goals',
    RESTORE: 'restore-goals',
  },
  OPPORTUNITIES: {
    LIST_FREE: 'list-opportunities-free',
    LIST_ALL: 'list-opportunities-all',
    UPDATE_ALL: 'update-opportunities-all',
    DESTROY: 'destroy-opportunities',
    UPDATE_STATUS: 'update-opportunities-status',
  },
};
