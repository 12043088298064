import { Column, ColumnBody, ColumnHeader, Container } from './styles';

const Kanban = {
  Container,
  Column,
  ColumnHeader,
  ColumnBody,
};

export default Kanban;
