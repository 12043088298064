import { Opportunity } from 'src/modules/attendance/@types/models';
import { store } from 'src/store';
import { getTheme } from 'src/styles/themes/default/_MuiTheme';

export function opportunityStatusTextColor(opportunity: Opportunity) {
  const theme = getTheme(store.getState().app.themeMode);

  if (opportunity.is_waiting) return theme.palette.warning.main;

  switch (opportunity.status) {
    case 'open':
      return theme.palette.text.primary;
    case 'gain':
      return theme.palette.success.main;
    case 'lost':
      return theme.palette.error.main;
    case 'transferred':
      return theme.palette.text.disabled;
    default:
      return theme.palette.text.primary;
  }
}

export function opportunityStatusThemeColor(status: string) {
  switch (status) {
    case 'open':
      return 'default';
    case 'gain':
      return 'success';
    case 'lost':
      return 'error';
    default:
      return 'primary';
  }
}
