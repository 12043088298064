import React, { ReactNode, useRef, useState } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestoreIcon from '@mui/icons-material/Restore';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Can, { CanProps } from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import CustomMenuItem from 'src/components/Table/CustomMenuItem';
import { STATUSES } from 'src/constants';
import { handleErrors } from 'src/utils/errors';

type APIEndpoint = (
  id: string | number,
  config?: AxiosRequestConfig,
) => Promise<AxiosResponse>;

type TableActionsProps = {
  resourceName: string;
  resourceId: string | number;
  resourceStatus?: string;
  startItems?: ReactNode;
  middleItems?: ReactNode;
  endItems?: ReactNode;
  editLink?: RouterLinkProps['to'];
  editPermissions?: CanProps['permissions'];
  destroyApiEndpoint?: APIEndpoint;
  destroyPermissions?: CanProps['permissions'];
  canDelete?: boolean;
  onlyDelete?: boolean;
  restoreApiEndpoint?: APIEndpoint;
  restorePermissions?: CanProps['permissions'];
  restoreText?: string;
  restoreStatuses?: string[];
  descriptionPrefix?: string;
  onFinish?: () => void;
};

const TableActions: React.FC<TableActionsProps> = ({
  resourceName,
  resourceId,
  resourceStatus,
  startItems,
  middleItems,
  endItems,
  editLink,
  editPermissions = [],
  destroyApiEndpoint,
  destroyPermissions = [],
  canDelete,
  onlyDelete,
  restoreApiEndpoint,
  restorePermissions = [],
  restoreText = 'Restaurar',
  restoreStatuses = [STATUSES.INACTIVE],
  descriptionPrefix = 'O Registro',
  onFinish,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);
  const deleteDialogRef = useRef<ConfirmDialogRef>(null);
  const restoreDialogRef = useRef<ConfirmDialogRef>(null);
  const [dialogLoading, setDialogLoading] = useState(false);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleOpenDeleteDialog = () => {
    deleteDialogRef.current?.show();
  };

  const handleConfirmDelete = async () => {
    try {
      if (!destroyApiEndpoint) return;

      setDialogLoading(true);

      const isDelete = deleteDialogRef.current?.isDelete();
      let deleteConfig: AxiosRequestConfig = {};
      if (isDelete) {
        deleteConfig = { data: { delete: true } };
      }

      await destroyApiEndpoint(resourceId, deleteConfig);

      toast.success(
        `${resourceName} ${isDelete ? 'excluido' : 'desativado'}(a)!`,
      );

      deleteDialogRef.current?.hide();

      if (onFinish) onFinish();
    } catch (error) {
      handleErrors(error, `Erro ao desativar ${resourceName.toLowerCase()}.`);
    } finally {
      setDialogLoading(false);
    }
  };

  const handleOpenRestoreDialog = () => {
    restoreDialogRef.current?.show();
  };

  const handleConfirmRestore = async () => {
    try {
      if (!restoreApiEndpoint) return;
      setDialogLoading(true);

      await restoreApiEndpoint(resourceId);
      toast.success(`${resourceName} restaurado(a)!`);

      restoreDialogRef.current?.hide();
      if (onFinish) onFinish();
    } catch (error) {
      handleErrors(error, `Erro ao restaurar ${resourceName.toLowerCase()}.`);
    } finally {
      setDialogLoading(false);
    }
  };

  function renderDestroyButton() {
    if (!destroyApiEndpoint) return null;
    if (restoreStatuses?.includes(resourceStatus || '') && !canDelete)
      return null;

    return (
      <Can permissions={destroyPermissions}>
        <CustomMenuItem
          text={canDelete || onlyDelete ? 'Excluir' : 'Desativar'}
          Icon={DeleteIcon}
          iconProps={{ color: 'error' }}
          onClick={handleOpenDeleteDialog}
        />
      </Can>
    );
  }

  function renderRestoreButton() {
    if (!restoreApiEndpoint) return null;
    if (!restoreStatuses?.includes(resourceStatus || '')) return null;

    return (
      <Can permissions={restorePermissions}>
        <CustomMenuItem
          text={restoreText}
          Icon={RestoreIcon}
          iconProps={{ color: 'primary' }}
          onClick={handleOpenRestoreDialog}
        />
      </Can>
    );
  }

  return (
    <div>
      <IconButton size="medium" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {startItems && (
          <>
            {startItems}

            <Divider />
          </>
        )}

        {editLink && (
          <Can permissions={editPermissions}>
            <CustomMenuItem
              button
              component={(props: any) => (
                <RouterLink {...props} to={editLink} />
              )}
              text="Editar"
              Icon={EditIcon}
              iconProps={{ color: 'primary' }}
            />
          </Can>
        )}

        {middleItems}

        <Divider />

        {renderDestroyButton()}

        {renderRestoreButton()}

        {endItems && (
          <>
            <Divider />

            {endItems}
          </>
        )}
      </Menu>

      <ConfirmDialog
        ref={deleteDialogRef}
        title={`${
          onlyDelete ? 'Excluir' : 'Desativar'
        } ${resourceName} ${resourceId}`}
        description={`${descriptionPrefix} será ${
          onlyDelete ? 'excluido' : 'desativado'
        }(a). Confirma esta ação?`}
        confirmColor={'error'}
        onConfirm={handleConfirmDelete}
        loading={dialogLoading}
        canDelete={canDelete}
      />

      <ConfirmDialog
        ref={restoreDialogRef}
        title={`Restaurar ${resourceName} ${resourceId}`}
        description={`${descriptionPrefix} será restaurado(a). Confirma esta ação?`}
        confirmColor="primary"
        onConfirm={handleConfirmRestore}
        loading={dialogLoading}
      />
    </div>
  );
};

export default TableActions;
