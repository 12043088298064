import React, { useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import TextField from 'src/components/Form/TextField';
import AutocompleteFunnelSteps from 'src/modules/attendance/components/Form/_common/AutocompleteFunnelSteps';
import attendanceApi from 'src/modules/attendance/services/api';
import { handleErrors } from 'src/utils/errors';

type StepCellProps = {
  funnelId: number;
  funnelStepId: number;
};

const StepCell: React.FC<StepCellProps> = ({ funnelId, funnelStepId }) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);

  async function handleChangeOpportunityStatus(formData) {
    try {
      if (formData.funnel_step_id === funnelStepId || loading) return;
      setLoading(true);

      await attendanceApi.admin.funnels.opportunities.updateFunnelOpportunity(
        funnelId,
        formData.opportunity_id,
        { funnel_step_id: formData.funnel_step_id },
      );
    } catch (error) {
      handleErrors(error, 'Erro ao mudar status da opportunidade.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleChangeOpportunityStatus}
      style={{ width: '100%' }}
      initialData={{ funnel_step_id: funnelStepId }}
    >
      <TextField hidden name="opportunity_id" value={funnelId} />
      <AutocompleteFunnelSteps
        name="funnel_step_id"
        fullWidth
        funnelId={funnelId}
        size="small"
        disableClearable
        disabled={loading}
        onChange={() => formRef.current?.submitForm()}
      />
    </Form>
  );
};

export default StepCell;
