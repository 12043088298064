import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import { Funnel, Opportunity } from 'src/modules/attendance/@types/models';
import AutocompleteFunnelAttendants from 'src/modules/attendance/components/Form/_common/AutocompleteFunnelAttendants';
import AutocompleteFunnels from 'src/modules/attendance/components/Form/_common/AutocompleteFunnels';
import attendanceApi from 'src/modules/attendance/services/api';
import { handleErrors } from 'src/utils/errors';

export type OpportunityTransferFunnelDialogProps = {
  funnelId: number;
  opportunity: Opportunity;
  onClose: () => void;
  onSuccess?: () => void;
} & DialogProps;

const OpportunityTransferFunnelDialog: React.FC<
  OpportunityTransferFunnelDialogProps
> = ({ funnelId, opportunity, onSuccess, onClose, ...rest }) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [selectedFunnel, setSelectedFunnel] = useState<Funnel>();

  function handleChangeFunnel(value: AutocompleteValue<Funnel>) {
    if (Array.isArray(value) || !value) {
      setSelectedFunnel(undefined);
    } else {
      setSelectedFunnel(value.data);
    }
  }

  async function handleSubmit(formData) {
    try {
      setLoading(true);

      await attendanceApi.admin.funnels.opportunities.transferFunnelOpportunity(
        funnelId,
        opportunity.id,
        formData,
      );

      toast.success('Oportunidade Transferida!');

      if (onSuccess) onSuccess();

      onClose();
    } catch (error) {
      handleErrors(error, 'Erro ao transferir oportunidade.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" {...rest}>
      <DialogTitle align="center">Transferir Oportunidade de Funil</DialogTitle>

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{ funnel_id: selectedFunnel?.id }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutocompleteFunnels
                name="funnel_id"
                label="Funil de Destino"
                onChange={handleChangeFunnel}
                getOptionDisabled={(option) => option.value === funnelId}
                textFieldProps={{ required: true }}
              />
            </Grid>

            {selectedFunnel && (
              <Grid item xs={12}>
                <AutocompleteFunnelAttendants
                  name="attendant_id"
                  funnelId={selectedFunnel.id}
                  label="Atendente Responsável (Opcional)"
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                startIcon={<CloseIcon />}
                color="error"
                onClick={onClose}
                disabled={loading}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                startIcon={<CheckIcon />}
                color="success"
                variant="contained"
                loading={loading}
              >
                Transferir
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default OpportunityTransferFunnelDialog;
