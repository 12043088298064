import { FC, useContext } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuListItems from 'src/routes/Private/Layout/SideNavigation/MenuListItems';
import PrivateContext from 'src/routes/Private/PrivateContext';

import { Drawer, ToolbarIcon } from './styles';

const SideNavigation: FC = () => {
  const { sideMenuIsOpen, closeSideMenu } = useContext(PrivateContext);

  return (
    <Drawer variant="persistent" open={sideMenuIsOpen}>
      <ToolbarIcon>
        <IconButton onClick={closeSideMenu} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </ToolbarIcon>

      <Divider />

      <MenuListItems />
    </Drawer>
  );
};

export default SideNavigation;
