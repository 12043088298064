import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { IUser } from 'src/interfaces/models';
import attendanceApi from 'src/modules/attendance/services/api';

type AutocompleteFunnelAttendantsProps = {
  funnelId: number;
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps<IUser>, 'options'>;

const AutocompleteFunnelAttendants: React.FC<
  AutocompleteFunnelAttendantsProps
> = ({ funnelId, requestConfig, ...rest }) => {
  const { isLoading, data: response } =
    attendanceApi.admin.common.useFunnelAttendantsQuery(funnelId, {
      requestConfig,
    });

  const options =
    response?.data?.map((attendant) => ({
      key: attendant.id,
      label: attendant.name,
      value: attendant.id,
      data: attendant,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteFunnelAttendants;
