export const OPPORTUNITY_STATUS_LABEL = {
  open: 'Aberto',
  gain: 'Ganho',
  lost: 'Perdido',
  transferred: 'Transferido',
};

export const OPPORTUNITY_STATUS_OPTIONS = Object.keys(OPPORTUNITY_STATUS_LABEL)
  .filter((key) => key !== 'transferred')
  .map((key, index) => ({
    key: index,
    label: OPPORTUNITY_STATUS_LABEL[key],
    value: key,
  }));

export const REASON_TYPE_LABEL = {
  gain: 'Ganho',
  lost: 'Perda',
};

export const REASON_TYPE_OPTIONS = Object.keys(REASON_TYPE_LABEL).map(
  (key, index) => ({
    key: index,
    label: REASON_TYPE_LABEL[key],
    value: key,
  }),
);
