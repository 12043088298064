import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { Grid, Tooltip } from '@mui/material';
import { differenceInDays, isAfter } from 'date-fns';
import { Opportunity } from 'src/modules/attendance/@types/models';

type OpportunityUnreadProps = {
  opportunity: Opportunity;
};

/**
 * Checks implemented:
 * 1. !attendance && +30 days created_at = blue_circle
 * 2. attendance && +30 days = blue_circle
 * 3. interest > attendance = red_circle
 */
const OpportunityUnread: React.FC<OpportunityUnreadProps> = ({
  opportunity,
}) => {
  const currentDate = new Date();

  if (!opportunity.last_attendance_at) {
    const createdDate = new Date(opportunity.created_at);

    const createdDiffDays = differenceInDays(currentDate, createdDate);
    if (createdDiffDays >= 30) {
      return (
        <Grid item>
          <Tooltip title="Não atendido a 30+ dias">
            <CircleIcon fontSize="inherit" color="info" />
          </Tooltip>
        </Grid>
      );
    }
  } else {
    const lastAttedanceDate = new Date(opportunity.last_attendance_at);

    const attendanceDiffDays = differenceInDays(currentDate, lastAttedanceDate);

    if (attendanceDiffDays >= 30) {
      return (
        <Grid item>
          <Tooltip title="30+ dias sem atendimento">
            <CircleIcon fontSize="inherit" color="info" />
          </Tooltip>
        </Grid>
      );
    }
  }

  if (!opportunity.last_interest_at) return null;
  const lastInterestDate = new Date(opportunity.last_interest_at);
  const lastAttedanceDate = opportunity.last_attendance_at
    ? new Date(opportunity.last_attendance_at)
    : null;
  if (!lastAttedanceDate || isAfter(lastInterestDate, lastAttedanceDate)) {
    return (
      <Grid item>
        <Tooltip title="Interesse do cliente não lido">
          <CircleIcon fontSize="inherit" color="error" />
        </Tooltip>
      </Grid>
    );
  }

  return null;
};

export default OpportunityUnread;
