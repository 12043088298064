import yup from 'src/libs/yup';

export default yup.object().shape({
  login: yup.string().required().label('Usuário'),
  password: yup.string().required().label('Senha'),
  new_password: yup.string().min(6).label('Nova Senha'),
  new_password_confirmation: yup
    .string()
    .equalTo(yup.ref('new_password'), 'Nova Senha')
    .label('Confirmação'),
});
