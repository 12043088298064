import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import AutocompleteUsers from 'src/components/Form/_Common/AutocompleteUsers';
import Autocomplete from 'src/components/Form/Autocomplete';
import DatePickerOperator from 'src/components/Form/DatePickerOperator';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import {
  objectToQuery,
  orderDirections,
  queryToObject,
} from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import DefaultFilterSchema from 'src/validators/DefaultFilter.schema';

import { FilterDrawerProps, FilterDrawerRef } from './interfaces';
import { Drawer } from './styles';

const FilterDrawer: ForwardRefRenderFunction<
  FilterDrawerRef,
  FilterDrawerProps
> = (
  {
    children,
    disableDefaultFilters,
    columns,
    yupSchema,
    disablePagination,
    ...rest
  },
  ref,
) => {
  const formRef = useRef<FormHandles>(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useImperativeHandle(ref, () => ({
    open: () => setFilterOpen(true),
    close: () => setFilterOpen(false),
    getFormRef: () => formRef,
  }));

  const applyFilters = useCallback(
    (data: Record<string, any>) => {
      const query = new URLSearchParams(location.search);

      if (disablePagination) {
        delete data.page;
        delete data.per_page;
      } else {
        data.page = 1;
        data.per_page = query.get('per_page') || 10;
      }

      history.replace({
        pathname: location.pathname,
        search: objectToQuery(data, { addQueryPrefix: false }),
      });
    },
    [location.search, location.pathname, history, disablePagination],
  );

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        setDisabled(true);
        formRef.current?.setErrors({});

        let filterData = {};

        if (!disableDefaultFilters) {
          const { success, data, errors } = await yupValidate(
            DefaultFilterSchema,
            formData,
          );

          if (!success) {
            return showFormErrors(errors, formRef);
          }

          filterData = data;
        }

        if (yupSchema) {
          const { success, data, errors } = await yupValidate(
            yupSchema,
            formData,
          );

          if (!success) {
            return showFormErrors(errors, formRef);
          }

          filterData = { ...filterData, ...data };
        }

        applyFilters(filterData);
        setFilterOpen(false);
      } catch (error) {
        handleErrors(error, 'Erro ao aplicar filtros.');
      } finally {
        setDisabled(false);
      }
    },
    [disableDefaultFilters, yupSchema, applyFilters, setFilterOpen],
  );

  useEffect(() => {
    if (filterOpen && formRef.current) {
      const query = new URLSearchParams(location.search);
      const formData = queryToObject(query);
      setTimeout(() => {
        formRef.current?.setData(formData);
      }, 0);
    }
  }, [filterOpen, location.search]);

  return (
    <Drawer
      open={filterOpen}
      onOpen={() => setFilterOpen(true)}
      onClose={() => setFilterOpen(false)}
      anchor="right"
      {...rest}
    >
      <Typography color="primary" variant="h5" align="center">
        Filtros
      </Typography>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          {!disableDefaultFilters && (
            <>
              <Grid item xs={12}>
                <DatePickerOperator
                  name="created_at"
                  label="Data de Criação"
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <DatePickerOperator
                  name="updated_at"
                  label="Data de Alteração"
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <AutocompleteUsers
                  name="created_by"
                  label="Criado Por"
                  size="small"
                  multiple
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteUsers
                  name="updated_by"
                  label="Alterado Por"
                  size="small"
                  multiple
                />
              </Grid>

              {columns && (
                <Scope path="order_by[0]">
                  <Grid item xs={8}>
                    <Autocomplete
                      name="column"
                      label="Ordenar por Coluna"
                      options={columns.map((column) => ({
                        key: column.field,
                        label: column.headerName || '',
                        value: column.field,
                      }))}
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Autocomplete
                      name="direction"
                      label="Direção"
                      options={orderDirections}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                </Scope>
              )}
            </>
          )}

          {children}

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Button
                type="reset"
                disabled={disabled}
                variant="contained"
                color="error"
                startIcon={<CloseIcon />}
                onClick={() => formRef.current?.setData({})}
              >
                Limpar
              </Button>

              <Button
                type="submit"
                disabled={disabled}
                variant="contained"
                color="primary"
                startIcon={<CheckIcon />}
              >
                Aplicar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Drawer>
  );
};

export default forwardRef(FilterDrawer);
