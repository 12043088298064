import { FC, useContext } from 'react';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Package from 'src/../package.json';
import { useStoreDispatch, useStoreSelector } from 'src/hooks/store';
import UserMenu from 'src/routes/Private/Layout/TopNavigation/UserMenu';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { AppActions } from 'src/store/ducks/app';

import { AppBar, Toolbar, MenuButton, Title } from './styles';

const TopNavigation: FC = () => {
  const { sideMenuIsOpen, openSideMenu } = useContext(PrivateContext);
  const dispatch = useStoreDispatch();
  const { themeMode } = useStoreSelector((state) => state.app);

  function handleChangeThemeMode() {
    dispatch(
      AppActions.appToggleThemeMode(themeMode === 'light' ? 'dark' : 'light'),
    );
  }

  return (
    <AppBar position="absolute" isOpen={sideMenuIsOpen}>
      <Toolbar>
        <MenuButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={openSideMenu}
          isOpen={sideMenuIsOpen}
        >
          <MenuIcon />
        </MenuButton>

        <Title variant="h6" color="inherit" noWrap>
          {process.env.REACT_APP_TITLE_PREFIX}{' '}
          <Typography variant="caption" color="inherit">
            v{Package.version}
          </Typography>
        </Title>

        <IconButton
          sx={{ ml: 1 }}
          onClick={handleChangeThemeMode}
          color="inherit"
          title="Mudar cor do tema"
        >
          {themeMode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>

        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default TopNavigation;
