import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import BackButton from 'src/components/BackButton';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { FunnelStep } from 'src/modules/attendance/@types/models';
import { SaveFunnelStepParams } from 'src/modules/attendance/@types/params';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';
import attendanceApi from 'src/modules/attendance/services/api';
import {
  FunnelStepStoreSchema,
  FunnelStepUpdateSchema,
} from 'src/modules/attendance/validators/FunnelStep/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } =
    useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [stepStatus, setStepStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<SaveFunnelStepParams>();
  const { url } = useRouteMatch();

  const stepSchema = params.stepId
    ? FunnelStepUpdateSchema
    : FunnelStepStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.stepId) {
        const { data: step } =
          await attendanceApi.admin.funnels.steps.getFunnelStep(
            params.id,
            params.stepId,
          );
        setStepStatus(step.status);

        setTimeout(() => {
          formRef.current?.setData(step);
        });
      }
    } catch (error) {
      handleErrors(error, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, params.stepId, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<FunnelStep> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(stepSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newStep: FunnelStep;
      if (params.stepId) {
        const response =
          await attendanceApi.admin.funnels.steps.updateFunnelStep(
            params.id,
            params.stepId,
            data,
          );
        newStep = response.data;
      } else {
        const response =
          await attendanceApi.admin.funnels.steps.storeFunnelStep(
            params.id,
            data,
          );
        newStep = response.data;
      }

      toast.success('Etapa salva com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.stepId) {
        history.replace(`${url}/${newStep.id}`);
      }
    } catch (error) {
      handleErrors(error, 'Erro', stepSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await attendanceApi.admin.funnels.steps.destroyFunnelStep(
        params.id,
        params.stepId,
      );

      toast.success('Etapa desativada!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao desativar Etapa');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await attendanceApi.admin.funnels.steps.restoreFunnelStep(
        params.id,
        params.stepId,
      );

      toast.success('Etapa restaurada!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao atualizar Etapa.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: [ATTENDANCE_PERMISSIONS.FUNNELS.STORE],
        update: [ATTENDANCE_PERMISSIONS.FUNNELS.UPDATE],
        destroy: [ATTENDANCE_PERMISSIONS.FUNNELS.DESTROY],
        restore: [ATTENDANCE_PERMISSIONS.FUNNELS.RESTORE],
      }}
      resourceName="Etapa"
      onSave={handleSave}
      onDestroy={handleDestroy}
      onRestore={handleRestore}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.stepId}
      isActive={stepStatus === STATUSES.ACTIVE}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.stepId ? 'Editar' : 'Nova'} Etapa
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm>
                  <TextField name="name" label="Nome" required />
                </Grid>

                {params.stepId && (
                  <Grid item xs={12} sm={2}>
                    <TextField name="ordination" label="Ordem" required />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label="Descrição"
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Save;
