import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';
import { WHATSAPP_PERMISSIONS } from 'src/modules/whatsapp/constants/permissions';

export const whatsappMenu: ISideMenuItem = {
  primaryText: 'WhatsApp',
  icon: WhatsAppIcon,
  route: '/whatsapp',
  subItems: [
    {
      primaryText: 'Configurações',
      subItems: [
        {
          primaryText: 'Instâncias',
          route: '/whatsapp/instancias',
          search: 'status[0]=active',
          permissions: [WHATSAPP_PERMISSIONS.Z_API_INSTANCES.LIST],
        },
      ],
    },
  ],
};
