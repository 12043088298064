import yup from 'src/libs/yup';

export const RoleStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  group: yup.string().label('Grupo'),
  permissions: yup.array().of(yup.boolean()),
});

export const RoleUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  group: yup.string().label('Grupo'),
  permissions: yup.array().of(yup.boolean()),
});
