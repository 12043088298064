import React from 'react';

import Apexchart from 'src/components/Apexchart';
import { Dashboard } from 'src/interfaces/models/Dashboard';

type OpportunitiesWithoutAttendanceChartProps = {
  data?: Dashboard['opportunitiesWithoutAttendance'];
};

const OpportunitiesWithoutAttendanceChart: React.FC<
  OpportunitiesWithoutAttendanceChartProps
> = ({ data }) => {
  return (
    <Apexchart
      type="bar"
      options={{
        title: {
          text: 'Oportunidades sem Atendimento',
          align: 'center',
        },
        chart: { id: 'opportunities-without-attendance' },
        xaxis: {
          categories: data?.labels || [''],
        },
        legend: {
          showForSingleSeries: true,
        },
        dataLabels: {
          dropShadow: {
            enabled: true,
          },
        },
      }}
      series={data?.series || []}
    />
  );
};

export default OpportunitiesWithoutAttendanceChart;
