import styled from 'styled-components';

export const FreeChatLabel = styled.div`
  &::after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.palette.success.dark};
  }
`;
