import { FC } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import Button from 'src/components/Button';

import { CustomToolbarProps } from './interfaces';

const CustomToolbar: FC<CustomToolbarProps> = ({
  showFilters,
  onFilterClick,
  ...rest
}) => {
  return (
    <GridToolbarContainer {...rest}>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />

      {showFilters && (
        <Button
          startIcon={<FilterListIcon />}
          onClick={onFilterClick}
          color="primary"
          size="small"
        >
          Filtros
        </Button>
      )}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
