import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import LinearProgressWithLabel from 'src/components/LinearProgressWithLabel';
import api from 'src/services/api';
import { formatDate } from 'src/utils/helpers';
import { fCurrencyInt } from 'src/utils/number';

const GoalsTab: React.FC = () => {
  const { data: response } = api.admin.common.useDashboardGoalsQuery();

  return (
    <Grid2 container spacing={1} disableEqualOverflow>
      {response?.data.map((item) => (
        <Grid2 key={item.goal.id} xs={12}>
          <Card>
            <CardHeader
              title={item.goal.name}
              subheader={`${formatDate(item.goal.start_date)} - ${formatDate(
                item.goal.end_date,
              )}`}
            />

            <CardContent>
              <Grid2 container spacing={2} disableEqualOverflow>
                {!!item.goal.funnels?.length && (
                  <Grid2 xs={12}>
                    <Typography variant="body2">
                      Funis:{' '}
                      {item.goal.funnels
                        .map((funnel) => funnel.name)
                        .join(', ')}
                    </Typography>
                  </Grid2>
                )}

                {item.global && (
                  <>
                    <Grid2 xs={12}>
                      <Typography variant="body2">
                        Meta da Equipe -{' '}
                        <strong>{fCurrencyInt(item.global.sum_amount)}</strong>{' '}
                        <Typography
                          component="span"
                          variant="inherit"
                          color="text.secondary"
                        >
                          de {fCurrencyInt(item.goal.global_amount)} (
                          {item.global.total_days} dias)
                        </Typography>
                      </Typography>

                      <LinearProgressWithLabel
                        value={item.global.percent}
                        color="success"
                      />

                      <Box display="flex" gap={1}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Deveria ter atingido -{' '}
                          <strong>{fCurrencyInt(item.global.should_be)}</strong>{' '}
                          ({item.global.current_days} dias)
                        </Typography>

                        <Box>
                          <Divider orientation="vertical" />
                        </Box>

                        <Typography variant="subtitle2" color="text.secondary">
                          Previsão para atingir -{' '}
                          <strong>{fCurrencyInt(item.global.prevision)}</strong>{' '}
                          ({item.global.total_days - item.global.current_days}{' '}
                          dias restantes)
                        </Typography>
                      </Box>
                    </Grid2>

                    {!!item.individuals.length && (
                      <Grid2 xs={12}>
                        <Divider />
                      </Grid2>
                    )}
                  </>
                )}

                {item.individuals.map((indiv) => (
                  <Grid2 key={indiv.attendant_id} xs={12} md={6} lg={4}>
                    <Typography variant="body2">
                      {indiv.attendant_name} -{' '}
                      <strong>{fCurrencyInt(indiv.sum_amount)}</strong>{' '}
                      <Typography
                        component="span"
                        variant="inherit"
                        color="text.secondary"
                      >
                        de {fCurrencyInt(indiv.goal_amount)}
                      </Typography>
                    </Typography>

                    <LinearProgressWithLabel
                      value={indiv.percent}
                      color="primary"
                    />
                  </Grid2>
                ))}
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
      ))}
    </Grid2>
  );
};

export default GoalsTab;
