import Dashboard from './Dashboard';
import * as Departments from './Departments';
import * as Leads from './Leads';
import Profile from './Profile';
import * as Roles from './Roles';
import * as Users from './Users';

export default {
  Dashboard,
  Users,
  Profile,
  Roles,
  Departments,
  Leads,
};
