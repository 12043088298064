import { FC, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles, SubmitHandler } from '@unform/core';
import TextField from 'src/components/Form/TextField';
import Link from 'src/components/Link';
import api from 'src/services/api';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import recoverPasswordSchema from 'src/validators/User/recoverPassword.schema';

import { Form, SubmitButton } from './styles';

const RecoverPassword: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        recoverPasswordSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      await api.admin.auth.resetPassword({
        token,
        ...data,
      });

      toast('Senha alterada com sucesso!', { type: 'success' });

      history.push('/');
    } catch (error) {
      handleErrors(error, 'Erro', recoverPasswordSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleOnSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" align="center">
            Recuperar Senha
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="password"
            type="password"
            label="Nova Senha"
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="password_confirmation"
            type="password"
            label="Confirmar Nova Senha"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <SubmitButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Recuperar Senha
          </SubmitButton>
        </Grid>

        <Grid item xs={12}>
          <Link to="/" variant="body2">
            Voltar ao Login
          </Link>
        </Grid>
      </Grid>
    </Form>
  );
};

export default RecoverPassword;
