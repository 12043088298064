import React from 'react';

import {
  DataGrid,
  GridColDef,
  GridInputSelectionModel,
} from '@mui/x-data-grid';
import { OpportunityInterestProduct } from 'src/modules/attendance/@types/models';
import masks from 'src/utils/masks';

const columns: GridColDef<OpportunityInterestProduct>[] = [
  { field: 'reference_code', headerName: 'Codigo', width: 70 },
  {
    field: 'name',
    headerName: 'Tipo - Produto',
    flex: 1,
    valueGetter({ row }) {
      return `${row.type} - ${row.name}`;
    },
  },
  { field: 'quantity', headerName: 'Qtde', width: 60 },
  {
    field: 'price',
    headerName: 'Preço',
    valueFormatter({ value }) {
      return masks.currency(value);
    },
  },
];

type ProductsListProps = {
  products?: OpportunityInterestProduct[];
  selectedProducts?: GridInputSelectionModel;
};

const ProductsList: React.FC<ProductsListProps> = ({
  products = [],
  selectedProducts = [],
}) => {
  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick
      columns={columns}
      rows={products}
      hideFooter
      selectionModel={selectedProducts}
      density="compact"
    />
  );
};

export default ProductsList;
