import { FC, PropsWithChildren } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import Package from 'src/../package.json';
import Link from 'src/components/Link';

import { Container, GridImage, Paper } from './styles';

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container container>
      <GridImage item xs={false} sm={4} md={7} />
      <Grid item xs={12} sm={8} md={5}>
        <Paper elevation={6} square>
          {children}

          <Box mt={5} justifySelf="flex-end">
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link
                color="inherit"
                to={{ pathname: 'https://wisolutions.com.br' }}
                target="_blank"
              >
                Wi Solutions
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
              <br />
              <small>v{Package.version}</small>
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Container>
  );
};

export default AuthLayout;
