import { IRouteInterface } from 'src/interfaces/IRoutes';
import { WHATSAPP_PERMISSIONS } from 'src/modules/whatsapp/constants/permissions';

import PrivatePages from '../pages/Private';

export const whatsappRouteList: IRouteInterface[] = [
  {
    exact: true,
    component: PrivatePages.ZApiInstances.List,
    label: 'Instâncias',
    path: '/whatsapp/instancias',
    type: 'private',
    permissions: [WHATSAPP_PERMISSIONS.Z_API_INSTANCES.LIST],
  },
  {
    component: PrivatePages.ZApiInstances.Save,
    label: 'Nova Instância',
    path: '/whatsapp/instancias/novo',
    type: 'private',
    permissions: [WHATSAPP_PERMISSIONS.Z_API_INSTANCES.STORE],
  },
  {
    component: PrivatePages.ZApiInstances.Save,
    label: 'Editar Instância',
    path: '/whatsapp/instancias/:id',
    type: 'private',
    permissions: [WHATSAPP_PERMISSIONS.Z_API_INSTANCES.UPDATE],
  },
  {
    component: PrivatePages.Chats,
    label: 'Conversas WhatsApp',
    path: '/whatsapp/conversas/:zApiInstanceId',
    type: 'private',
    permissions: [WHATSAPP_PERMISSIONS.OMNICHANNEL.LIST],
  },
];
