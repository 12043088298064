import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { IPermission, IRole } from 'src/interfaces/models';

import { ModuleTitle, TitleDivider } from './styles';

type ModuleGroups = {
  [key: string]: IPermission[];
};
type PermissionsModules = {
  [key: string]: ModuleGroups;
};

export type RolePermissionsProps = {
  role: IRole;
};

const RolePermissions: React.FC<RolePermissionsProps> = ({ role }) => {
  const modules: PermissionsModules = {};
  role.permissions?.forEach((permission) => {
    const currentModule = modules[permission.module] || {};
    const currentGroup = currentModule[permission.group] || [];

    currentGroup.push(permission);

    currentModule[permission.group] = currentGroup;
    modules[permission.module] = currentModule;
  });

  return (
    <Grid container spacing={2}>
      {Object.keys(modules).map((moduleKey) => (
        <Grid item key={moduleKey} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container>
                <TitleDivider variant="middle" textAlign="left">
                  <ModuleTitle variant="h6">{moduleKey}</ModuleTitle>
                </TitleDivider>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                {Object.keys(modules[moduleKey]).map((groupKey) => (
                  <Grid item xs={12} md={6} xl={4} key={groupKey}>
                    <Card>
                      <CardHeader subheader={groupKey} />
                      <CardContent>
                        {modules[moduleKey][groupKey]
                          .sort((a, b) => a.id - b.id)
                          .map((permission) => (
                            <FormControlLabel
                              key={permission.id}
                              label={permission.name}
                              control={
                                <Checkbox size="small" color="warning" />
                              }
                              checked
                            />
                          ))}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default RolePermissions;
