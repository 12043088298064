import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ForwardIcon from '@mui/icons-material/Forward';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import { useStoreSelector } from 'src/hooks/store';
import { Opportunity } from 'src/modules/attendance/@types/models';
import AutocompleteFunnelAttendants from 'src/modules/attendance/components/Form/_common/AutocompleteFunnelAttendants';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';
import attendanceApi from 'src/modules/attendance/services/api';
import {
  OpportunityAttendantsSchema,
  OpportunityTransferSchema,
} from 'src/modules/attendance/validators/Opportunity/save.schema';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import { hasPermission } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';

export type OpportunityAttendantsDialogProps = {
  funnelId: number;
  opportunity: Opportunity;
  onClose: () => void;
  onSuccessTransfer?: () => void;
  onSuccessAttendants?: () => void;
} & DialogProps;

const OpportunityAttendantsDialog: React.FC<
  OpportunityAttendantsDialogProps
> = ({
  funnelId,
  opportunity,
  onSuccessTransfer,
  onSuccessAttendants,
  onClose,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const transferFormRef = useRef<FormHandles>(null);
  const attendantsFormRef = useRef<FormHandles>(null);
  const { user } = useStoreSelector((state) => state.auth);

  const canUpdateAttendants =
    opportunity.attendant_id === user?.id ||
    hasPermission([ATTENDANCE_PERMISSIONS.OPPORTUNITIES.UPDATE_ALL]);

  const loadAttendants = useCallback(async () => {
    const { data } =
      await attendanceApi.admin.funnels.opportunities.attendants.getOpportunityAttendants(
        funnelId,
        opportunity.id,
      );

    const currentAttendantsIds = data.map((attendant) => attendant.id);
    if (currentAttendantsIds) {
      attendantsFormRef.current?.setData({
        attendants: currentAttendantsIds,
      });
    }
  }, [funnelId, opportunity.id]);

  useEffect(() => {
    if (rest.open) {
      loadAttendants();
    }
  }, [rest.open, loadAttendants]);

  function handleClose() {
    if (loading) return;

    onClose();
  }

  async function handleSubmitTransfer(formData) {
    try {
      setLoading(true);

      formData.opportunities_ids = [opportunity.id];

      const { success, data, errors } = await yupValidate(
        OpportunityTransferSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, transferFormRef);
      }

      await attendanceApi.admin.funnels.opportunities.transferFunnelOpportunitiesAttendant(
        funnelId,
        data,
      );

      if (onSuccessTransfer) onSuccessTransfer();
      toast.success('Oportunidade transferida!');
      loadAttendants();
    } catch (error) {
      handleErrors(
        error,
        'Erro ao transferir oportunidade.',
        OpportunityTransferSchema,
        transferFormRef,
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitAttendants(formData) {
    try {
      setLoading(true);

      const { success, data, errors } = await yupValidate(
        OpportunityAttendantsSchema,
        formData,
      );

      if (!success) return showFormErrors(errors, attendantsFormRef);

      await attendanceApi.admin.funnels.opportunities.attendants.updateOpportunityAttendants(
        funnelId,
        opportunity.id,
        data,
      );

      if (onSuccessAttendants) onSuccessAttendants();
      toast.success('Atendentes atualizados!');
    } catch (error) {
      handleErrors(
        error,
        'Erro ao atualizar atendentes.',
        OpportunityTransferSchema,
        transferFormRef,
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleOpportunityAttendantExit() {
    try {
      setLoading(true);

      await attendanceApi.admin.funnels.opportunities.attendants.opportunityAttendantExit(
        funnelId,
        opportunity.id,
      );

      loadAttendants();
    } catch (error) {
      handleErrors(error, 'Erro ao sair da oportunidade.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} {...rest}>
      <DialogTitle align="center">
        Atendentes com acesso a Oportunidade
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={6}>
              <Form
                ref={transferFormRef}
                onSubmit={handleSubmitTransfer}
                initialData={{
                  attendant_id: opportunity.attendant_id,
                }}
              >
                <Grid container spacing={1} padding={1}>
                  <Grid item xs={12}>
                    <Divider textAlign="left">Transferir Oportunidade</Divider>
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteFunnelAttendants
                      name="attendant_id"
                      funnelId={funnelId}
                      requestConfig={{
                        params: { user_has_access: true },
                      }}
                      label="Atendante Responsável"
                      disabled={loading || !canUpdateAttendants}
                    />
                  </Grid>

                  <Grid item xs={12} textAlign="right">
                    <Button
                      type="submit"
                      endIcon={<ForwardIcon />}
                      color="success"
                      disabled={loading || !canUpdateAttendants}
                      variant="contained"
                    >
                      Transferir
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={6}>
              <Form ref={attendantsFormRef} onSubmit={handleSubmitAttendants}>
                <Grid container spacing={1} padding={1}>
                  <Grid item xs={12}>
                    <Divider textAlign="left">Atendentes</Divider>
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteFunnelAttendants
                      name="attendants"
                      funnelId={funnelId}
                      requestConfig={{
                        params: { user_has_access: true },
                      }}
                      label="Atendentes Extras"
                      multiple
                      getOptionDisabled={(option) =>
                        option.value === opportunity.attendant_id
                      }
                      disabled={loading || !canUpdateAttendants}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container gap={1} justifyContent="space-between">
                      <Button
                        startIcon={<CloseIcon />}
                        color="error"
                        disabled={loading || canUpdateAttendants}
                        onClick={handleOpportunityAttendantExit}
                      >
                        Sair
                      </Button>
                      <Button
                        type="submit"
                        endIcon={<CheckIcon />}
                        color="success"
                        disabled={loading || !canUpdateAttendants}
                        variant="contained"
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OpportunityAttendantsDialog;
