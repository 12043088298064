import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { ZApiInstance } from 'src/modules/whatsapp/@types/models';
import whatsappApi from 'src/modules/whatsapp/services/api';

type AutocompleteZApiInstanceProps = {
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps<ZApiInstance>, 'options'>;

const AutocompleteZApiInstances: React.FC<AutocompleteZApiInstanceProps> = ({
  requestConfig,
  ...rest
}) => {
  const { isLoading, data: response } =
    whatsappApi.admin.common.useZApiInstancesQuery({
      requestConfig,
    });

  const options =
    response?.data?.map((intance) => ({
      key: intance.id,
      label: intance.name,
      value: intance.id,
      data: intance,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteZApiInstances;
