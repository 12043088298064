import yup from 'src/libs/yup';

export const UserStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().email().label('Nome'),
  login: yup.string().required().label('Login'),
  password: yup.string().required().label('Senha'),
  password_confirmation: yup
    .string()
    .required()
    .equalTo(yup.ref('password'), 'senha')
    .label('Confirmação'),
  change_password: yup.boolean().label('Mudar Senha'),
  role_id: yup.number().nullable().label('Função'),
  is_admin: yup.bool().nullable().label('Administrador'),
  departments: yup.array().of(yup.number()).label('Departamentos'),
  document: yup.string().cpfCnpj().label('CPF / CNPJ'),
  responsible: yup.string().label('Responsável'),
  company_name: yup.string().label('Razão Social'),
  state_registration: yup.string().label('Inscrição Estadual'),
  zip_code: yup.string().label('CEP'),
  address: yup.string().label('Endereço'),
  phone: yup.string().label('Telefone'),
  photo: yup.mixed().isFile().label('Foto'),
  permissions: yup.array().of(yup.boolean()),
});

export const UserUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().email().label('Nome'),
  login: yup.string().required().label('Login'),
  password: yup.string().label('Senha'),
  password_confirmation: yup
    .string()
    .equalTo(yup.ref('password'), 'senha')
    .label('Confirmação'),
  change_password: yup.boolean().label('Mudar Senha'),
  role_id: yup.number().nullable().label('Função'),
  is_admin: yup.bool().nullable().label('Administrador'),
  departments: yup.array().of(yup.number()).label('Departamentos'),
  document: yup.string().cpfCnpj().label('CPF / CNPJ'),
  responsible: yup.string().label('Responsável'),
  company_name: yup.string().label('Razão Social'),
  state_registration: yup.string().label('Inscrição Estadual'),
  zip_code: yup.string().label('CEP'),
  address: yup.string().label('Endereço'),
  phone: yup.string().label('Telefone'),
  photo: yup.mixed().isFile().label('Foto'),
  permissions: yup.array().of(yup.boolean()),
});
