import { TableProps } from 'src/components/Table/interfaces';
import { IResponseAPIPaginationMeta } from 'src/services/api/interfaces';

export interface IUsePaginationReturn<T> {
  handlePageChange: TableProps['onPageChange'];
  handlePageSizeChange: TableProps['onPageSizeChange'];
  loading: boolean;
  info: IResponseAPIPaginationMeta;
  data: T[];
  loadData: () => void;
}

export const INITIAL_INFO: IResponseAPIPaginationMeta = {
  current_page: 1,
  first_page: 1,
  last_page: 1,
  per_page: 10,
  total: 0,
};
