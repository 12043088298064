import ChatContent from './ChatContent';
import ChatList from './ChatList';
import ContextProvider from './ContextProvider';
import { Container } from './styles';

const Omnichannel = {
  ContextProvider,
  Container,
  ChatList,
  ChatContent,
};

export default Omnichannel;
