import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

import { UseQueryResult } from '@tanstack/react-query';
import { TableProps } from 'src/components/Table/interfaces';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
} from 'src/services/api/interfaces';
import { objectToQuery, queryToObject } from 'src/utils/helpers';

import { INITIAL_INFO, IUseApiQueryPaginationReturn } from './interfaces';

type UseApiQueryPaginationEndpoint<T> = (
  data?: IApiUseQueryData<T>,
) => UseQueryResult<IApiUseQueryFnData<T>>;

function useApiQueryPagination<T>(
  query: UseApiQueryPaginationEndpoint<T>,
  defaultParams?: Record<string, any>,
): IUseApiQueryPaginationReturn<T> {
  const history = useHistory();
  const location = useLocation();

  const getRequestParams = useCallback(() => {
    try {
      const query = new URLSearchParams(location.search);

      const params = queryToObject(query);

      if (!params.page) {
        params.page = String(INITIAL_INFO.first_page);
      }
      if (!params.per_page) {
        params.per_page = String(INITIAL_INFO.per_page);
      }

      return { ...defaultParams, ...params };
    } catch (error) {
      return {};
    }
  }, [location.search, defaultParams]);

  const handlePageChange: NonNullable<TableProps['onPageChange']> = (page) => {
    const query = new URLSearchParams(location.search);

    const params = queryToObject(query);
    params.page = String(page + 1);

    history.replace({
      pathname: location.pathname,
      search: objectToQuery(params, { addQueryPrefix: false }),
    });
  };

  const handlePageSizeChange: NonNullable<TableProps['onPageSizeChange']> = (
    pageSize,
  ) => {
    const query = new URLSearchParams(location.search);

    const params = queryToObject(query);
    params.per_page = String(pageSize);

    history.replace({
      pathname: location.pathname,
      search: objectToQuery(params, { addQueryPrefix: false }),
    });
  };

  const queryResult = query({
    requestConfig: {
      params: getRequestParams(),
      paramsSerializer(params) {
        return objectToQuery(params, { addQueryPrefix: false });
      },
    },
  });

  return {
    handlePageChange,
    handlePageSizeChange,
    ...queryResult,
  };
}

export default useApiQueryPagination;
