import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import BackButton from 'src/components/BackButton';
import CheckboxPermissions from 'src/components/Form/_Common/CheckboxPermissions';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { BASE_PERMISSIONS } from 'src/constants/permissions/base';
import { ISaveRole } from 'src/interfaces/forms/ISaveRole';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IRole } from 'src/interfaces/models';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  RoleStoreSchema,
  RoleUpdateSchema,
} from 'src/validators/Role/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } =
    useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [roleStatus, setRoleStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const roleSchema = params.id ? RoleUpdateSchema : RoleStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const roleResponse = await api.admin.roles.getRole(params.id);
        const { permissions, ...role } = roleResponse.data;
        const rolePermissions: boolean[] = [];
        permissions?.forEach((perm) => {
          rolePermissions[perm.id] = true;
        });

        setRoleStatus(role.status);

        setTimeout(() => {
          formRef.current?.setData({
            ...role,
            permissions: rolePermissions,
          });
        });
      }
    } catch (error) {
      handleErrors(error, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<ISaveRole> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(roleSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const permissions: number[] = [];
      formData.permissions.forEach((selected, index) => {
        if (selected) permissions.push(index);
      });

      let newRole: IRole;
      if (params.id) {
        const response = await api.admin.roles.updateRole(params.id, {
          ...data,
          permissions,
        });
        newRole = response.data;
      } else {
        const response = await api.admin.roles.storeRole({
          ...data,
          permissions,
        });
        newRole = response.data;
      }

      toast.success('Função salva com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/funcoes/${newRole.id}`);
      }
    } catch (error) {
      handleErrors(error, 'Erro', roleSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await api.admin.roles.destroyRole(params.id);

      toast.success('Função desativada!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao desativar Função');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await api.admin.roles.restoreRole(params.id);

      toast.success('Função restaurada!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao atualizar função.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Container maxWidth="xl">
      <Form
        ref={formRef}
        permissions={{
          store: [BASE_PERMISSIONS.ROLES.STORE],
          update: [BASE_PERMISSIONS.ROLES.UPDATE],
          destroy: [BASE_PERMISSIONS.ROLES.DESTROY],
          restore: [BASE_PERMISSIONS.ROLES.RESTORE],
        }}
        resourceName="Função"
        onSave={handleSave}
        onDestroy={handleDestroy}
        onRestore={handleRestore}
        onCancel={handleCancel}
        loading={loading || layoutLoading}
        isUpdating={!!params.id}
        isActive={roleStatus === STATUSES.ACTIVE}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Nova'} Função
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField name="name" label="Nome" required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="group" label="Grupo" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Descrição"
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <CheckboxPermissions
              title="Permissões da Função"
              description={
                <>
                  Atenção, os <strong>usuários vinculados</strong> a esta função
                  irão ter essas permissões, além das{' '}
                  <strong>permissiões personalizadas</strong> definidas em seus
                  cadastros.
                </>
              }
              formRef={formRef}
            />
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default Save;
