import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import ForwardIcon from '@mui/icons-material/Forward';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import AutocompleteFunnelAttendants from 'src/modules/attendance/components/Form/_common/AutocompleteFunnelAttendants';
import attendanceApi from 'src/modules/attendance/services/api';
import { OpportunityTransferSchema } from 'src/modules/attendance/validators/Opportunity/save.schema';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

export type TransferOpportunitiesAttendantDialogProps = {
  funnelId: number;
  opportuntyIds: (string | number)[];
  onClose: () => void;
  onSuccessTransfer?: () => void;
} & DialogProps;

const TransferOpportunitiesAttendantDialog: React.FC<
  TransferOpportunitiesAttendantDialogProps
> = ({ funnelId, opportuntyIds, onSuccessTransfer, onClose, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const transferFormRef = useRef<FormHandles>(null);

  function handleClose() {
    if (loading) return;

    onClose();
  }

  async function handleSubmitTransfer(formData) {
    try {
      setLoading(true);

      formData.opportunities_ids = opportuntyIds;

      const { success, data, errors } = await yupValidate(
        OpportunityTransferSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, transferFormRef);
      }

      await attendanceApi.admin.funnels.opportunities.transferFunnelOpportunitiesAttendant(
        funnelId,
        data,
      );

      if (onSuccessTransfer) onSuccessTransfer();
      toast.success('Oportunidades transferidas!');
      handleClose();
    } catch (error) {
      handleErrors(
        error,
        'Erro ao transferir oportunidades.',
        OpportunityTransferSchema,
        transferFormRef,
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} {...rest}>
      <DialogTitle align="center">
        Transferir {opportuntyIds.length} Oportunidade(s)
      </DialogTitle>

      <DialogContent>
        <Form ref={transferFormRef} onSubmit={handleSubmitTransfer}>
          <Grid container spacing={1} padding={1}>
            <Grid item xs={12}>
              <AutocompleteFunnelAttendants
                name="attendant_id"
                funnelId={funnelId}
                requestConfig={{
                  params: { user_has_access: true },
                }}
                label="Atendante Responsável"
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} textAlign="right">
              <Button
                type="submit"
                endIcon={<ForwardIcon />}
                color="success"
                disabled={loading}
                variant="contained"
              >
                Transferir
              </Button>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default TransferOpportunitiesAttendantDialog;
