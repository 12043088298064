import yup from 'src/libs/yup';
import {
  dateOperatorSchema,
  numberOperatorSchema,
} from 'src/validators/DefaultFilter.schema';

const GoalFilterSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  status: yup.array().of(yup.string()).nullable().label('Status'),
  start_date: dateOperatorSchema,
  end_date: dateOperatorSchema,
  global_amount: numberOperatorSchema,
  individual_amount: numberOperatorSchema,
  funnels: yup.array().of(yup.number()).nullable().label('Funis'),
  users: yup.array().of(yup.number()).nullable().label('Usuários'),
});

export default GoalFilterSchema;
