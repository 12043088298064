import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import BackButton from 'src/components/BackButton';
import AutocompleteUsers from 'src/components/Form/_Common/AutocompleteUsers';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { SaveGoalUserParams } from 'src/modules/attendance/@types/params';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';
import attendanceApi from 'src/modules/attendance/services/api';
import {
  GoalUserStoreSchema,
  GoalUserUpdateSchema,
} from 'src/modules/attendance/validators/Goal/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } =
    useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useParams<SaveGoalUserParams>();
  const { url } = useRouteMatch();

  const schema = params.userId ? GoalUserUpdateSchema : GoalUserStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.userId) {
        const { data: user } =
          await attendanceApi.admin.goals.users.getGoalUser(
            params.id,
            params.userId,
          );

        setTimeout(() => {
          formRef.current?.setData({
            user_id: user.id,
            amount: user.amount,
          });
        });
      }
    } catch (error) {
      handleErrors(error, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, params.userId, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave = async ({ formData, shouldGoBack }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(schema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.userId) {
        await attendanceApi.admin.goals.users.updateGoalUser(
          params.id,
          params.userId,
          data,
        );
      } else {
        await attendanceApi.admin.goals.users.storeGoalUser(params.id, data);
      }

      toast.success('Meta Individual salva com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.userId) {
        history.replace(`${url}/${data.user_id}`);
      }
    } catch (error) {
      handleErrors(error, 'Erro', schema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await attendanceApi.admin.goals.users.destroyGoalUser(
        params.id,
        params.userId,
      );

      toast.success('Meta Individual excluida!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao excluir Meta Individual');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: [ATTENDANCE_PERMISSIONS.GOALS.UPDATE],
        update: [ATTENDANCE_PERMISSIONS.GOALS.UPDATE],
        destroy: [ATTENDANCE_PERMISSIONS.GOALS.UPDATE],
      }}
      resourceName="Usuário"
      onSave={handleSave}
      onDestroy={handleDestroy}
      // onRestore={handleRestore}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.userId}
      // isActive={stepStatus === STATUSES.ACTIVE}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.userId ? 'Editar' : 'Novo'} Meta Individual
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <AutocompleteUsers
                    name="user_id"
                    label="Usuário"
                    textFieldProps={{ required: true }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    name="amount"
                    label="Valor"
                    mask="currency"
                    returnUnmasked
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Save;
