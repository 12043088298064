import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { Reason } from 'src/modules/attendance/@types/models';
import {
  ATTENDANCE_ADMIN_QUERY_KEYS,
  ATTENDANCE_COMMON_QUERY_KEYS,
} from 'src/modules/attendance/constants/query';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getReasons(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Reason>>(
    '/attendance/admin/reasons',
    config,
  );

  return response;
}

export function useReasonsQuery(data?: IApiUseQueryData<Reason>) {
  return useQuery<IApiUseQueryFnData<Reason>>(
    [ATTENDANCE_ADMIN_QUERY_KEYS.REASONS, data?.requestConfig?.params],
    () => getReasons(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateReasonsQueries() {
  queryClient.invalidateQueries([ATTENDANCE_COMMON_QUERY_KEYS.REASONS]);
  queryClient.invalidateQueries([ATTENDANCE_ADMIN_QUERY_KEYS.REASONS]);
}

export async function storeReason(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Reason>(
    '/attendance/admin/reasons',
    data,
    config,
  );

  invalidateReasonsQueries();

  return response;
}

export async function getReason(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Reason>(
    `/attendance/admin/reasons/${id}`,
    config,
  );

  return response;
}

export async function updateReason(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<Reason>(
    `/attendance/admin/reasons/${id}`,
    data,
    config,
  );

  invalidateReasonsQueries();

  return response;
}

export async function destroyReason(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/attendance/admin/reasons/${id}`,
    config,
  );

  invalidateReasonsQueries();

  return response;
}

export async function restoreReason(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/attendance/admin/reasons/${id}/restore`,
    config,
  );

  invalidateReasonsQueries();

  return response;
}
