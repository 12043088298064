import { FC } from 'react';
import { useTheme } from 'styled-components';

import MuiLink, { LinkProps } from '@mui/material/Link';

const LinkExternal: FC<LinkProps> = ({ children, ...rest }) => {
  const theme = useTheme();

  return (
    <MuiLink color={theme.palette.text.primary} target="_blank" {...rest}>
      {children}
    </MuiLink>
  );
};

export default LinkExternal;
