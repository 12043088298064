type InputValue = string | number | null;

function getLocaleCode() {
  // const {
  //   currentLang: {
  //     numberFormat: { code, currency },
  //   },
  // } = getLocales();

  return {
    code: 'pt-BR',
    currency: 'BRL',
  };
}

// ----------------------------------------------------------------------

export function fNumber(inputValue: InputValue) {
  const { code } = getLocaleCode();

  if (!inputValue && inputValue !== 0) return '';

  const number = Number(inputValue);

  const fm = new Intl.NumberFormat(code, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);

  return fm;
}

export function fNumberInt(inputValue: InputValue) {
  let number = Number(inputValue);

  number = Number.isNaN(number) ? 0 : number / 100;

  return fNumber(number);
}

// ----------------------------------------------------------------------

export function fCurrency(
  inputValue: InputValue,
  options?: Intl.NumberFormatOptions,
) {
  const { code, currency } = getLocaleCode();

  if (!inputValue && inputValue !== 0) return '';

  const number = Number(inputValue);

  const fm = new Intl.NumberFormat(code, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  }).format(number);

  return fm;
}

export function fCurrencyInt(
  inputValue: InputValue,
  options?: Intl.NumberFormatOptions,
) {
  let number = Number(inputValue);

  number = Number.isNaN(number) ? 0 : number / 100;

  return fCurrency(number, options);
}

// ----------------------------------------------------------------------

export function fPercent(inputValue: InputValue) {
  const { code } = getLocaleCode();

  if (!inputValue && inputValue !== 0) return '';

  const number = Number(inputValue) / 100;

  const fm = new Intl.NumberFormat(code, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(number);

  return fm;
}

export function fPercentInt(inputValue: InputValue) {
  let number = Number(inputValue);

  number = Number.isNaN(number) ? 0 : number / 100;

  return fPercent(number);
}

// ----------------------------------------------------------------------

export function fShortenNumber(inputValue: InputValue) {
  const { code } = getLocaleCode();

  if (!inputValue && inputValue !== 0) return '';

  const number = Number(inputValue);

  const fm = new Intl.NumberFormat(code, {
    notation: 'compact',
    maximumFractionDigits: 2,
  }).format(number);

  return fm.replace(/[A-Z]/g, (match) => match.toLowerCase());
}

export function fShortenNumberInt(inputValue: InputValue) {
  let number = Number(inputValue);

  number = Number.isNaN(number) ? 0 : number / 100;

  return fShortenNumber(number);
}

// ----------------------------------------------------------------------

export function fData(inputValue: InputValue) {
  if (!inputValue) return '';

  if (inputValue === 0) return '0 Bytes';

  const units = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  const decimal = 2;

  const baseValue = 1024;

  const number = Number(inputValue);

  const index = Math.floor(Math.log(number) / Math.log(baseValue));

  const fm = `${parseFloat((number / baseValue ** index).toFixed(decimal))} ${
    units[index]
  }`;

  return fm;
}
