import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { ZApiInstance } from 'src/modules/whatsapp/@types/models';
import {
  WHATSAPP_ADMIN_QUERY_KEYS,
  WHATSAPP_COMMON_QUERY_KEYS,
} from 'src/modules/whatsapp/constants/query';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getZApiInstances(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<ZApiInstance>>(
    '/whatsapp/admin/z-api-instances',
    config,
  );

  return response;
}

export function useZApiInstancesQuery(data?: IApiUseQueryData<ZApiInstance>) {
  return useQuery<IApiUseQueryFnData<ZApiInstance>>(
    [WHATSAPP_ADMIN_QUERY_KEYS.Z_API_INSTANCES, data?.requestConfig?.params],
    () => getZApiInstances(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateZApiInstancesQueries() {
  queryClient.invalidateQueries([WHATSAPP_COMMON_QUERY_KEYS.Z_API_INSTANCES]);
  queryClient.invalidateQueries([WHATSAPP_ADMIN_QUERY_KEYS.Z_API_INSTANCES]);
}

export async function storeZApiInstance(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<ZApiInstance>(
    '/whatsapp/admin/z-api-instances',
    data,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function getZApiInstance(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<ZApiInstance>(
    `/whatsapp/admin/z-api-instances/${id}`,
    config,
  );

  return response;
}

export async function updateZApiInstance(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<ZApiInstance>(
    `/whatsapp/admin/z-api-instances/${id}`,
    data,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function destroyZApiInstance(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/whatsapp/admin/z-api-instances/${id}`,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function restoreZApiInstance(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/whatsapp/admin/z-api-instances/${id}/restore`,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function checkZApiInstanceConnection(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get(
    `/whatsapp/admin/z-api-instances/${id}/check-connection`,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function disconnectZApiInstance(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get(
    `/whatsapp/admin/z-api-instances/${id}/disconnect`,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function updateZApiInstanceWebhooks(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get(
    `/whatsapp/admin/z-api-instances/${id}/update-webhooks`,
    config,
  );

  return response;
}
