import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import InputFile from 'src/components/Form/InputFile';
import TextField from 'src/components/Form/TextField';
import { useStoreSelector } from 'src/hooks/store';
import api from 'src/services/api';
import { AuthActions } from 'src/store/ducks/auth';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import profileSchema from 'src/validators/User/profile.schema';

import { Actions, Container } from './styles';

const Profile: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const user = useStoreSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        profileSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const response = await api.admin.profile.updateProfile(
        objectToFormData(data),
      );

      const user = response.data;
      const userPermissions = user.permissions || [];
      const rolePermissions = user.role?.permissions?.filter(
        (rolePerm) =>
          !user.permissions?.find((userPerm) => userPerm.id === rolePerm.id),
      );

      userPermissions.concat(rolePermissions || []);

      dispatch(AuthActions.authProfile(user, userPermissions));

      toast('Perfil atualizado com sucesso', { type: 'success' });

      history.push('/');
    } catch (error) {
      handleErrors(error, 'Erro', profileSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Card>
        <CardHeader title="Perfil" />

        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            photo: user?.photo,
            name: user?.name,
            login: user?.login,
            email: user?.email,
            role_name: user?.is_admin ? 'Administrador' : user?.role?.name,
          }}
        >
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <InputFile name="photo" label="Foto" />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="name" label="Nome" autoFocus />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="login" label="Login" disabled />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField name="email" label="E-mail" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="role_name" label="Função" disabled />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Preencha os campos abaixo <strong>somente</strong> se
                      deseja alterar sua senha.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name="password"
                      type="password"
                      label="Nova Senha"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name="password_confirmation"
                      type="password"
                      label="Confirmar Nova Senha"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>

          <Actions>
            <Button
              type="submit"
              loading={loading}
              variant="contained"
              color="primary"
              onClick={() => formRef.current?.submitForm()}
              startIcon={<CheckIcon />}
            >
              Salvar
            </Button>
          </Actions>
        </Form>
      </Card>
    </Container>
  );
};

export default Profile;
