import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ZApiInstance } from 'src/modules/whatsapp/@types/models';
import { WHATSAPP_COMMON_QUERY_KEYS } from 'src/modules/whatsapp/constants/query';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

/** REASONS */
export async function getZApiInstances(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<ZApiInstance>>(
    '/whatsapp/admin/common/z-api-instances',
    config,
  );

  return response;
}

export function useZApiInstancesQuery(data?: IApiUseQueryData<ZApiInstance>) {
  return useQuery<IApiUseQueryFnData<ZApiInstance>>(
    [WHATSAPP_COMMON_QUERY_KEYS.Z_API_INSTANCES, data?.requestConfig?.params],
    () => getZApiInstances(data?.requestConfig),
    data?.queryOptions,
  );
}

export async function getZApiInstance(
  id: number | string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ZApiInstance>> {
  const response = await apiAxios.get<ZApiInstance>(
    `/whatsapp/admin/common/z-api-instances/${id}`,
    config,
  );

  return response;
}
