import React from 'react';

import { useTheme } from '@mui/material';
import Apexchart from 'src/components/Apexchart';
import { Dashboard } from 'src/modules/attendance/@types/dashboard';

type OpportunitiesStatusChartProps = {
  data?: Dashboard['opportunitiesStatus'];
};

const OpportunitiesStatusChart: React.FC<OpportunitiesStatusChartProps> = ({
  data,
}) => {
  const theme = useTheme();

  return (
    <Apexchart
      type="pie"
      options={{
        title: {
          text: '(%) Aproveitamento das Oportunidades',
          align: 'center',
        },
        chart: { id: 'opportunties-status' },
        colors: [
          theme.palette.info.light,
          theme.palette.success.light,
          theme.palette.error.light,
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
          },
        },
        labels: data?.labels || [],
        dataLabels: {
          enabled: true,
          formatter(value) {
            return `${Number(value).toFixed(2)}%`;
          },
        },
      }}
      series={data?.series || []}
    />
  );
};

export default OpportunitiesStatusChart;
