import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { ADMIN_QUERY_KEYS, COMMON_QUERY_KEYS } from 'src/constants/query';
import { IUser } from 'src/interfaces/models';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getUsers(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<IUser>>(
    '/admin/users',
    config,
  );

  return response;
}

export function useUsersQuery(data?: IApiUseQueryData<IUser>) {
  return useQuery<IApiUseQueryFnData<IUser>>(
    [ADMIN_QUERY_KEYS.USERS, data?.requestConfig?.params],
    () => getUsers(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateUsersQueries() {
  queryClient.invalidateQueries([COMMON_QUERY_KEYS.USERS]);
  queryClient.invalidateQueries([ADMIN_QUERY_KEYS.USERS]);
}

export async function storeUser(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<IUser>('/admin/users', data, config);

  invalidateUsersQueries();

  return response;
}

export async function getUser(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IUser>(`/admin/users/${id}`, config);

  return response;
}

export async function updateUser(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<IUser>(
    `/admin/users/${id}`,
    data,
    config,
  );

  invalidateUsersQueries();

  return response;
}

export async function destroyUser(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(`/admin/users/${id}`, config);

  invalidateUsersQueries();

  return response;
}

export async function restoreUser(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/admin/users/${id}/restore`,
    config,
  );

  invalidateUsersQueries();

  return response;
}
