import React, { useRef, useState } from 'react';

import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/PersonSearch';
import {
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { ButtonProps } from 'src/components/Button/interfaces';

type ActionsButtonProps = ButtonProps & {
  onTransferClick: () => void;
};

const ActionsButton: React.FC<ActionsButtonProps> = ({
  onTransferClick,
  ...props
}) => {
  const userMenuRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  function handleTransferClick() {
    setIsOpen(false);
    onTransferClick();
  }

  return (
    <>
      <Button
        {...props}
        ref={userMenuRef}
        startIcon={<ArrowDownIcon />}
        onClick={() => setIsOpen(!isOpen)}
        variant="outlined"
      >
        Ações em Massa
      </Button>

      <Popper
        open={isOpen}
        anchorEl={userMenuRef.current}
        transition
        disablePortal
        style={{ zIndex: 999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <MenuList>
                  <MenuItem onClick={handleTransferClick}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mudar Atendente" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ActionsButton;
