import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import BackButton from 'src/components/BackButton';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import InputPhoneNumber from 'src/components/Form/InputPhoneNumber';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { BASE_PERMISSIONS } from 'src/constants/permissions/base';
import { IEditParams } from 'src/interfaces/IEditParams';
import { Lead } from 'src/interfaces/models';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  LeadStoreSchema,
  LeadUpdateSchema,
} from 'src/validators/Lead/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } =
    useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [leadStatus, setLeadStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const leadSchema = params.id ? LeadUpdateSchema : LeadStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const { data: lead } = await api.admin.leads.getLead(params.id);
        setLeadStatus(lead.status);

        setTimeout(() => {
          formRef.current?.setData(lead);
        });
      }
    } catch (error) {
      handleErrors(error, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<Lead> = async ({ formData, shouldGoBack }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(leadSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newLead: Lead;
      if (params.id) {
        const response = await api.admin.leads.updateLead(params.id, data);
        newLead = response.data;
      } else {
        const response = await api.admin.leads.storeLead(data);
        newLead = response.data;
      }

      toast.success('Lead salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/leads/${newLead.id}`);
      }
    } catch (error) {
      handleErrors(error, 'Erro', leadSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await api.admin.leads.destroyLead(params.id);

      toast.success('Lead desativado!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao desativar Lead');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await api.admin.leads.restoreLead(params.id);

      toast.success('Lead restaurado!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao atualizar Lead.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    formRef.current?.setFieldValue('name', params.get('name'));
  }, [history.location.search]);

  return (
    <Container maxWidth="xl">
      <Form
        ref={formRef}
        permissions={{
          store: [BASE_PERMISSIONS.LEADS.STORE],
          update: [BASE_PERMISSIONS.LEADS.UPDATE],
          destroy: [BASE_PERMISSIONS.LEADS.DESTROY],
          restore: [BASE_PERMISSIONS.LEADS.RESTORE],
        }}
        resourceName="Lead"
        onSave={handleSave}
        onDestroy={handleDestroy}
        onRestore={handleRestore}
        onCancel={handleCancel}
        loading={loading || layoutLoading}
        isUpdating={!!params.id}
        isActive={leadStatus === STATUSES.ACTIVE}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Novo'} Lead
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField name="name" label="Nome" required />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="email"
                      label="E-mail"
                      required
                      type="email"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputPhoneNumber name="phone" label="Telefone" required />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputPhoneNumber
                      name="whatsapp_number"
                      label="WhatsApp"
                      required
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default Save;
