export function formatToMoney(
  value: number,
  options?: Intl.NumberFormatOptions,
) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    ...options,
  });

  return formatter.format(value);
}

/**
 *  use this to format 1000 -> R$ 10,00
 */
export function formatIntToMoney(
  value: number,
  options?: Intl.NumberFormatOptions,
) {
  return formatToMoney(value / 100, options);
}
