import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import api from 'src/services/api';

type AutocompleteDepartmentsProps = {
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps, 'options'>;

const AutocompleteDepartments: React.FC<AutocompleteDepartmentsProps> = ({
  requestConfig,
  ...rest
}) => {
  const { isLoading, data: response } = api.admin.common.useDepartmentsQuery({
    requestConfig,
  });

  const options =
    response?.data?.map((user) => ({
      key: user.id,
      label: user.name,
      value: user.id,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteDepartments;
