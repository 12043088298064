import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Dashboard } from 'src/modules/attendance/@types/dashboard';
import { ATTENDANCE_ADMIN_QUERY_KEYS } from 'src/modules/attendance/constants/query';
import apiAxios from 'src/services/api/axios';
import { IUseQueryData } from 'src/services/api/interfaces';

export async function getDashboard(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<Dashboard>(
    '/attendance/admin/dashboard',
    config,
  );

  return response;
}

export function useDashboardQuery(
  data?: IUseQueryData<AxiosResponse<Dashboard>>,
) {
  return useQuery<AxiosResponse<Dashboard>>(
    [ATTENDANCE_ADMIN_QUERY_KEYS.DASHBOARD, data?.requestConfig?.params],
    () => getDashboard(data?.requestConfig),
    data?.queryOptions,
  );
}
