import React from 'react';
import { SystemMessage as RCESystemMessage } from 'react-chat-elements';

import { formatDistanceToNow } from 'date-fns';
import { MessageProps } from 'src/components/Omnichannel/ChatContent/Message';

import { OmnichannelMessageContainer } from './styles';

const SystemMessage: React.FC<MessageProps> = ({ message }) => {
  if (!message.system) return null;

  return (
    <OmnichannelMessageContainer direction={message.direction}>
      <div className="message-content">
        <RCESystemMessage
          id={message._id}
          notch
          removeButton={false}
          replyButton={false}
          retracted={false}
          status={'sent'}
          position={message.direction === 'outgoing' ? 'right' : 'left'}
          type="text"
          title={''}
          titleColor=""
          text={message.system.text || ''}
          date={new Date(message.sended_at)}
          dateString={formatDistanceToNow(new Date(message.sended_at), {
            addSuffix: true,
            includeSeconds: true,
          })}
          focus={false}
          forwarded={false}
        />
      </div>
    </OmnichannelMessageContainer>
  );
};

export default SystemMessage;
