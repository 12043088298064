import styled, { css } from 'styled-components';

import MuiAppBar from '@mui/material/AppBar';
import MuiIconButton from '@mui/material/IconButton';
import MuiToolbar from '@mui/material/Toolbar';
import MuiTypography from '@mui/material/Typography';
import { SIDE_NAVIGATION_WIDTH } from 'src/routes/Private/Layout/styles';

export const AppBar = styled(MuiAppBar)<{ isOpen?: boolean }>`
  /* z-index: ${({ theme }) => theme.zIndex.drawer + 1}; */
  transition: ${({ theme }) =>
    theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      margin-left: ${SIDE_NAVIGATION_WIDTH}px;
      width: calc(100% - ${SIDE_NAVIGATION_WIDTH}px);
      transition: ${theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })};
    `}
`;

export const Toolbar = styled(MuiToolbar)`
  padding-right: 24px;
`;

export const MenuButton = styled(MuiIconButton)<{ isOpen?: boolean }>`
  margin-right: 36px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: none;
    `}
`;

export const Title = styled(MuiTypography).attrs(() => ({
  component: 'h1',
}))`
  flex-grow: 1;
`;
