import yup from 'src/libs/yup';

export const LeadStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().required().label('E-mail'),
  phone: yup.string().required().label('Telefone'),
  whatsapp_number: yup.string().label('WhatsApp'),
});

export const LeadUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().required().label('E-mail'),
  phone: yup.string().required().label('Telefone'),
  whatsapp_number: yup.string().label('WhatsApp'),
});
