import React, { useContext, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import ThreePIcon from '@mui/icons-material/ThreeP';
import { Menu, MenuItem, Typography } from '@mui/material';
import IconButton from 'src/components/IconButton';
import ChatAttendantsDialog from 'src/components/Omnichannel/ChatAttendantsDialog';
import CloseChatDialog from 'src/components/Omnichannel/CloseChatDialog';
import { OmnichannelContext } from 'src/components/Omnichannel/ContextProvider/types';

const ChatMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [openAttendantsDialog, setOpenAttendantsDialog] = useState(false);
  // const [openOpportunitiesDialog, setOpenOpportunitiesDialog] = useState(false);
  const [showCloseChatDialog, setShowCloseChatDialog] = useState(false);
  const { selectedChat } = useContext(OmnichannelContext);

  const isChatActive = selectedChat?.status === 'active';

  function handleClose() {
    setAnchorEl(undefined);
  }

  return (
    <>
      <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
      >
        {/* <MenuItem onClick={() => setOpenOpportunitiesDialog(true)}>
          <ThreePIcon fontSize="inherit" />
          <Typography variant="body2" marginLeft={1}>
            Oportunidades
          </Typography>
        </MenuItem> */}

        <MenuItem onClick={() => setOpenAttendantsDialog(true)}>
          <GroupIcon fontSize="inherit" />
          <Typography variant="body2" marginLeft={1}>
            Atendentes
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => setShowCloseChatDialog(true)}
          disabled={!isChatActive}
        >
          <CloseIcon fontSize="inherit" color="error" />
          <Typography variant="body2" color="error" marginLeft={1}>
            Encerrar Chat
          </Typography>
        </MenuItem>
      </Menu>

      <ChatAttendantsDialog
        open={openAttendantsDialog}
        onClose={() => setOpenAttendantsDialog(false)}
      />

      {/* {selectedChat && (
        <ChatClientOpportunitiesDialog
          chat={selectedChat}
          open={openOpportunitiesDialog}
          onClose={() => setOpenOpportunitiesDialog(false)}
        />
      )} */}

      <CloseChatDialog
        open={showCloseChatDialog}
        onClose={() => setShowCloseChatDialog(false)}
      />
    </>
  );
};

export default ChatMenu;
