import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import TextField from 'src/components/Form/TextField';

import CustomToolbar from './CustomToolbar';
import { CustomToolbarProps } from './CustomToolbar/interfaces';
import { TableProps } from './interfaces';

const Table: FC<TableProps> = ({
  columns,
  showFilters,
  canSearch,
  filterProps,
  ...rest
}) => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const searchFormRef = useRef<FormHandles>(null);
  const [currentSortModel, setCurrentSortModel] = useState<GridSortModel>([]);
  const [hasSearch, setHasSearch] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const onFilterClick = useCallback(() => {
    filterRef.current?.open();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const column = query.get('order_by[0][column]');
    const sort = query.get('order_by[0][direction]') === 'asc' ? 'asc' : 'desc';

    if (column) {
      setCurrentSortModel([
        {
          field: column,
          sort: sort,
        },
      ]);
    } else {
      setCurrentSortModel([]);
    }

    setHasSearch(query.has('search'));

    const search = query.get('search');
    if (search) {
      searchFormRef.current?.setFieldValue('search', search);
    }
  }, [location.search]);

  const handleSearchSubmit = useCallback(
    (formData) => {
      const query = new URLSearchParams(location.search);

      if (formData.search) {
        query.set('search', formData.search);
      } else {
        query.delete('search');
      }

      history.replace({
        pathname: location.pathname,
        search: query.toString(),
      });
    },
    [location, history],
  );

  const handleSortChange = useCallback<
    NonNullable<TableProps['onSortModelChange']>
  >(
    (sortModel) => {
      const query = new URLSearchParams(location.search);

      if (sortModel.length) {
        if (currentSortModel[0] === sortModel[0]) return;

        query.set('order_by[0][column]', sortModel[0].field);
        query.set('order_by[0][direction]', sortModel[0].sort || 'asc');
      } else {
        query.delete('order_by[0][column]');
        query.delete('order_by[0][direction]');
      }

      history.replace({
        pathname: location.pathname,
        search: query.toString(),
      });
    },
    [location, currentSortModel, history],
  );

  return (
    <>
      {showFilters && (
        <FilterDrawer
          columns={columns.filter((column) => !column.hide && column.field)}
          {...filterProps}
          ref={filterRef}
        />
      )}

      {canSearch && (
        <Form
          ref={searchFormRef}
          onSubmit={handleSearchSubmit}
          style={{ marginBottom: 4 }}
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="search"
                label="Buscar..."
                size="small"
                autoComplete="off"
                InputProps={{
                  startAdornment: hasSearch ? (
                    <InputAdornment position="start">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => {
                          searchFormRef.current?.setFieldValue('search', '');
                          searchFormRef.current?.submitForm();
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        color="default"
                        onClick={searchFormRef.current?.submitForm}
                      >
                        <SearchIcon fontSize="inherit" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Form>
      )}

      <DataGrid
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: { showFilters, onFilterClick } as CustomToolbarProps,
        }}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        columns={columns.map((column) => ({
          sortable: false,
          ...column,
        }))}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        sortingMode="server"
        sortModel={currentSortModel}
        onSortModelChange={handleSortChange}
        {...rest}
      />
    </>
  );
};

export default Table;
