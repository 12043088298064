import { AxiosRequestConfig } from 'axios';
import { IUser } from 'src/interfaces/models';
import apiAxios from 'src/services/api/axios';

type ILoginResponse = {
  authentication: {
    token: string;
    type: string;
  };
  user: IUser;
};

export async function login(data: object, config?: AxiosRequestConfig) {
  const response = await apiAxios.post<ILoginResponse>(
    '/admin/login',
    data,
    config,
  );

  return response;
}

export async function logout(config?: AxiosRequestConfig) {
  const response = await apiAxios.delete<boolean>('/admin/me', config);

  return response;
}

export async function forgotPassword(
  data: object,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<{ email: string }>(
    '/admin/forgot-password',
    data,
    config,
  );

  return response;
}

export async function resetPassword(data: object, config?: AxiosRequestConfig) {
  const response = await apiAxios.post<boolean>(
    '/admin/reset-password',
    data,
    config,
  );

  return response;
}
