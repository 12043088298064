import * as auth from './auth';
import * as common from './common';
import * as departments from './departments';
import * as leads from './leads';
import * as profile from './profile';
import * as roles from './roles';
import * as users from './users';

export default {
  /** common - Functions that don't need permissions */
  common,
  auth,
  users,
  roles,
  profile,
  departments,
  leads,
};
