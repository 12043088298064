import styled from 'styled-components';

import { Grid, Typography } from '@mui/material';
import MuiContainer from '@mui/material/Container';
import MuiPaper from '@mui/material/Paper';

export const Container = styled(MuiContainer)`
  padding: 0;
  height: calc(100vh - 64px);
  display: grid;
  grid-template-rows: auto 1fr;
`;

export const Header = styled(MuiPaper)`
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const HeaderText = styled(Typography)`
  font-size: 14px;
`;

export const BodyContainer = styled(Grid)``;

export const BodyItem = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(2, 1)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
`;
