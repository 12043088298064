import styled from 'styled-components';

import MuiAvatar from '@mui/material/Avatar';
import { Form as Unform } from '@unform/web';
import Button from 'src/components/Button';

export const Avatar = styled(MuiAvatar)`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Form = styled(Unform)`
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};
`;
