import { FC } from 'react';

import Grid from '@mui/material/Grid';
import Autocomplete from 'src/components/Form/Autocomplete';
import CurrencyOperator from 'src/components/Form/CurrencyOperator';
import DatePickerOperator from 'src/components/Form/DatePickerOperator';
import TextField from 'src/components/Form/TextField';

const statusItems = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
  { key: 3, label: 'Arquivado', value: 'archived' },
];

const GoalsFilters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <TextField name="name" label="Nome" size="small" />
      </Grid>

      <Grid item xs={12}>
        <DatePickerOperator name="start_date" label="Início" size="small" />
      </Grid>

      <Grid item xs={12}>
        <DatePickerOperator name="end_date" label="Fim" size="small" />
      </Grid>

      <Grid item xs={12}>
        <CurrencyOperator
          name="global_amount"
          label="Meta Global"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <CurrencyOperator
          name="individual_amount"
          label="Meta Individual"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="status"
          label="Status"
          options={statusItems}
          size="small"
          multiple
        />
      </Grid>
    </>
  );
};

export default GoalsFilters;
