import { FC, useCallback, useEffect, useRef, useState } from 'react';

import MuiFormControl from '@mui/material/FormControl';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiFormHelperText from '@mui/material/FormHelperText';
import MuiFormLabel from '@mui/material/FormLabel';
import MuiRadio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
import { useField } from '@unform/core';

import { InputRef, RadioGroupProps } from './interfaces';

const RadioGroup: FC<RadioGroupProps> = ({
  name,
  options,
  onChange,
  label,
  radioProps,
  controlLabelProps,
  disabled,
  ...rest
}) => {
  const radioGroupRef = useRef<InputRef>(null);

  const {
    fieldName,
    registerField,
    error,
    defaultValue = '',
    clearError,
  } = useField(name);

  const [selectedValue, setSelectedValue] = useState(defaultValue ?? '');

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: radioGroupRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (_, value) => {
        setSelectedValue(value ?? '');
        clearError();
      },
      clearValue: (_, newValue) => {
        setSelectedValue(newValue ?? '');
        clearError();
      },
    });
  }, [fieldName, registerField, clearError]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (radioGroupRef.current) {
      radioGroupRef.current.value = selectedValue;

      if (onChange) onChange(selectedValue);
    }
  }, [selectedValue, onChange]);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;

      clearError();

      setSelectedValue(value);
    },
    [clearError],
  );

  return (
    <MuiFormControl component="fieldset" error={!!error}>
      {label && <MuiFormLabel component="legend">{label}</MuiFormLabel>}
      <MuiRadioGroup
        ref={radioGroupRef}
        name={fieldName}
        value={selectedValue}
        onChange={handleChange}
        {...rest}
      >
        {options.map((option) => (
          <MuiFormControlLabel
            {...controlLabelProps}
            key={option.key}
            value={option.value}
            control={<MuiRadio color="primary" {...radioProps} />}
            label={option.label}
            disabled={disabled}
          />
        ))}
      </MuiRadioGroup>

      {error && <MuiFormHelperText>{error}</MuiFormHelperText>}
    </MuiFormControl>
  );
};

export default RadioGroup;
