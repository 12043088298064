import styled, { css } from 'styled-components';

type ContainerProps = { direction: string; hasReactions?: boolean };

export const OmnichannelMessageContainer = styled.div<ContainerProps>`
  display: flex;
  /* position: relative; */
  /* max-width: 80%; */

  transition: background-color 1s ease-out;

  &.selected {
    background-color: ${({ theme }) => theme.palette.action.selected};
  }

  .message-icon {
    /* align-self: center; */
    margin: 0 4px;
  }

  .message-content {
    flex-grow: 1;
    position: relative;

    ${({ hasReactions }) =>
      hasReactions &&
      css`
        margin-bottom: 15px;
      `}

    .rce-container-mbox {
      /* flex-grow: 1; */

      .rce-mbox {
        max-width: 80%;
        .rce-mbox-body {
          .rce-mbox-forward {
            background-color: ${({ theme }) => theme.palette.background.paper};
          }

          .rce-mbox-text {
            white-space: break-spaces;
          }

          .rce-mbox-photo {
            .rce-mbox-photo--img {
              img {
                cursor: pointer;
                object-fit: cover;
                max-height: 200px;
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    .reactions {
      position: absolute;
      bottom: -15px;

      > span {
        border: 1px solid;
        border-radius: 50%;
        padding: 2px 3px;
        margin: 0 1px;
      }

      ${({ direction }) =>
        direction === 'incoming'
          ? css`
              left: 25px;
              > span {
                background-color: ${({ theme }) => theme.palette.grey[200]};
                border-color: ${({ theme }) => theme.palette.grey[300]};
              }
            `
          : css`
              right: 25px;
              > span {
                background-color: ${({ theme }) => theme.palette.info.dark};
                border-color: ${({ theme }) => theme.palette.grey[700]};
              }
            `}
    }

    // system message
    .rce-container-smsg {
      .rce-smsg {
        .rce-smsg-text {
          font-size: inherit;
        }
      }
    }
  }

  ${({ direction }) => {
    if (!direction) return;
    return direction === 'incoming'
      ? css`
          .rce-mbox {
            background-color: ${({ theme }) => theme.palette.grey[200]};
          }
          .rce-mbox-left-notch {
            fill: ${({ theme }) => theme.palette.grey[200]};
          }
        `
      : css`
          /* justify-self: flex-end; */
          flex-direction: row-reverse;

          .rce-mbox {
            background-color: ${({ theme }) => theme.palette.info.dark};
          }
          .rce-mbox-right-notch {
            fill: ${({ theme }) => theme.palette.info.dark};
          }
          .rce-mbox-text,
          .rce-mbox-time {
            color: ${({ theme }) => theme.palette.info.contrastText};
          }
        `;
  }}
`;
