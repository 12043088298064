import React from 'react';
import Chart, { Props } from 'react-apexcharts';

import { useTheme } from '@mui/material';
import ptBR from 'apexcharts/dist/locales/pt-br.json';
import { useStoreSelector } from 'src/hooks/store';

const Apexchart: React.FC<Props> = (props) => {
  const { themeMode } = useStoreSelector((store) => store.app);
  const theme = useTheme();

  return (
    <Chart
      {...props}
      options={{
        ...props.options,
        theme: { ...props.options?.theme, mode: themeMode },
        chart: {
          background: 'transparent',
          ...props.options?.chart,
          locales: [ptBR],
          defaultLocale: 'pt-br',
          fontFamily: theme.typography.fontFamily,
        },
      }}
    />
  );
};

export default Apexchart;
