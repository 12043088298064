import yup from 'src/libs/yup';

const LeadFilterSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  email: yup.string().label('E-mail'),
  phone: yup.string().label('Telefone'),
  whatsapp_number: yup.string().label('WhatsApp'),
  status: yup.array().of(yup.string()).nullable().label('Status'),
});

export default LeadFilterSchema;
