import { FC, PropsWithChildren } from 'react';

export type ITabPanelProps = {
  show: boolean;
} & PropsWithChildren;

const TabPanel: FC<ITabPanelProps> = ({ show, children }) => {
  return (
    <div role="tabpanel" hidden={!show}>
      {show && children}
    </div>
  );
};

export default TabPanel;
