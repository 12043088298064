import { MessageBoxType } from 'react-chat-elements';

import { SvgIconComponent } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Lead } from 'src/interfaces/models';
import api from 'src/services/api';
import { Chat, ChatMessage } from 'src/services/websocket/omnichannel/types';
import { store } from 'src/store';

function getChatMessageIcon({
  channel,
}: ChatMessage): SvgIconComponent | undefined {
  if (channel === 'whatsapp') return WhatsAppIcon;
}

function getChatMessageText(message: ChatMessage): string {
  if (message?.channel === 'whatsapp') {
    const { whatsapp } = message;

    if (message.type === 'text') return whatsapp?.text?.message || '';
    if (message.type === 'image') return 'Imagem';
    if (message.type === 'document') return 'Documento';
    if (message.type === 'audio') return 'Áudio';
    if (message.type === 'video') return 'Vídeo';
  }

  if (message.channel === 'system') return message.system?.text || '';

  return '';
}

function getChatMessageStatus(message: ChatMessage): MessageBoxType['status'] {
  if (message.whatsapp) {
    switch (message.whatsapp.status) {
      case 'PENDING':
        return 'waiting';
      case 'PLAYED':
      case 'READ':
      case 'READ-SELF':
        return 'read';
      case 'RECEIVED':
        return 'received';
      default:
        return 'sent';
    }
  }

  return 'waiting';
}

function getChatMessageBoxType(message: ChatMessage): MessageBoxType['type'] {
  switch (message.type) {
    case 'text':
      return 'text';
    case 'document':
      return 'file';
    case 'audio':
      return 'audio';
    case 'image':
      return 'photo';
    default:
      return 'text';
  }
}

function scrollToChatMessage(id?: string) {
  if (!id) return;
  const messageElement = document.getElementById(`chat-message-${id}`);
  messageElement?.scrollIntoView({ behavior: 'smooth' });
  messageElement?.classList.add('selected');
  setTimeout(() => {
    messageElement?.classList.remove('selected');
  }, 1000);
}

function hasAccessToChat(chat: Chat) {
  const { user } = store.getState().auth;

  if (!user) return false;

  if (
    user.is_admin ||
    chat.attendant_id === user.id ||
    chat.attendants_ids?.includes(user.id)
  )
    return true;

  return false;
}

async function openLeadChat(data: { lead?: Lead; z_api_instance_id?: number }) {
  try {
    const modules = store.getState().app.modules;
    if (!modules?.WhatsApp) throw new Error('Módulo WhatsApp não ativado.');
    if (!data.lead) throw new Error('Lead não informado.');
    if (!data.z_api_instance_id) throw new Error('Instância não informada.');

    const response = await api.admin.leads.storeLeadChat(data.lead.id, {
      z_api_instance_id: data.z_api_instance_id,
    });

    return response.data;
  } catch (error) {
    if (!data.lead?.whatsapp_number) return;

    const phoneNumber = parsePhoneNumber(data.lead.whatsapp_number, 'BR');
    window.open(
      `https://wa.me/${phoneNumber.countryCallingCode}${phoneNumber.nationalNumber}`,
      '_blank',
    );
  }
}

const OminichannelUtils = {
  getChatMessageText,
  getChatMessageIcon,
  getChatMessageBoxType,
  getChatMessageStatus,
  scrollToChatMessage,
  hasAccessToChat,
  openLeadChat,
};

export default OminichannelUtils;
