import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import DateTimePicker from 'src/components/Form/DateTimePicker';
import TextField from 'src/components/Form/TextField';
import { Opportunity } from 'src/modules/attendance/@types/models';
import AutocompleteReasons from 'src/modules/attendance/components/Form/_common/AutocompleteReasons';
import { OPPORTUNITY_STATUS_LABEL } from 'src/modules/attendance/constants';
import { opportunityStatusThemeColor } from 'src/modules/attendance/helpers';
import attendanceApi from 'src/modules/attendance/services/api';
import { OpportunityStatusSchema } from 'src/modules/attendance/validators/Opportunity/save.schema';
import { handleErrors } from 'src/utils/errors';

export type ChangeStatusOpportunityDialogProps = {
  funnelId: number;
  opportunity: Opportunity;
  onClose: () => void;
  onSucess?: (opportunity: Opportunity) => void;
} & DialogProps;

const ChangeStatusOpportunityDialog: React.FC<
  ChangeStatusOpportunityDialogProps
> = ({ funnelId, opportunity, onSucess, onClose, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(opportunity.status);
  const formRef = useRef<FormHandles>(null);

  async function handleSubmit(formData) {
    try {
      setLoading(true);

      if (selectedStatus === 'open') {
        const { data } =
          await attendanceApi.admin.funnels.opportunities.openFunnelOpportunity(
            funnelId,
            opportunity.id,
            formData,
          );
        if (onSucess) onSucess(data);
      } else if (selectedStatus === 'gain') {
        const { data } =
          await attendanceApi.admin.funnels.opportunities.gainFunnelOpportunity(
            funnelId,
            opportunity.id,
            formData,
          );
        if (onSucess) onSucess(data);
      } else if (selectedStatus === 'lost') {
        const { data } =
          await attendanceApi.admin.funnels.opportunities.lostFunnelOpportunity(
            funnelId,
            opportunity.id,
            formData,
          );
        if (onSucess) onSucess(data);
      }

      toast.success('Status atualizado!');

      onClose();
    } catch (error) {
      handleErrors(
        error,
        'Erro ao alterar status da oportunidade.',
        OpportunityStatusSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" {...rest}>
      <DialogTitle align="center">Status da Oportunidade</DialogTitle>

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          wait_until: opportunity.wait_until,
          amount: opportunity.amount,
          reason_id: opportunity.reason_id,
          closed_at: opportunity.closed_at,
        }}
        noValidate
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="opportunity-status-label">
                  Alterar Status
                </FormLabel>
                <RadioGroup
                  aria-labelledby="opportunity-status-label"
                  value={selectedStatus}
                  onChange={(e) =>
                    setSelectedStatus(e.target.value as Opportunity['status'])
                  }
                  name="opportunity-statuses"
                  row
                >
                  {Object.entries(OPPORTUNITY_STATUS_LABEL)
                    .filter(([key]) => key !== 'transferred')
                    .map(([key, label]) => (
                      <FormControlLabel
                        key={key}
                        value={key}
                        control={
                          <Radio color={opportunityStatusThemeColor(key)} />
                        }
                        label={label}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            {selectedStatus === 'open' && (
              <Grid item xs={12}>
                <DateTimePicker
                  name="wait_until"
                  label="Em espera até"
                  disablePast
                />
              </Grid>
            )}

            {selectedStatus === 'gain' && (
              <>
                <Grid item xs={12}>
                  <TextField
                    name="amount"
                    label="Valor Ganho"
                    mask="currency"
                    returnUnmasked
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteReasons
                    name="reason_id"
                    label="Motivo do Ganho"
                    requestConfig={{
                      params: { funnel_id: funnelId, type: 'gain' },
                    }}
                    textFieldProps={{ required: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DateTimePicker
                    name="closed_at"
                    label="Data do Ganho"
                    returnAsDate
                    disableFuture
                    inputProps={{ required: true }}
                  />
                </Grid>
              </>
            )}

            {selectedStatus === 'lost' && (
              <>
                <Grid item xs={12}>
                  <AutocompleteReasons
                    name="reason_id"
                    label="Motivo da Perda"
                    requestConfig={{
                      params: { funnel_id: funnelId, type: 'lost' },
                    }}
                    textFieldProps={{ required: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DateTimePicker
                    name="closed_at"
                    label="Data da Perda"
                    returnAsDate
                    disableFuture
                    inputProps={{ required: true }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                startIcon={<CloseIcon />}
                color="error"
                onClick={onClose}
                disabled={loading}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                startIcon={<CheckIcon />}
                color="success"
                variant="contained"
                loading={loading}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ChangeStatusOpportunityDialog;
