import React from 'react';

import { useTheme } from '@mui/material';
import Apexchart from 'src/components/Apexchart';
import { formatIntToMoney } from 'src/helpers/number';
import { Dashboard } from 'src/modules/attendance/@types/dashboard';

type OpportunitiesAmountChartProps = {
  data?: Dashboard['opportunitiesStatus'];
};

const OpportunitiesAmountChart: React.FC<OpportunitiesAmountChartProps> = ({
  data,
}) => {
  const theme = useTheme();

  return (
    <Apexchart
      type="bar"
      options={{
        title: {
          text: '(R$) Aproveitamento das Oportunidades',
          align: 'center',
        },
        chart: { id: 'opportunities-amount' },
        colors: [
          theme.palette.info.light,
          theme.palette.success.light,
          theme.palette.error.light,
        ],
        xaxis: {
          categories: data?.labels || [''],
        },
        yaxis: {
          labels: {
            formatter(value) {
              return formatIntToMoney(Number(value));
            },
          },
        },
        dataLabels: {
          dropShadow: {
            enabled: true,
          },
          formatter(value) {
            return formatIntToMoney(Number(value));
          },
        },
        tooltip: {
          y: {
            formatter(value) {
              return formatIntToMoney(Number(value));
            },
          },
        },
      }}
      series={data?.series || []}
    />
  );
};

export default OpportunitiesAmountChart;
