import { FC, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles, SubmitHandler } from '@unform/core';
import TextField from 'src/components/Form/TextField';
import Link from 'src/components/Link';
import api from 'src/services/api';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import forgotMyPasswordSchema from 'src/validators/User/forgotMyPassword.schema';

import { Form, SubmitButton } from './styles';

const ForgotPassword: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        forgotMyPasswordSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      await api.admin.auth.forgotPassword(data);

      toast('Verifique seu email para recuperar sua senha.', {
        type: 'success',
      });

      history.push('/');
    } catch (error) {
      handleErrors(error, 'Erro', forgotMyPasswordSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleOnSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" align="center">
            Esqueci Minha Senha
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField name="email" label="E-mail" fullWidth variant="outlined" />
        </Grid>

        <Grid item xs={12}>
          <SubmitButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Recuperar Senha
          </SubmitButton>
        </Grid>

        <Grid item xs={12}>
          <Link to="/" variant="body2">
            Voltar ao Login
          </Link>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ForgotPassword;
