import { useEffect, useState } from 'react';

import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { useStoreSelector } from 'src/hooks/store';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';
import { WHATSAPP_PERMISSIONS } from 'src/modules/whatsapp/constants/permissions';
import { whatsappMenu } from 'src/modules/whatsapp/routes/menu';
import whatsappApi from 'src/modules/whatsapp/services/api';

export default function useWhatsAppMenu() {
  const [menuItems, setMenuItems] = useState<ISideMenuItem>();
  const modules = useStoreSelector((state) => state.app.modules);
  const user = useStoreSelector((state) => state.auth.user);
  const { data: response, refetch } =
    whatsappApi.admin.common.useZApiInstancesQuery({
      queryOptions: {
        enabled: false,
      },
    });

  useEffect(() => {
    if (modules?.WhatsApp) refetch();
  }, [modules?.WhatsApp, refetch]);

  useEffect(() => {
    if (!modules?.WhatsApp || !user) {
      setMenuItems(undefined);
      return;
    }

    const instancesSubItems: ISideMenuItem[] =
      response?.data.map((zApiInstance) => ({
        primaryText: zApiInstance.name,
        route: `/whatsapp/conversas/${zApiInstance.id}`,
        icon: LabelOutlinedIcon,
        permissions: [WHATSAPP_PERMISSIONS.OMNICHANNEL.LIST],
      })) || [];

    setMenuItems({
      ...whatsappMenu,
      subItems: instancesSubItems.concat(whatsappMenu.subItems || []),
    });
  }, [modules?.WhatsApp, user, response?.data]);

  return menuItems;
}
