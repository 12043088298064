import styled from 'styled-components';

import Grid from '@mui/material/Grid';
import MuiPaper from '@mui/material/Paper';

export const Container = styled(Grid)`
  height: 100vh;
`;

export const GridImage = styled(Grid)`
  background-image: url('https://source.unsplash.com/random/?work-from-anywhere');
  background-repeat: no-repeat;
  background-color: ${({ theme }) =>
    theme.palette.mode === 'light'
      ? theme.palette.grey[50]
      : theme.palette.grey[900]};
  background-size: cover;
  background-position: center;
`;

export const Paper = styled(MuiPaper)`
  padding: ${({ theme }) => theme.spacing(8, 4)};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
