import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { toast } from 'react-toastify';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import MicIcon from '@mui/icons-material/Mic';
import PhotoIcon from '@mui/icons-material/Photo';
import SendIcon from '@mui/icons-material/Send';
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormHandles, SubmitHandler } from '@unform/core';
import TextField from 'src/components/Form/TextField';
import { OmnichannelContext } from 'src/components/Omnichannel/ContextProvider/types';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import { ChatMessageTypes } from 'src/services/websocket/omnichannel/types';
import { handleErrors } from 'src/utils/errors';

import { Container } from './styles';

const WhatsApp: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const stopAudioRef = useRef<HTMLButtonElement>(null);
  const sendAudioRef = useRef(false);
  const { answerMessage, setAnswerMessage, selectedChat, setUpload } =
    useContext(OmnichannelContext);
  const [loading, setLoading] = useState(false);
  const {
    status: audioStatus,
    startRecording,
    stopRecording,
    clearBlobUrl,
  } = useReactMediaRecorder({
    audio: true,
    blobPropertyBag: { type: 'audio/ogg; codecs=opus' },
    onStop: handleSendAudio,
  });

  useEffect(() => {
    formRef.current?.setFieldValue('message', '');
    stopAudioRef.current?.click();
  }, [selectedChat]);

  const openSelectFiles = (type: ChatMessageTypes) => {
    let accept = '';
    switch (type) {
      case 'image':
        accept = 'image/gif,image/png,image/jpeg,image/jpg';
        break;
      case 'document':
        accept =
          'application/pdf,application/msword,application/vnd.ms-excel,video/*';
        break;
      default:
        toast.warn('Tipo de upload não implementado');
        return;
    }

    setUpload({
      type,
      accept,
      uploadFiles: [],
    });
  };

  const handleSendWhatsApp: SubmitHandler = async (formData) => {
    try {
      if (formData.message && selectedChat?._id && !loading) {
        setLoading(true);

        WSOmnichannel.socket?.emit(
          'send_message',
          {
            chat_id: selectedChat._id,
            message: formData.message,
            channel: 'whatsapp',
            type: 'text',
            replyMessageId: answerMessage?.whatsapp?.messageId,
          },
          (result) => {
            if (result.error) {
              toast.error(result.error.message);
            } else {
              formRef.current?.setFieldValue('message', '');
              setAnswerMessage(undefined);
            }

            setLoading(false);
            const fieldRef = formRef.current?.getFieldRef('message');
            fieldRef.current.focus();
          },
        );
      }
    } catch (error) {
      handleErrors(error, 'Erro ao enviar mensagem');
      setLoading(false);
    }
  };

  function startRecordAudio() {
    sendAudioRef.current = false;
    startRecording();
  }

  function sendRecordedAudio() {
    sendAudioRef.current = true;
    stopRecording();
  }

  function handleSendAudio(blobUrl: string, blob: Blob) {
    try {
      if (!sendAudioRef.current) throw new Error('Áudio Cancelado!');

      if (selectedChat?._id && !loading) {
        setLoading(true);
        const currentDate = new Date();
        const file = new File([blob], `audio_${currentDate.getTime()}`, {
          lastModified: currentDate.getTime(),
          type: blob.type,
        });

        WSOmnichannel.socket?.emit(
          'send_message',
          {
            chat_id: selectedChat._id,
            channel: 'whatsapp',
            type: 'audio',
            file,
            replyMessageId: answerMessage?.whatsapp?.messageId,
          },
          (result) => {
            if (result.error) {
              toast.error(result.error.message);
            } else {
              setAnswerMessage(undefined);
            }

            setLoading(false);
          },
        );
      }
    } catch (error) {
      clearBlobUrl();
      handleErrors(error, 'Erro ao enviar mensagem');
      setLoading(false);
    }
  }

  return (
    <Container ref={formRef} onSubmit={handleSendWhatsApp}>
      {audioStatus !== 'recording' ? (
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <TextField
              name="message"
              label="Mensagem via WhatsApp"
              disabled={loading}
              multiline
              maxRows={5}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {loading ? (
                      <IconButton
                        color="primary"
                        type="button"
                        disabled
                        size="large"
                      >
                        <CircularProgress color="primary" size={28} />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton color="primary" type="submit" size="large">
                          <SendIcon />
                        </IconButton>
                      </>
                    )}
                  </InputAdornment>
                ),
                onKeyPress: (e) => {
                  if (!e.shiftKey && e.key === 'Enter') {
                    e.preventDefault();
                    formRef.current?.submitForm();
                  }
                },
              }}
            />
          </Grid>

          <Grid item style={{ textAlign: 'center' }}>
            <Tooltip key="test" title="Gravar áudio">
              <IconButton
                color="primary"
                type="button"
                size="small"
                onClick={startRecordAudio}
                disabled={loading}
              >
                <MicIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Enviar Imagens">
              <IconButton
                color="primary"
                type="button"
                size="small"
                onClick={() => openSelectFiles('image')}
                disabled={loading}
              >
                <PhotoIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Enviar Arquivos (PDF, vídeo, word, excel)">
              <IconButton
                color="primary"
                type="button"
                size="small"
                onClick={() => openSelectFiles('document')}
                disabled={loading ?? false}
              >
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
          style={{ padding: 16 }}
        >
          <Grid item>
            <Typography color="GrayText">Gravando áudio...</Typography>
          </Grid>

          <Grid item>
            <Tooltip title="Cancelar áudio">
              <IconButton
                ref={stopAudioRef}
                color="error"
                type="button"
                size="small"
                onClick={stopRecording}
                disabled={loading}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title="Enviar áudio">
              <IconButton
                color="primary"
                type="button"
                size="small"
                onClick={sendRecordedAudio}
                disabled={loading}
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default WhatsApp;
