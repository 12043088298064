import { FC, useCallback, useState } from 'react';
import Helmet from 'react-helmet';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { useStoreDispatch, useStoreSelector } from 'src/hooks/store';
import { IRouteInterface } from 'src/interfaces/IRoutes';
import { AppActions } from 'src/store/ducks/app';
import { hasPermission } from 'src/utils/helpers';

import PrivateLayout from './Layout';
import PrivateContext from './PrivateContext';

const Private: FC<IRouteInterface> = ({
  component: Component,
  title,
  permissions,
  ...rest
}) => {
  const dispatch = useStoreDispatch();
  const { sideMenuIsOpen } = useStoreSelector((state) => state.app);

  const [layoutLoading, setLayoutLoading] = useState(false);

  function openSideMenu() {
    dispatch(AppActions.appToggleSideMenu(true));
  }
  function closeSideMenu() {
    dispatch(AppActions.appToggleSideMenu(false));
  }

  const startLayoutLoading = useCallback(() => {
    setLayoutLoading(true);
  }, []);
  const stopLayoutLoading = useCallback(() => {
    setLayoutLoading(false);
  }, []);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        hasPermission(permissions) ? (
          <>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            <PrivateContext.Provider
              value={{
                sideMenuIsOpen,
                openSideMenu,
                closeSideMenu,
                layoutLoading,
                startLayoutLoading,
                stopLayoutLoading,
              }}
            >
              <PrivateLayout>
                <Component {...props} />
              </PrivateLayout>
            </PrivateContext.Provider>
          </>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default Private;
