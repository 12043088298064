const unmask = (value: string): string =>
  value && String(value).replace(/[^\+\d]/g, '');

const cpf = (value: string): string =>
  String(value)
    .replace(/\D/g, '') // Replace any non-number character for nothing
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

const rg = (value: string): string =>
  String(value)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{1})\d+?$/, '$1');

const cnpj = (value: string): string =>
  String(value)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

const zip_code = (value: string): string =>
  String(value)
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');

const phone = (value: string): string =>
  String(value)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d)/, '$1');

const cellphone = (value: string): string =>
  String(value)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');

const phoneOrCellphone = (value: string): string =>
  String(value).replace(/\D/g, '').length > 10
    ? cellphone(value)
    : phone(value);

const strictPhone = (value: string): string =>
  String(value)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '+$1 $2')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d)/, '$1');

const stricCellphone = (value: string): string =>
  String(value)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '+$1 $2')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');

const strictPhoneOrCellphone = (value: string): string =>
  String(value).replace(/\D/g, '').length > 12
    ? stricCellphone(value)
    : strictPhone(value);

const currency = (value: string): string => {
  const string = String(value).replace(/\D/g, '');

  if (string) {
    return (parseInt(string, 10) / 100).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      // style: 'currency', To use R$ remove the comments
      // currency: 'BRL',
    });
  }
  return '';
};

const currencyAllPlatforms = (value: string): string => {
  const string = String(value).replace(/\D/g, '');

  if (string) {
    const [integer, decimal] = (parseInt(string, 10) / 100)
      .toFixed(2)
      .toString()
      .split('.');

    return `${integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${decimal}`;
  }

  return '';
};

const creditOrDebitCard = (value: string): string =>
  String(value)
    .replace(/\D/g, '') // Replace any non-number character for nothing
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(.\d{4})\d+?$/, '$1');

const creditOrDebitCardExpiry = (value: string): string =>
  String(value)
    .replace(/\D/g, '') // Replace any non-number character for nothing
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(.\d{2})\d+?$/, '$1');

const percent = (value: string): string => {
  const string = String(value).replace(/\D/g, '');

  let number = parseInt(string, 10) / 100;

  if (number < 0) {
    number = 0;
  } else if (number > 100) {
    number = 100;
  }

  if (number === 0) return '0';
  if (!number) return '';

  const [integer, decimal] = number.toFixed(2).split('.');

  return `${integer},${decimal}`;
};

const cpfOrCnpj = (value: string): string => {
  if (String(value).replace(/\D/g, '').length > 11) {
    return cnpj(value);
  } else {
    return cpf(value);
  }
};

export type Masks = {
  unmask: (value: string) => string;
  cpf: (value: string) => string;
  rg: (value: string) => string;
  cnpj: (value: string) => string;
  cpfOrCnpj: (value: string) => string;
  zip_code: (value: string) => string;
  phone: (value: string) => string;
  cellphone: (value: string) => string;
  phoneOrCellphone: (value: string) => string;
  strictPhone: (value: string) => string;
  stricCellphone: (value: string) => string;
  strictPhoneOrCellphone: (value: string) => string;
  currency: (value: string) => string;
  currencyAllPlatforms: (value: string) => string;
  creditOrDebitCard: (value: string) => string;
  creditOrDebitCardExpiry: (value: string) => string;
  percent: (value: string) => string;
};

const masks: Masks = {
  unmask,
  cpf,
  rg,
  cnpj,
  cpfOrCnpj,
  zip_code,
  phone,
  cellphone,
  phoneOrCellphone,
  strictPhone,
  stricCellphone,
  strictPhoneOrCellphone,
  currency,
  currencyAllPlatforms,
  creditOrDebitCard,
  creditOrDebitCardExpiry,
  percent,
};

export default masks;
