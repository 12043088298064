import yup from 'src/libs/yup';
import { dateOperatorSchema } from 'src/validators/DefaultFilter.schema';

const DashboardFilterSchema = yup.object().shape({
  funnel_id: yup.number().label('Funil'),
  attendants: yup.array().label('Atendentes'),
  period: dateOperatorSchema.optional().label('Período'),
});

export default DashboardFilterSchema;
