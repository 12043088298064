import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { queryClient } from 'src/config/query';
import { Opportunity } from 'src/modules/attendance/@types/models';
import { ATTENDANCE_ADMIN_QUERY_KEYS } from 'src/modules/attendance/constants/query';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

import { attendants } from './attendants';

async function getFunnelOpportunities(
  funnelId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<Opportunity>>(
    `/attendance/admin/funnels/${funnelId}/opportunities`,
    config,
  );

  return response;
}

function useFunnelOpportunitiesQuery(
  funnelId: string | number,
  data?: IApiUseQueryData<Opportunity>,
) {
  return useQuery<IApiUseQueryFnData<Opportunity>>(
    [
      ATTENDANCE_ADMIN_QUERY_KEYS.FUNNELS_OPPORTUNITIES,
      funnelId,
      data?.requestConfig?.params,
    ],
    () => getFunnelOpportunities(funnelId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateFunnelOpportunitiesQueries() {
  queryClient.invalidateQueries([
    ATTENDANCE_ADMIN_QUERY_KEYS.FUNNELS_OPPORTUNITIES,
  ]);
}

async function storeFunnelOpportunity(
  funnelId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Opportunity>(
    `/attendance/admin/funnels/${funnelId}/opportunities`,
    data,
    config,
  );

  invalidateFunnelOpportunitiesQueries();

  return response;
}

async function getFunnelOpportunity(
  funnelId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Opportunity>(
    `/attendance/admin/funnels/${funnelId}/opportunities/${id}`,
    config,
  );

  return response;
}

async function updateFunnelOpportunity(
  funnelId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<Opportunity>(
    `/attendance/admin/funnels/${funnelId}/opportunities/${id}`,
    data,
    config,
  );

  invalidateFunnelOpportunitiesQueries();

  return response;
}

function useFunnelOpportunityQuery(
  funnelId: string | number,
  id: string | number,
  data?: IApiUseQueryData<Opportunity, AxiosResponse>,
) {
  return useQuery<AxiosResponse<Opportunity>>(
    [
      ATTENDANCE_ADMIN_QUERY_KEYS.FUNNELS_OPPORTUNITIES,
      funnelId,
      id,
      data?.requestConfig?.params,
    ],
    () => getFunnelOpportunity(funnelId, id, data?.requestConfig),
    data?.queryOptions,
  );
}

async function storeFunnelOpportunityAttendance(
  funnelId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Opportunity>(
    `/attendance/admin/funnels/${funnelId}/opportunities/${id}/attendance`,
    data,
    config,
  );

  invalidateFunnelOpportunitiesQueries();

  return response;
}

async function gainFunnelOpportunity(
  funnelId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Opportunity>(
    `/attendance/admin/funnels/${funnelId}/opportunities/${id}/gain`,
    data,
    config,
  );

  invalidateFunnelOpportunitiesQueries();

  return response;
}

async function lostFunnelOpportunity(
  funnelId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Opportunity>(
    `/attendance/admin/funnels/${funnelId}/opportunities/${id}/lost`,
    data,
    config,
  );

  invalidateFunnelOpportunitiesQueries();

  return response;
}

async function openFunnelOpportunity(
  funnelId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Opportunity>(
    `/attendance/admin/funnels/${funnelId}/opportunities/${id}/open`,
    data,
    config,
  );

  invalidateFunnelOpportunitiesQueries();

  return response;
}

async function transferFunnelOpportunity(
  funnelId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Opportunity>(
    `/attendance/admin/funnels/${funnelId}/opportunities/${id}/transfer`,
    data,
    config,
  );

  invalidateFunnelOpportunitiesQueries();

  return response;
}

async function transferFunnelOpportunitiesAttendant(
  funnelId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/attendance/admin/funnels/${funnelId}/opportunities/transfer-attendant`,
    data,
    config,
  );

  invalidateFunnelOpportunitiesQueries();

  return response;
}

async function destroyFunnelOpportunity(
  funnelId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<Opportunity>(
    `/attendance/admin/funnels/${funnelId}/opportunities/${id}`,
    config,
  );

  invalidateFunnelOpportunitiesQueries();

  return response;
}

export const opportunities = {
  getFunnelOpportunities,
  useFunnelOpportunitiesQuery,
  storeFunnelOpportunity,
  getFunnelOpportunity,
  updateFunnelOpportunity,
  useFunnelOpportunityQuery,
  storeFunnelOpportunityAttendance,
  gainFunnelOpportunity,
  lostFunnelOpportunity,
  openFunnelOpportunity,
  transferFunnelOpportunity,
  transferFunnelOpportunitiesAttendant,
  destroyFunnelOpportunity,
  attendants,
};
