import yup from 'src/libs/yup';

export const ReasonStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  type: yup.string().required().label('Tipo'),
});

export const ReasonUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  type: yup.string().required().label('Tipo'),
});
