import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import { AxiosRequestConfig } from 'axios';
import DatePicker from 'src/components/Form/DatePicker';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { IEditParams } from 'src/interfaces/IEditParams';
import { Goal } from 'src/modules/attendance/@types/models';
import AutocompleteFunnels from 'src/modules/attendance/components/Form/_common/AutocompleteFunnels';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';
import attendanceApi from 'src/modules/attendance/services/api';
import {
  GoalStoreSchema,
  GoalUpdateSchema,
} from 'src/modules/attendance/validators/Goal/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const GoalTab: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } =
    useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [goalStatus, setGoalStatus] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>();
  const history = useHistory();
  const params = useParams<IEditParams>();

  const goalSchema = params.id ? GoalUpdateSchema : GoalStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const { data: goal } = await attendanceApi.admin.goals.getGoal(
          params.id,
        );
        setGoalStatus(goal.status);

        setTimeout(() => {
          formRef.current?.setData({
            ...goal,
            funnels: goal.funnels?.map((funnel) => funnel.id),
          });
        });
      }
    } catch (error) {
      handleErrors(error, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<Goal> = async ({ formData, shouldGoBack }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(goalSchema, formData);

      if (!success) {
        console.log({ errors });
        return showFormErrors(errors, formRef);
      }

      let newGoal: Goal;
      if (params.id) {
        const response = await attendanceApi.admin.goals.updateGoal(
          params.id,
          data,
        );
        newGoal = response.data;
      } else {
        const response = await attendanceApi.admin.goals.storeGoal(data);
        newGoal = response.data;
      }

      toast.success('Meta salva com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/atendimento/metas/${newGoal.id}`);
      }
    } catch (error) {
      handleErrors(error, 'Erro', goalSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async (isDeleting) => {
    try {
      setLoading(true);

      let deleteConfig: AxiosRequestConfig = {};
      if (isDeleting) {
        deleteConfig = { data: { delete: true } };
      }

      await attendanceApi.admin.goals.destroyGoal(params.id, deleteConfig);

      toast.success('Meta desativada!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao desativar Meta');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await attendanceApi.admin.goals.restoreGoal(params.id);

      toast.success('Meta restaurada!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao atualizar Meta.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: [ATTENDANCE_PERMISSIONS.GOALS.STORE],
        update: [ATTENDANCE_PERMISSIONS.GOALS.UPDATE],
        destroy: [ATTENDANCE_PERMISSIONS.GOALS.DESTROY],
        restore: [ATTENDANCE_PERMISSIONS.GOALS.RESTORE],
      }}
      resourceName="Motivo"
      onSave={handleSave}
      onDestroy={handleDestroy}
      canDelete
      onRestore={handleRestore}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.id}
      isActive={goalStatus === STATUSES.ACTIVE}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <DatePicker
                    name="start_date"
                    label="Início"
                    onChange={(date) => setStartDate(date || undefined)}
                    inputProps={{ required: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <DatePicker
                    name="end_date"
                    label="Fim"
                    minDate={startDate}
                    disabled={!startDate}
                    inputProps={{ required: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField name="name" label="Nome" required />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label="Descrição"
                    multiline
                    rows={3}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="global_amount"
                    label="Meta da Equipe"
                    mask="currency"
                    returnUnmasked
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteFunnels name="funnels" label="Funis" multiple />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default GoalTab;
