import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FormHandles, SubmitHandler } from '@unform/core';
import TextField from 'src/components/Form/TextField';
import Link from 'src/components/Link';
import { queryClient } from 'src/config/query';
import api from 'src/services/api';
import { AuthActions } from 'src/store/ducks/auth';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import loginSchema from 'src/validators/User/login.schema';

import { Avatar, Form, SubmitButton } from './styles';

const Login: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const dispatch = useDispatch();

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        loginSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const response = await api.admin.auth.login(data);

      const { authentication, user } = response.data;

      if (user.change_password) {
        setChangePassword(true);
      } else {
        const userPermissions = user.permissions || [];
        const rolePermissions = user.role?.permissions?.filter(
          (rolePerm) =>
            !user.permissions?.find((userPerm) => userPerm.id === rolePerm.id),
        );

        userPermissions.concat(rolePermissions || []);

        queryClient.clear();

        dispatch(
          AuthActions.authSuccess(authentication.token, user, userPermissions),
        );
      }
    } catch (error) {
      handleErrors(error, 'Erro', loginSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Avatar>
              <LockOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography component="h1" variant="h5" textAlign="center">
            Login
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="login"
            label="Nome de Usuário"
            required
            disabled={changePassword}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="password"
            label="Senha"
            required
            type="password"
            autoComplete="current-password"
            disabled={changePassword}
          />
        </Grid>

        {changePassword && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Cadastre sua nova senha.</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="new_password"
                label="Nova Senha"
                required
                autoFocus
                type="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="new_password_confirmation"
                label="Confirmar Nova Senha"
                required
                type="password"
                autoComplete="current-password"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <SubmitButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Entrar
          </SubmitButton>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Link to="/esqueci-minha-senha" variant="body2">
              Esqueceu sua Senha?
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Login;
