import React, { forwardRef, useRef } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import { Grid } from '@mui/material';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form as Unform } from '@unform/web';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import useCombinedRefs from 'src/hooks/useCombinedRefs';

import { FormProps } from './interfaces';

const Form: React.ForwardRefRenderFunction<FormHandles, FormProps> = (
  {
    children,
    resourceName = 'Registro',
    onSave,
    onDestroy,
    onRestore,
    onCancel,
    loading,
    permissions,
    isUpdating,
    isActive,
    canDelete,
    ...rest
  },
  ref,
) => {
  const formRef = useCombinedRefs<FormHandles>(ref);
  const destroyDialogRef = useRef<ConfirmDialogRef>(null);
  const restoreDialogRef = useRef<ConfirmDialogRef>(null);

  let shouldGoBack = false;

  const handleSave: SubmitHandler = async (formData, formHelpers, event) => {
    try {
      if (loading) return;

      await onSave({
        formData,
        formHelpers,
        event,
        shouldGoBack,
      });
    } finally {
      shouldGoBack = false;
    }
  };

  function handleSaveAndBack() {
    shouldGoBack = true;
    formRef.current?.submitForm();
  }

  async function confirmDelete() {
    if (!onDestroy) return;

    await onDestroy(destroyDialogRef.current?.isDelete());
    destroyDialogRef.current?.hide();
  }

  async function confirmRestore() {
    if (onRestore) await onRestore();
    restoreDialogRef.current?.hide();
  }

  async function handleCancel() {
    if (onCancel) await onCancel();
  }

  function renderSaveButtons() {
    const savePermissions = isUpdating ? permissions.update : permissions.store;

    return (
      <Grid container gap={1}>
        {onCancel && (
          <Button
            loading={loading}
            startIcon={<ArrowBackIcon />}
            variant="outlined"
            color="error"
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        )}

        <Can
          behavior={permissions.behavior}
          permissions={savePermissions || []}
        >
          <Button
            loading={loading}
            startIcon={<CheckIcon />}
            variant="outlined"
            color="success"
            onClick={handleSaveAndBack}
          >
            Salvar e Voltar
          </Button>

          <Button
            type="submit"
            loading={loading}
            startIcon={<CheckIcon />}
            variant="contained"
            color="success"
          >
            Salvar
          </Button>
        </Can>
      </Grid>
    );
  }

  return (
    <Unform ref={formRef} onSubmit={handleSave} noValidate {...rest}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {children}
        </Grid>

        <Grid item xs={12}>
          <Grid container gap={1} justifyContent="space-between">
            <Grid>
              {isUpdating &&
                (isActive
                  ? onDestroy && (
                      <Can
                        behavior={permissions.behavior}
                        permissions={permissions.destroy || []}
                      >
                        <Button
                          type="button"
                          loading={loading}
                          startIcon={<CloseIcon />}
                          variant="contained"
                          color="error"
                          onClick={() => destroyDialogRef.current?.show()}
                        >
                          Desativar
                        </Button>
                      </Can>
                    )
                  : onRestore && (
                      <Can
                        behavior={permissions.behavior}
                        permissions={permissions.restore || []}
                      >
                        <Button
                          type="button"
                          loading={loading}
                          startIcon={<RestoreIcon />}
                          color="primary"
                          onClick={() => restoreDialogRef.current?.show()}
                        >
                          Restaurar
                        </Button>
                      </Can>
                    ))}
            </Grid>

            <Grid>{renderSaveButtons()}</Grid>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={destroyDialogRef}
        title={`Desativar ${resourceName}`}
        description="Confirma esta ação?"
        confirmColor="error"
        onConfirm={confirmDelete}
        loading={loading}
        canDelete={canDelete}
      />

      <ConfirmDialog
        ref={restoreDialogRef}
        title={`Restaurar ${resourceName}`}
        description="Confirma esta ação?"
        confirmColor={'primary'}
        onConfirm={confirmRestore}
        loading={loading}
      />
    </Unform>
  );
};

export default forwardRef(Form);
