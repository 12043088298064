import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import BackButton from 'src/components/BackButton';
import NavigationTabs, { NavigationTab } from 'src/components/NavigationTabs';
import TabPanel from 'src/components/TabPanel';
import { IEditParams } from 'src/interfaces/IEditParams';

import GoalTab from './GoalTab';
import { Container } from './styles';
import UsersTab from './UsersTab';

const Save: FC = () => {
  const params = useParams<IEditParams>();

  const GoalsTabs: NavigationTab[] = [
    { name: 'Meta', slug: '', Component: GoalTab },
    {
      name: 'Usuários',
      slug: 'usuarios',
      Component: UsersTab,
      disabled: !params.id,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.id ? 'Editar' : 'Novo'} Meta
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <NavigationTabs
            baseUrl={`/atendimento/metas${params.id ? `/${params.id}` : ''}`}
            tabs={GoalsTabs}
          />
        </Grid>

        <Grid item xs={12}>
          {GoalsTabs.map(({ name, slug, Component }) => (
            <TabPanel key={name} show={slug === (params.selectedTab || '')}>
              <Component />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Save;
