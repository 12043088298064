import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import MuiButton from '@mui/material/Button';

import { LinkButtonProps } from './interfaces';

const LinkButton: FC<LinkButtonProps> = ({ children, ...rest }) => {
  return (
    <MuiButton component={rest.to ? RouterLink : undefined} {...rest}>
      {children}
    </MuiButton>
  );
};

export default LinkButton;
