import styled from 'styled-components';

import Kanban from 'src/components/Kanban';

export const Container = styled(Kanban.Container)`
  flex: 1;
  padding: 4px;
  position: relative;
`;

export const Column = styled(Kanban.Column)``;

export const ColumnHeader = styled(Kanban.ColumnHeader)`
  padding: 12px;
  text-align: center;
`;

export const ColumnBody = styled(Kanban.ColumnBody)`
  padding: 8px 12px;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  align-items: center;
  justify-content: center;
`;
