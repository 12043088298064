import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import Button from 'src/components/Button';
import { OmnichannelContext } from 'src/components/Omnichannel/ContextProvider/types';
import { useStoreSelector } from 'src/hooks/store';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import { handleErrors } from 'src/utils/errors';

export type CloseChatDialogProps = {
  onClose: () => void;
  onSuccess?: () => void;
} & DialogProps;

const CloseChatDialog: React.FC<CloseChatDialogProps> = ({
  onSuccess,
  onClose,
  ...rest
}) => {
  const { selectedChat } = useContext(OmnichannelContext);
  const [loading, setLoading] = useState(false);
  const { user } = useStoreSelector((state) => state.auth);

  const canCloseChat =
    selectedChat?.attendant_id === user?.id || user?.is_admin;

  const isChatActive = selectedChat?.status === 'active';

  function handleClose() {
    if (loading) return;

    onClose();
  }

  async function handleConfirm() {
    try {
      setLoading(true);

      if (selectedChat) {
        WSOmnichannel.socket?.emit(
          'close_chat',
          {
            chat_id: selectedChat?._id,
          },
          (result) => {
            if (result.error) {
              toast.error(result.error.message);
            } else {
              if (onSuccess) onSuccess();
              toast.success('Chat encerrado!');
              onClose();
            }
          },
        );
      }
    } catch (error) {
      handleErrors(error, 'Erro ao encerrar chat.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} {...rest}>
      <DialogTitle align="center">Encerrar Chat</DialogTitle>

      <DialogContent>
        <Typography>Deseja encerrar o chat do lead?</Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          color="inherit"
          disabled={loading}
          variant="text"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleConfirm}
          endIcon={<CheckIcon />}
          color="error"
          disabled={loading || !canCloseChat || !isChatActive}
          variant="contained"
        >
          Encerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseChatDialog;
