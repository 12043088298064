export const ATTENDANCE_COMMON_QUERY_KEYS = {
  REASONS: 'common-reasons',
  FUNNELS: 'common-funnels',
  FUNNELS_STEPS: 'common-funnels-steps',
  FUNNELS_ATTENDANTS: 'common-funnels-attendants',
  GOALS: 'common-goals',
  GOALS_USERS: 'common-goals-users',
};

export const ATTENDANCE_ADMIN_QUERY_KEYS = {
  DASHBOARD: 'admin-dashboard',
  REASONS: 'admin-reasons',
  FUNNELS: 'admin-funnels',
  FUNNELS_ATTENDANTS: 'admin-funnels-attendants',
  FUNNELS_STEPS: 'admin-funnels-steps',
  FUNNELS_OPPORTUNITIES: 'admin-funnels-opportunities',
  FUNNELS_OPPORTUNITIES_ATTENDANTS: 'admin-funnels-opportunities-attendants',
  GOALS: 'admin-goals',
  GOALS_USERS: 'admin-goals-users',
};
