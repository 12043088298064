import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { ADMIN_QUERY_KEYS, COMMON_QUERY_KEYS } from 'src/constants/query';
import { Lead } from 'src/interfaces/models';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';
import { Chat } from 'src/services/websocket/omnichannel/types';

export async function getLeads(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Lead>>(
    '/admin/leads',
    config,
  );

  return response;
}

export function useLeadsQuery(data?: IApiUseQueryData<Lead>) {
  return useQuery<IApiUseQueryFnData<Lead>>(
    [ADMIN_QUERY_KEYS.LEADS, data?.requestConfig?.params],
    () => getLeads(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateLeadsQueries() {
  queryClient.invalidateQueries([COMMON_QUERY_KEYS.LEADS]);
  queryClient.invalidateQueries([ADMIN_QUERY_KEYS.LEADS]);
}

export async function storeLead(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Lead>('/admin/leads', data, config);

  invalidateLeadsQueries();

  return response;
}

export async function getLead(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Lead>(`/admin/leads/${id}`, config);

  return response;
}

export async function updateLead(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<Lead>(`/admin/leads/${id}`, data, config);

  invalidateLeadsQueries();

  return response;
}

export async function destroyLead(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(`/admin/leads/${id}`, config);

  invalidateLeadsQueries();

  return response;
}

export async function restoreLead(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/admin/leads/${id}/restore`,
    config,
  );

  invalidateLeadsQueries();

  return response;
}

export async function getLeadChat(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Chat>(`/admin/leads/${id}/chat`, config);

  return response;
}

export async function storeLeadChat(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Chat>(
    `/admin/leads/${id}/chat`,
    data,
    config,
  );

  return response;
}
