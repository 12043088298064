import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { FunnelStep } from 'src/modules/attendance/@types/models';
import attendanceApi from 'src/modules/attendance/services/api';

type AutocompleteFunnelStepsProps = {
  funnelId: number;
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps<FunnelStep>, 'options'>;

const AutocompleteFunnelSteps: React.FC<AutocompleteFunnelStepsProps> = ({
  funnelId,
  requestConfig,
  ...rest
}) => {
  const { isLoading, data: response } =
    attendanceApi.admin.common.useFunnelStepsQuery(funnelId, {
      requestConfig,
    });

  const options =
    response?.data?.map((step) => ({
      key: step.id,
      label: step.name,
      value: step.id,
      data: step,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteFunnelSteps;
