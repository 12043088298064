import { FC, useCallback, useEffect, useRef, useState } from 'react';

import MuiAutocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useField } from '@unform/core';

import {
  AutocompleteProps,
  AutocompleteRef,
  AutocompleteValue,
  CustomMuiAutocompleteProps,
  IOption,
} from './interfaces';

const filter = createFilterOptions<IOption>();

const Autocomplete: FC<AutocompleteProps> = ({
  name,
  options,
  label,
  onChange,
  loading,
  textFieldProps,
  creatable,
  handleCreate,
  ...rest
}) => {
  const { fieldName, registerField, error, defaultValue, clearError } =
    useField(name);
  const autocompleteRef = useRef<AutocompleteRef>(null);
  const [value, setValue] = useState<AutocompleteValue>(
    rest.multiple ? ([] as IOption[]) : null,
  );

  const handleSetValue = useCallback(
    (
      newValue: (string | IOption)[] | string | IOption | null,
      isDefault = false,
    ) => {
      if (rest.multiple) {
        // multiple values
        if (Array.isArray(newValue)) {
          const selectedValues = autocompleteRef.current?.options?.filter(
            (option) =>
              newValue.find((val) =>
                typeof val === 'object' ? val == option : val == option.value,
              ) !== undefined,
          );
          setValue(selectedValues || []);
          if (isDefault && selectedValues?.length && autocompleteRef.current) {
            autocompleteRef.current.defaultSetted = true;
          }
        } else {
          setValue([]);
        }
      } else {
        // single value
        if (Array.isArray(newValue)) {
          setValue(null);
        } else if (typeof newValue === 'object') {
          setValue(newValue);
          if (isDefault && newValue && autocompleteRef.current) {
            autocompleteRef.current.defaultSetted = true;
          }
        } else {
          const selectedValue = autocompleteRef.current?.options?.find(
            (option) => option.value == newValue,
          );
          setValue(selectedValue || null);
          if (isDefault && selectedValue && autocompleteRef.current) {
            autocompleteRef.current.defaultSetted = true;
          }
        }
      }
    },
    [rest.multiple],
  );

  useEffect(() => {
    registerField<any>({
      name: fieldName,
      ref: autocompleteRef.current,
      getValue: (ref) => {
        if (Array.isArray(ref?.value)) {
          return ref.value.map((option: IOption) => option.value);
        } else {
          return ref?.value?.value ?? null; // 1º value is from ref, 2º is from selected option
        }
      },
      setValue: (ref, newValue) => {
        handleSetValue(newValue);
        clearError();
      },
      clearValue: (_, newValue) => {
        handleSetValue(newValue);
        clearError();
      },
    });
  }, [fieldName, registerField, clearError, handleSetValue]);

  useEffect(() => {
    if (autocompleteRef.current && options) {
      autocompleteRef.current.options = options;

      if (defaultValue && !autocompleteRef.current.defaultSetted) {
        handleSetValue(defaultValue, true);
      }
    }
  }, [defaultValue, handleSetValue, options]);

  useEffect(() => {
    const hasChanged = autocompleteRef.current?.value != value;
    if (!hasChanged) return;

    if (autocompleteRef.current) {
      autocompleteRef.current.value = value;
    }

    if (onChange) onChange(value);
  }, [value, onChange]);

  const handleChange = useCallback<
    NonNullable<CustomMuiAutocompleteProps['onChange']>
  >(
    (_, newOption) => {
      clearError();
      if (
        typeof newOption !== 'string' &&
        !Array.isArray(newOption) &&
        creatable &&
        newOption?.key === 'create'
      ) {
        if (handleCreate) handleCreate(newOption.value);
      } else {
        handleSetValue(newOption);
      }
    },
    [clearError, creatable, handleCreate, handleSetValue],
  );

  const handleFilter = useCallback<
    NonNullable<AutocompleteProps['filterOptions']>
  >(
    (options, params) => {
      const filtered = filter(options, params);

      if (creatable && params.inputValue !== '') {
        filtered.push({
          key: 'create',
          label: `Criar "${params.inputValue}"`,
          value: params.inputValue,
        });
      }

      return filtered;
    },
    [creatable],
  );

  return (
    <MuiAutocomplete
      ref={autocompleteRef}
      value={value}
      onChange={handleChange}
      filterOptions={handleFilter}
      isOptionEqualToValue={(option, value) => option.value == value.value}
      getOptionKey={(option) =>
        typeof option === 'string' ? option : option.key
      }
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      options={options}
      loading={loading}
      fullWidth
      disableCloseOnSelect={rest.multiple}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          type="text"
          name={name}
          error={!!error}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          variant="outlined"
          {...textFieldProps}
        />
      )}
      {...rest}
    />
  );
};

export default Autocomplete;
