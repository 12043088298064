import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import DateTimePicker from 'src/components/Form/DateTimePicker';
import RadioGroup from 'src/components/Form/RadioGroup';
import TextField from 'src/components/Form/TextField';
import IconButton from 'src/components/IconButton';
import { Opportunity } from 'src/modules/attendance/@types/models';
import AutocompleteFunnelSteps from 'src/modules/attendance/components/Form/_common/AutocompleteFunnelSteps';
import AutocompleteReasons from 'src/modules/attendance/components/Form/_common/AutocompleteReasons';
import { OPPORTUNITY_STATUS_OPTIONS } from 'src/modules/attendance/constants';
import attendanceApi from 'src/modules/attendance/services/api';
import { InterestProductFormSchema } from 'src/modules/attendance/validators/Opportunity/save.schema';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';
import yupValidate from 'src/utils/yupValidate';

import { AttendanceData, InterestProductData } from './types';

export type StoreOpportunityAttendanceDialogProps = {
  opportunity: Opportunity;
  funnelId: number;
  onClose: () => void;
  onSuccess?: (opportunity: Opportunity) => void;
} & DialogProps;

const StoreOpportunityAttendanceDialog: React.FC<
  StoreOpportunityAttendanceDialogProps
> = ({ opportunity, onSuccess, onClose, funnelId, ...rest }) => {
  const attendanceFormRef = useRef<FormHandles>(null);
  const [selectedStatus, setSelectedStatus] = useState(opportunity.status);
  const [products, setProducts] = useState<InterestProductData[]>([]);
  const productFormRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const reset = useCallback(() => {
    attendanceFormRef.current?.setData({
      lead_name: opportunity.lead?.name,
      amount: opportunity.amount.toString(),
      funnel_step_id: opportunity.funnel_step_id,
    });
    setProducts([]);
  }, [opportunity]);

  function handleClose() {
    if (loading) return;
    onClose();
  }

  function clearProductForm() {
    productFormRef.current?.setData({ id: Math.random() });
  }

  async function saveProduct(productFormData: InterestProductData) {
    const { success, data, errors } = await yupValidate(
      InterestProductFormSchema,
      productFormData,
    );

    if (!success) {
      return showFormErrors(errors, productFormRef);
    }

    setProducts((state) => {
      const editIndex = state.findIndex((s) => s.id === data.id);

      if (editIndex >= 0) {
        return state.map((s) => {
          if (s.id === data.id) return data;
          return s;
        });
      }

      return [...state, data];
    });

    clearProductForm();
  }

  function removeProduct(id: number) {
    setProducts((state) => state.filter((s) => s.id !== id));
  }

  async function submitAttendance(formData: AttendanceData) {
    try {
      setLoading(true);

      const response =
        await attendanceApi.admin.funnels.opportunities.storeFunnelOpportunityAttendance(
          funnelId,
          opportunity.id,
          {
            ...formData,
            products: products,
          },
        );

      toast.success('Atendimento salvo com sucesso!');

      if (onSuccess) onSuccess(response.data);
      reset();
      onClose();
    } catch (error) {
      handleErrors(error, 'Erro ao cadastrar atendimento.');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (rest.open) reset();
  }, [reset, rest.open]);

  const productsColumns: GridColDef<InterestProductData>[] = [
    { field: 'reference_code', headerName: 'Código' },
    { field: 'name', headerName: 'Produto', flex: 1 },
    { field: 'type', headerName: 'Tipo' },
    {
      field: 'price',
      headerName: 'Preço',
      valueGetter({ row }) {
        return masks.currency(row.price || '');
      },
    },
    { field: 'quantity', headerName: 'Qtde' },
    {
      field: 'actions',
      headerName: '',
      align: 'center',
      renderCell({ row }) {
        return (
          <>
            <IconButton
              size="small"
              tooltip="Editar"
              color="info"
              onClick={() => productFormRef.current?.setData(row)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              tooltip="Remover"
              color="error"
              onClick={() => removeProduct(row.id)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Dialog fullWidth maxWidth="lg" {...rest}>
      <DialogTitle align="center">Novo Atendimento</DialogTitle>

      <DialogContent>
        <Grid container spacing={2} paddingTop={1}>
          <Grid item xs={12}>
            <Form
              ref={attendanceFormRef}
              onSubmit={submitAttendance}
              initialData={{
                lead_name: opportunity.lead?.name,
                amount: opportunity.amount.toString(),
                funnel_step_id: opportunity.funnel_step_id,
                status: opportunity.status,
                wait_until: opportunity.wait_until,
                reason_id: opportunity.reason_id,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField name="lead_name" label="Lead" disabled />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    name="amount"
                    label="Valor"
                    mask="currency"
                    returnUnmasked
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <AutocompleteFunnelSteps
                    name="funnel_step_id"
                    label="Etapa"
                    funnelId={funnelId}
                    disableClearable
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label="Descrição sobre o Atendimento"
                    multiline
                    rows={4}
                    autoFocus
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <RadioGroup
                    name="status"
                    label="Status"
                    options={OPPORTUNITY_STATUS_OPTIONS}
                    row
                    onChange={(value) =>
                      setSelectedStatus(value as Opportunity['status'])
                    }
                  />
                </Grid>

                {selectedStatus === 'open' && (
                  <Grid item xs={12}>
                    <DateTimePicker
                      name="wait_until"
                      label="Em espera até"
                      disablePast
                      returnAsDate
                    />
                  </Grid>
                )}

                {selectedStatus === 'gain' && (
                  <Grid item xs={12}>
                    <AutocompleteReasons
                      name="reason_id"
                      label="Motivo do Ganho"
                      requestConfig={{
                        params: { funnel_id: funnelId, type: 'gain' },
                      }}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                )}

                {selectedStatus === 'lost' && (
                  <Grid item xs={12}>
                    <AutocompleteReasons
                      name="reason_id"
                      label="Motivo da Perda"
                      requestConfig={{
                        params: { funnel_id: funnelId, type: 'lost' },
                      }}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left">Produtos</Divider>
          </Grid>

          <Grid item xs={12}>
            <Form
              ref={productFormRef}
              onSubmit={saveProduct}
              initialData={{ id: Math.random() }}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <TextField name="id" hidden />

                    <Grid item xs={4} sm={1.5}>
                      <TextField name="reference_code" label="ID/Código" />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField name="name" label="Nome do Produto" required />
                    </Grid>

                    <Grid item xs={8} sm={2}>
                      <TextField
                        name="type"
                        label="Tipo"
                        placeholder="Hospedagem / Ingresso"
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        name="price"
                        label="Preço"
                        mask="currency"
                        returnUnmasked
                      />
                    </Grid>

                    <Grid item xs={12} sm={1}>
                      <TextField name="quantity" label="Qtde" type="number" />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignSelf: 'center',
                      }}
                    >
                      <IconButton
                        type="submit"
                        color="success"
                        tooltip="Salvar"
                        size="small"
                      >
                        <CheckIcon fontSize="inherit" />
                      </IconButton>

                      <IconButton
                        color="error"
                        onClick={clearProductForm}
                        tooltip="Cancelar"
                        size="small"
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <DataGrid
                    disableColumnMenu
                    columns={productsColumns}
                    rows={products}
                    autoHeight
                    hideFooter
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between" gap={2}>
              <Button
                variant="contained"
                color="error"
                startIcon={<CloseIcon />}
                onClick={handleClose}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => attendanceFormRef.current?.submitForm()}
                variant="contained"
                color="success"
                startIcon={<CheckIcon />}
                loading={loading}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StoreOpportunityAttendanceDialog;
