import styled from 'styled-components';

import MuiCardActions from '@mui/material/CardActions';
import MuiContainer from '@mui/material/Container';

export const Container = styled(MuiContainer)`
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const Actions = styled(MuiCardActions)`
  justify-content: flex-end;
`;
