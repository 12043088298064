import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import BackButton from 'src/components/BackButton';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { BASE_PERMISSIONS } from 'src/constants/permissions/base';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IDepartment } from 'src/interfaces/models';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  DepartmentStoreSchema,
  DepartmentUpdateSchema,
} from 'src/validators/Department/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } =
    useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const departmentSchema = params.id
    ? DepartmentUpdateSchema
    : DepartmentStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const { data: department } = await api.admin.departments.getDepartment(
          params.id,
        );

        setStatus(department.status);

        formRef.current?.setData(department);
      }
    } catch (error) {
      handleErrors(error, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave = async ({ formData, shouldGoBack }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        departmentSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newDepartment: IDepartment;
      if (params.id) {
        const response = await api.admin.departments.updateDepartment(
          params.id,
          data,
        );
        newDepartment = response.data;
      } else {
        const response = await api.admin.departments.storeDepartment(data);
        newDepartment = response.data;
      }

      toast.success('Departamento salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/departamentos/${newDepartment.id}`);
      }
    } catch (error) {
      handleErrors(error, 'Erro', departmentSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await api.admin.departments.destroyDepartment(params.id);

      toast.success('Departamento desativado!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao desativar departamento');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await api.admin.departments.restoreDepartment(params.id);

      toast.success('Departamento restaurado!');

      loadData();
    } catch (error) {
      handleErrors(error, 'Erro ao restaurar departamento.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Container maxWidth="xl">
      <Form
        ref={formRef}
        permissions={{
          store: [BASE_PERMISSIONS.ROLES.STORE],
          update: [BASE_PERMISSIONS.ROLES.UPDATE],
          destroy: [BASE_PERMISSIONS.ROLES.DESTROY],
          restore: [BASE_PERMISSIONS.ROLES.RESTORE],
        }}
        resourceName="Função"
        onSave={handleSave}
        onDestroy={handleDestroy}
        onRestore={handleRestore}
        onCancel={handleCancel}
        loading={loading || layoutLoading}
        isUpdating={!!params.id}
        isActive={status === STATUSES.ACTIVE}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Novo'} Departamento
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField name="name" label="Nome" required />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Descrição"
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default Save;
