import styled, { css } from 'styled-components';

import MuiPaper from '@mui/material/Paper';

export const Container = styled(MuiPaper)<{ isChatOpen?: boolean }>`
  width: 100%;
  display: grid;
  align-content: start;

  ${({ theme, isChatOpen }) => css`
    ${theme.breakpoints.down('lg')} {
      ${isChatOpen &&
      css`
        display: none;
      `}
    }
    ${theme.breakpoints.up('sm')} {
      max-width: 300px;
    }
  `}

  .chat-list {
    overflow-y: auto;
  }

  .new-message {
    &::after {
      content: '';
      position: absolute;
      top: 5px;
      right: 20px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.palette.error.dark};
    }
  }
`;
