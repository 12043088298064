import yup from 'src/libs/yup';

export const FunnelStepStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
});

export const FunnelStepUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  ordination: yup.number().required().label('Ordem'),
  description: yup.string().label('Descrição'),
});
