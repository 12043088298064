import { IRouteInterface } from 'src/interfaces/IRoutes';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';

import PrivatePages from '../pages/Private';

export const AttendanceRouteList: IRouteInterface[] = [
  {
    exact: true,
    component: PrivatePages.Reasons.List,
    label: 'Motivos',
    path: '/atendimento/motivos',
    type: 'private',
    permissions: [ATTENDANCE_PERMISSIONS.REASONS.LIST],
  },
  {
    component: PrivatePages.Reasons.Save,
    label: 'Novo Motivo',
    path: '/atendimento/motivos/novo',
    type: 'private',
    permissions: [ATTENDANCE_PERMISSIONS.REASONS.STORE],
  },
  {
    component: PrivatePages.Reasons.Save,
    label: 'Editar Motivo',
    path: '/atendimento/motivos/:id',
    type: 'private',
    permissions: [ATTENDANCE_PERMISSIONS.REASONS.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.Funnels.List,
    label: 'Funis',
    path: '/atendimento/funis',
    type: 'private',
    permissions: [ATTENDANCE_PERMISSIONS.FUNNELS.LIST],
  },
  {
    component: PrivatePages.Funnels.Save,
    label: 'Novo Funil',
    path: '/atendimento/funis/novo',
    type: 'private',
    permissions: [ATTENDANCE_PERMISSIONS.FUNNELS.STORE],
  },
  {
    component: PrivatePages.Funnels.Save,
    label: 'Editar Funil',
    path: '/atendimento/funis/:id/:selectedTab?',
    type: 'private',
    permissions: [ATTENDANCE_PERMISSIONS.FUNNELS.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.Dashboard,
    label: 'Dashboard',
    path: '/atendimento/dashboard',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Opportunities,
    label: 'Oportunidades',
    path: '/atendimento/oportunidades',
    type: 'private',
  },
  {
    component: PrivatePages.OpportunityDetails,
    label: 'Detalhes da Oportunidade',
    path: '/atendimento/oportunidades/:funnelId/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Goals.List,
    label: 'Metas',
    path: '/atendimento/metas',
    type: 'private',
    permissions: [ATTENDANCE_PERMISSIONS.GOALS.LIST],
  },
  {
    component: PrivatePages.Goals.Save,
    label: 'Nova Meta',
    path: '/atendimento/metas/novo',
    type: 'private',
    permissions: [ATTENDANCE_PERMISSIONS.GOALS.STORE],
  },
  {
    component: PrivatePages.Goals.Save,
    label: 'Editar Meta',
    path: '/atendimento/metas/:id/:selectedTab?',
    type: 'private',
    permissions: [ATTENDANCE_PERMISSIONS.GOALS.UPDATE],
  },
];
