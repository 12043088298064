import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import { BASE_PERMISSIONS } from 'src/constants/permissions/base';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { Lead } from 'src/interfaces/models';
import api from 'src/services/api';
import masks from 'src/utils/masks';
import LeadFilterSchema from 'src/validators/Lead/filter.schema';

import LeadFilters from './LeadFilters';
import { Container } from './styles';

const List: FC = () => {
  const {
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    data: response,
    refetch,
  } = useApiQueryPagination(api.admin.leads.useLeadsQuery);

  const columns: GridColDef<Lead>[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Nome',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">{row.name}</Typography>
            <Typography variant="body2">{row.email}</Typography>
          </div>
        );
      },
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      minWidth: 160,
      valueGetter({ value }) {
        return value ? masks.strictPhoneOrCellphone(value) : '';
      },
    },
    {
      field: 'whatsapp_number',
      headerName: 'WhatsApp',
      minWidth: 160,
      valueGetter({ value }) {
        return value ? masks.strictPhoneOrCellphone(value) : '';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resourceName="Lead"
            resourceId={id}
            resourceStatus={row.status}
            editLink={`/leads/${id}`}
            editPermissions={[BASE_PERMISSIONS.LEADS.UPDATE]}
            destroyApiEndpoint={api.admin.leads.destroyLead}
            destroyPermissions={[BASE_PERMISSIONS.LEADS.DESTROY]}
            restoreApiEndpoint={api.admin.leads.restoreLead}
            restorePermissions={[BASE_PERMISSIONS.LEADS.RESTORE]}
            descriptionPrefix={`O Lead ${row.name}`}
            onFinish={refetch}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Leads</Typography>

            <Can
              behavior="disable"
              permissions={[BASE_PERMISSIONS.LEADS.STORE]}
            >
              <LinkButton
                to="/leads/novo"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={response?.data.data || []}
                pageSize={response?.data.meta.per_page}
                rowCount={response?.data.meta.total}
                columns={columns}
                loading={isLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                canSearch
                showFilters
                filterProps={{
                  yupSchema: LeadFilterSchema,
                  children: <LeadFilters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
