import { QueryClient } from '@tanstack/react-query';
import { handleErrors } from 'src/utils/errors';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      staleTime: 1000 * 60, // 60 seconds,
      onError: (error) => handleErrors(error),
    },
  },
});
