import { FC, useContext, useRef } from 'react';
import { toast } from 'react-toastify';

import SendIcon from '@mui/icons-material/Send';
import { IconButton, InputAdornment } from '@mui/material';
import { FormHandles, SubmitHandler } from '@unform/core';
import TextField from 'src/components/Form/TextField';
import { OmnichannelContext } from 'src/components/Omnichannel/ContextProvider/types';
import WSOmnichannel from 'src/services/websocket/omnichannel';

import { Container } from './styles';

const Sms: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { selectedChat } = useContext(OmnichannelContext);

  const handleSendSMS: SubmitHandler = (formData) => {
    if (formData.message && selectedChat?._id) {
      WSOmnichannel.socket?.emit(
        'send_message',
        {
          chat_id: selectedChat?._id,
          message: formData.message,
          channel: 'sms',
          type: 'text',
        },
        (result) => {
          if (result.error) {
            toast.error(result.error.message);
          } else {
            formRef.current?.setFieldValue('message', '');
          }
        },
      );

      formRef.current?.setFieldValue('message', '');
    }
  };

  return (
    <Container ref={formRef} onSubmit={handleSendSMS}>
      <TextField
        name="message"
        label="Mensagem via SMS"
        multiline
        maxRows={5}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton color="primary" type="submit" size="large">
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
          onKeyPress: (e) => {
            if (!e.shiftKey && e.key === 'Enter') {
              e.preventDefault();
              formRef.current?.submitForm();
            }
          },
        }}
      />
    </Container>
  );
};

export default Sms;
