import React from 'react';
import { MessageBox } from 'react-chat-elements';

import { formatDistanceToNow } from 'date-fns';
import { MessageProps } from 'src/components/Omnichannel/ChatContent/Message';
import OminichannelUtils from 'src/utils/omnichannel';

const Text: React.FC<MessageProps> = ({ message, onReply, reply }) => {
  if (!message.whatsapp) return null;

  return (
    <MessageBox
      id={message.whatsapp.messageId}
      notch
      removeButton={false}
      reply={reply}
      replyButton={!!onReply}
      onReplyClick={onReply}
      retracted={false}
      status={OminichannelUtils.getChatMessageStatus(message)}
      position={message.direction === 'outgoing' ? 'right' : 'left'}
      type="text"
      title={''}
      titleColor=""
      text={message.whatsapp.text?.message || ''}
      date={new Date(message.sended_at)}
      dateString={formatDistanceToNow(new Date(message.sended_at), {
        addSuffix: true,
        includeSeconds: true,
      })}
      focus={false}
      forwarded={false}
    />
  );
};

export default Text;
