import { FC, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import CustomMenuItem from 'src/components/Table/CustomMenuItem';
import StatusIcon from 'src/components/Table/StatusIcon';
import { columnDate } from 'src/components/Table/types';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { Goal } from 'src/modules/attendance/@types/models';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';
import attendanceApi from 'src/modules/attendance/services/api';
import GoalFilterSchema from 'src/modules/attendance/validators/Goal/filter.schema';
import { handleErrors } from 'src/utils/errors';

import GoalsFilters from './GoalsFilters';
import { Container } from './styles';

const List: FC = () => {
  const {
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    data: response,
    refetch,
  } = useApiQueryPagination(attendanceApi.admin.goals.useGoalsQuery);
  const archiveDialogRef = useRef<ConfirmDialogRef>(null);
  const [goal, setGoal] = useState<Goal>();
  const [archiveLoading, setArchiveLoading] = useState(false);

  function openArchiveDialog(goal: Goal) {
    setGoal(goal);
    archiveDialogRef.current?.show();
  }

  async function handleArchive() {
    try {
      setArchiveLoading(true);

      if (goal) {
        await attendanceApi.admin.goals.archiveGoal(goal.id);

        toast.success('Meta arquivada!');

        archiveDialogRef.current?.hide();
      }
    } catch (error) {
      handleErrors(error, 'Erro ao arquivar Meta.');
    } finally {
      setArchiveLoading(false);
    }
  }

  const columns: GridColDef<Goal>[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome', flex: 1 },
    { field: 'start_date', headerName: 'Início', ...columnDate },
    { field: 'end_date', headerName: 'Fim', ...columnDate },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resourceName="Meta"
            resourceId={id}
            resourceStatus={row.status}
            editLink={`/atendimento/metas/${id}`}
            editPermissions={[ATTENDANCE_PERMISSIONS.GOALS.UPDATE]}
            destroyApiEndpoint={attendanceApi.admin.goals.destroyGoal}
            destroyPermissions={[ATTENDANCE_PERMISSIONS.GOALS.DESTROY]}
            canDelete
            restoreApiEndpoint={attendanceApi.admin.goals.restoreGoal}
            restorePermissions={[ATTENDANCE_PERMISSIONS.GOALS.RESTORE]}
            descriptionPrefix={`A meta ${row.name}`}
            onFinish={refetch}
            startItems={
              <>
                <Can permissions={[ATTENDANCE_PERMISSIONS.GOALS.DESTROY]}>
                  <CustomMenuItem
                    text="Arquivar"
                    Icon={ArchiveIcon}
                    iconProps={{ color: 'success' }}
                    onClick={() => openArchiveDialog(row)}
                  />
                </Can>
              </>
            }
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Metas</Typography>

            <Can
              behavior="disable"
              permissions={[ATTENDANCE_PERMISSIONS.GOALS.STORE]}
            >
              <LinkButton
                to="/atendimento/metas/novo"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={response?.data.data || []}
                pageSize={response?.data.meta.per_page}
                rowCount={response?.data.meta.total}
                columns={columns}
                loading={isLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                canSearch
                showFilters
                filterProps={{
                  yupSchema: GoalFilterSchema,
                  children: <GoalsFilters />,
                  disableDefaultFilters: true,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={archiveDialogRef}
        title={`Arquivar Meta ${goal?.id}`}
        description={`A meta "${goal?.name}" será arquivada. Confirma esta ação?`}
        confirmColor="success"
        confirmText="Arquivar"
        onConfirm={handleArchive}
        loading={archiveLoading}
      />
    </Container>
  );
};

export default List;
