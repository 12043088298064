import * as common from './common';
import * as funnels from './funnels';
import goals from './goals';
import * as reasons from './reasons';

export default {
  /** common - Functions that don't need permissions */
  common,
  reasons,
  funnels,
  goals,
};
