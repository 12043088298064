import React from 'react';

import Apexchart from 'src/components/Apexchart';
import { Dashboard } from 'src/interfaces/models/Dashboard';
import { fPercent } from 'src/utils/number';

type OpportunitiesSeizingChartProps = {
  data?: Dashboard['opportunitiesSeizing'];
};

const OpportunitiesSeizingChart: React.FC<OpportunitiesSeizingChartProps> = ({
  data,
}) => {
  return (
    <Apexchart
      type="bar"
      options={{
        title: {
          text: 'Aproveitamento das Oportunidades',
          align: 'center',
        },
        subtitle: {
          text: 'Quantidade Ganha / Quantidade Total',
          align: 'center',
        },
        chart: { id: 'opportunities-seizing' },
        xaxis: {
          categories: data?.labels || [''],
        },
        yaxis: {
          max: 100, // 100%
          labels: {
            formatter(value) {
              return fPercent(Number(value));
            },
          },
        },
        legend: {
          showForSingleSeries: true,
        },
        dataLabels: {
          dropShadow: {
            enabled: true,
          },
          formatter(value) {
            return fPercent(Number(value));
          },
        },
        tooltip: {
          y: {
            formatter(value) {
              return fPercent(Number(value));
            },
          },
        },
      }}
      series={data?.series || []}
    />
  );
};

export default OpportunitiesSeizingChart;
