import React, { useEffect, useState } from 'react';

import List from '@mui/material/List';
import useWhatsAppMenu from 'src/modules/whatsapp/hooks/useWhatsAppMenuItems';
import MenuItems from 'src/routes/menu-items';

import SideMenuItem from './Item';

const MenuListItems: React.FC = () => {
  const [items, setItems] = useState(MenuItems);
  const whatsappMenu = useWhatsAppMenu();

  useEffect(() => {
    if (!whatsappMenu) return;

    setItems((state) => {
      const whatsappMenuIndex = state.findIndex(
        (s) => s.route === whatsappMenu.route,
      );

      const deleteCount = whatsappMenuIndex === -1 ? 0 : 1;
      const position = whatsappMenuIndex === -1 ? 3 : whatsappMenuIndex;

      state.splice(position, deleteCount, whatsappMenu);

      return state;
    });
  }, [whatsappMenu]);

  return (
    <List>
      {items.map((item) => (
        <SideMenuItem key={item.primaryText} item={item} />
      ))}
    </List>
  );
};

export default MenuListItems;
