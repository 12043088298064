import yup from 'src/libs/yup';

export const GoalStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().max(255).label('Descrição'),
  start_date: yup.date().required().label('Início'),
  end_date: yup.date().required().label('Fim'),
  global_amount: yup.string().nullable().label('Meta da Equipe'),
  funnels: yup.array().of(yup.number()).nullable().label('Funis'),
});

export const GoalUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().max(255).label('Descrição'),
  start_date: yup.date().required().label('Início'),
  end_date: yup.date().required().label('Fim'),
  global_amount: yup.string().nullable().label('Meta da Equipe'),
  funnels: yup.array().of(yup.number()).nullable().label('Funis'),
});

export const GoalUserStoreSchema = yup.object().shape({
  user_id: yup.number().required().label('Usuário'),
  amount: yup.number().required().label('Meta'),
});

export const GoalUserUpdateSchema = yup.object().shape({
  user_id: yup.number().required().label('Usuário'),
  amount: yup.number().required().label('Meta'),
});
