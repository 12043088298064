import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import SearchIcon from '@mui/icons-material/Search';
import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import SearchLeadDialog from 'src/components/_functions/SearchLeadDialog';
import { SearchLeadDialogRef } from 'src/components/_functions/SearchLeadDialog/types';
import IconButton from 'src/components/IconButton';
import ChatItem from 'src/components/Omnichannel/ChatItem';
import { OmnichannelContext } from 'src/components/Omnichannel/ContextProvider/types';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import { OmnichannelListenEvents } from 'src/services/websocket/omnichannel/types';
import NotificationUtils from 'src/utils/notifications';
import OminichannelUtils from 'src/utils/omnichannel';

import { Container } from './styles';

const ChatList: React.FC = () => {
  const { connected, chats, setChats, setSelectedChat, zApiInstance } =
    useContext(OmnichannelContext);
  const searchLeadRef = useRef<SearchLeadDialogRef>(null);

  const handleNewChat = useCallback(
    (leadId: number) => {
      const chat = chats.find((chat) => chat.lead_id === leadId);
      if (chat) {
        setSelectedChat(chat);
      } else {
        WSOmnichannel.socket?.emit(
          'new_chat',
          { lead_id: leadId },
          (result) => {
            if (result.error) {
              toast.error(result.error.message);
            } else {
              setChats((data) => [...data, result.data]);
            }
          },
        );
      }

      searchLeadRef.current?.hide();
    },
    [chats, setChats, setSelectedChat],
  );

  useEffect(() => {
    const onNewChat: OmnichannelListenEvents['new_chat'] = (newChat) => {
      setChats((data) => {
        const exists = data.find((chat) => chat._id === newChat._id);
        const hasAccess = OminichannelUtils.hasAccessToChat(newChat);

        if (hasAccess) {
          if (!exists) return [newChat, ...data]; // if not find, add the new chat
          return data.map((chat) =>
            chat._id === newChat._id ? newChat : chat,
          );
        } else {
          // remove chat if lost access
          if (exists) {
            return data.filter((chat) => chat._id !== newChat._id);
          }

          return data; // keep value if not exists
        }
      });
    };

    const onMessageReceived: OmnichannelListenEvents['message_received'] = (
      message,
    ) => {
      setChats((data) =>
        data
          .map((chat) => {
            if (chat._id === message.chat) {
              chat.last_message = message;
              if (message.direction === 'incoming') {
                NotificationUtils.createNotification({
                  title: `Mensagem de ${chat.lead?.name}`,
                  options: {
                    body: OminichannelUtils.getChatMessageText(message),
                  },
                });
              }
            }

            return chat;
          })
          .sort((a, b) => {
            const aSended = a.last_message?.sended_at || '';
            const bSended = b.last_message?.sended_at || '';

            if (aSended > bSended) {
              return -1;
            }
            if (aSended < bSended) {
              return 1;
            }
            return 0;
          }),
      );
    };

    const onViewChat: OmnichannelListenEvents['view_chat'] = (viewedChat) => {
      setChats((data) =>
        data.map((chat) => {
          if (chat._id === viewedChat._id) {
            return viewedChat;
          }
          return chat;
        }),
      );
    };

    WSOmnichannel.socket?.emit('list_chats', (result) => {
      if (result.error) {
        toast.error(result.error.message);
      } else {
        setChats(result.data);
      }
    });

    WSOmnichannel.socket?.on('new_chat', onNewChat);

    WSOmnichannel.socket?.on('message_received', onMessageReceived);

    WSOmnichannel.socket?.on('view_chat', onViewChat);

    return () => {
      WSOmnichannel.socket?.removeListener('new_chat', onNewChat);
      WSOmnichannel.socket?.removeListener(
        'message_received',
        onMessageReceived,
      );
      WSOmnichannel.socket?.removeListener('view_chat', onViewChat);
    };
  }, [setChats, connected]);

  return (
    <Container>
      <List disablePadding>
        <ListItem>
          <ListItemText>Conversas ({zApiInstance?.name})</ListItemText>

          <ListItemSecondaryAction>
            <IconButton
              size="small"
              onClick={() => searchLeadRef.current?.show()}
            >
              <SearchIcon fontSize="inherit" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <Divider />
      </List>

      <List className="chat-list">
        {chats.map((chat) => (
          <ChatItem key={chat._id} chat={chat} />
        ))}
      </List>

      <SearchLeadDialog
        ref={searchLeadRef}
        onSelectLead={(lead) => handleNewChat(lead.id)}
      />
    </Container>
  );
};

export default ChatList;
