import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Omnichannel from 'src/components/Omnichannel';
import { ZApiInstance } from 'src/modules/whatsapp/@types/models';
import whatsappApi from 'src/modules/whatsapp/services/api';
import { handleErrors } from 'src/utils/errors';

import { Container } from './styles';

type WhatsAppRouteParams = {
  zApiInstanceId: string;
};

const Chats: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [zApiInstance, setZApiInstance] = useState<ZApiInstance>();
  const params = useParams<WhatsAppRouteParams>();

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const { data } = await whatsappApi.admin.common.getZApiInstance(
          params.zApiInstanceId,
        );

        setZApiInstance(data);
      } catch (error) {
        handleErrors(error, 'Erro ao buscar instância whatsapp.');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [params.zApiInstanceId]);

  if (loading || !zApiInstance) return null;

  return (
    <Container maxWidth="xl">
      <Omnichannel.ContextProvider zApiInstance={zApiInstance}>
        <Omnichannel.Container>
          <Omnichannel.ChatList />

          <Omnichannel.ChatContent />
        </Omnichannel.Container>
      </Omnichannel.ContextProvider>
    </Container>
  );
};

export default Chats;
