import { FC } from 'react';

import { RemoveRedEye } from '@mui/icons-material';
import path from 'path-browserify';

import { PreviewImg, PreviewFile } from './styles';

interface PreviewProps {
  src: string | File;
}

const Preview: FC<PreviewProps> = ({ src }) => {
  let isImage: boolean;
  let link: string;
  let alt: string;

  if (src instanceof File) {
    isImage = src.type.startsWith('image');
    link = URL.createObjectURL(src);
    alt = src.name;
  } else {
    isImage = ['.png', '.jpg', '.jpeg', '.gif', '.webp'].includes(
      path.extname(src).toLowerCase(),
    );
    link = src;
    alt = path.basename(src);
  }

  if (isImage) {
    return <PreviewImg src={link} alt={alt} title={alt} />;
  }

  return (
    <PreviewFile
      component="a"
      href={link}
      target="_blank"
      color="primary"
      size="small"
      startIcon={<RemoveRedEye />}
      title={alt}
    >
      Ver Arquivo
    </PreviewFile>
  );
};

export default Preview;
