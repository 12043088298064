import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { IUser } from 'src/interfaces/models';
import {
  ATTENDANCE_ADMIN_QUERY_KEYS,
  ATTENDANCE_COMMON_QUERY_KEYS,
} from 'src/modules/attendance/constants/query';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getGoalUsers(
  goalId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<IUser>>(
    `/attendance/admin/goals/${goalId}/users`,
    config,
  );

  return response;
}

export function useGoalUsersQuery(
  goalId: string | number,
  data?: IApiUseQueryData<IUser>,
) {
  return useQuery<IApiUseQueryFnData<IUser>>(
    [
      ATTENDANCE_ADMIN_QUERY_KEYS.GOALS_USERS,
      goalId,
      data?.requestConfig?.params,
    ],
    () => getGoalUsers(goalId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateGoalUsersQueries() {
  queryClient.invalidateQueries([ATTENDANCE_COMMON_QUERY_KEYS.GOALS_USERS]);
  queryClient.invalidateQueries([ATTENDANCE_ADMIN_QUERY_KEYS.GOALS_USERS]);
}

export async function storeGoalUser(
  goalId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<void>(
    `/attendance/admin/goals/${goalId}/users`,
    data,
    config,
  );

  invalidateGoalUsersQueries();

  return response;
}

export async function getGoalUser(
  goalId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IUser>(
    `/attendance/admin/goals/${goalId}/users/${id}`,
    config,
  );

  return response;
}

export async function updateGoalUser(
  goalId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<void>(
    `/attendance/admin/goals/${goalId}/users/${id}`,
    data,
    config,
  );

  invalidateGoalUsersQueries();

  return response;
}

export async function destroyGoalUser(
  goalId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/attendance/admin/goals/${goalId}/users/${id}`,
    config,
  );

  invalidateGoalUsersQueries();

  return response;
}
