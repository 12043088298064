import { FC, useContext } from 'react';

import {
  Avatar,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { formatDistanceToNow, isBefore, parseISO } from 'date-fns';
import { OmnichannelContext } from 'src/components/Omnichannel/ContextProvider/types';
import { Chat } from 'src/services/websocket/omnichannel/types';
import OminichannelUtils from 'src/utils/omnichannel';

import { FreeChatLabel } from './styles';

interface ChatItemProps {
  chat: Chat;
}

const ChatItem: FC<ChatItemProps> = ({ chat }) => {
  const { selectedChat, setSelectedChat } = useContext(OmnichannelContext);

  function renderChatLastMessage() {
    if (!chat.last_message) return null;

    const Icon = OminichannelUtils.getChatMessageIcon(chat.last_message);

    return (
      <>
        {formatDistanceToNow(new Date(chat.last_message.sended_at))}:{' '}
        {Icon && <Icon fontSize="inherit" />}{' '}
        {OminichannelUtils.getChatMessageText(chat.last_message)}
      </>
    );
  }

  function getUnreadClass() {
    if (chat._id === selectedChat?._id) return '';

    let unread = false;
    if (!chat.view_at && chat.last_message) {
      unread = true;
    } else if (
      chat.view_at &&
      chat.last_message?.direction === 'incoming' &&
      chat.last_message?.sended_at &&
      isBefore(parseISO(chat.view_at), parseISO(chat.last_message.sended_at))
    ) {
      unread = true;
    }

    return unread ? 'new-message' : '';
  }

  function renderAvatar() {
    let letters: string | undefined;

    if (chat.lead?.name) {
      const exploded = chat.lead.name.split(' ');
      if (exploded.length === 1) {
        letters = exploded.pop()?.substring(0, 2);
      } else {
        letters = `${exploded.shift()?.charAt(0) || ''}${
          exploded.pop()?.charAt(0) || ''
        }`;
      }
    }

    return (
      <Avatar alt={letters} src={chat.image}>
        {letters}
      </Avatar>
    );
  }

  function openChat() {
    if (selectedChat?._id !== chat._id) {
      setSelectedChat(chat);
    }
  }

  return (
    <>
      <ListItemButton
        selected={chat._id === selectedChat?._id}
        className={`${getUnreadClass()}`}
        onClick={openChat}
      >
        <ListItemAvatar>{renderAvatar()}</ListItemAvatar>

        <ListItemText
          primary={
            <Typography variant="body1" noWrap>
              {chat.lead?.name}
            </Typography>
          }
          secondary={
            <>
              <Typography variant="body2" noWrap color="GrayText">
                {renderChatLastMessage()}
              </Typography>
            </>
          }
        />

        {!chat.attendant_id && <FreeChatLabel title="Sem Atendente" />}
      </ListItemButton>

      <Divider variant="middle" />
    </>
  );
};

export default ChatItem;
