import styled from 'styled-components';
import { css } from 'styled-components';

import MuiIconButton from '@mui/material/IconButton';
import { IconButtonProps } from 'src/components/IconButton/interfaces';

export const StyledIconButton = styled(MuiIconButton)<{
  variant: IconButtonProps['variant'];
}>`
  ${({ variant }) =>
    variant === 'outlined' &&
    css`
      border: 1px solid;
    `}
`;
