import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { IUser } from 'src/interfaces/models';
import { ATTENDANCE_ADMIN_QUERY_KEYS } from 'src/modules/attendance/constants/query';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getFunnelAttendants(
  funnelId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<IUser>>(
    `/attendance/admin/funnels/${funnelId}/attendants`,
    config,
  );

  return response;
}

function useFunnelAttendantsQuery(
  funnelId: string | number,
  data?: IApiUseQueryData<IUser>,
) {
  return useQuery<IApiUseQueryFnData<IUser>>(
    [
      ATTENDANCE_ADMIN_QUERY_KEYS.FUNNELS_ATTENDANTS,
      funnelId,
      data?.requestConfig?.params,
    ],
    () => getFunnelAttendants(funnelId, data?.requestConfig),
    data?.queryOptions,
  );
}

export const attendants = {
  getFunnelAttendants,
  useFunnelAttendantsQuery,
};
