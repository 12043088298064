import styled from 'styled-components';

import MuiPaper from '@mui/material/Paper';

export const Container = styled(MuiPaper)`
  flex: 1;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const MessagesContainer = styled.div`
  flex: 1;
  max-height: inherit;

  padding: 16px;
  display: grid;
  gap: 8px;
  align-content: start;

  overflow-y: auto;
`;

export const InputContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 1)};
`;

export const SendContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
