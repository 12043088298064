import { FC } from 'react';
import { MessageBoxType } from 'react-chat-elements';

import SystemMessage from 'src/components/Omnichannel/ChatContent/Message/SystemMessage';
import WhatsAppMessage from 'src/modules/whatsapp/components/Omnichannel/ChatContent/Message/WhatsApp';
import { ChatMessage } from 'src/services/websocket/omnichannel/types';

export interface MessageProps {
  message: ChatMessage;
  onReply?: MessageBoxType['onReplyClick'];
  reply?: MessageBoxType['reply'];
  onReplyClick?: (referenceMessage: ChatMessage) => void;
}

const Message: FC<MessageProps> = ({ message, onReply, onReplyClick }) => {
  switch (message.channel) {
    case 'whatsapp':
      return (
        <WhatsAppMessage
          message={message}
          onReply={onReply}
          onReplyClick={onReplyClick}
        />
      );
    case 'system':
      return <SystemMessage message={message} />;
    default:
      return null;
  }
};

export default Message;
