import React, { ReactNode, RefObject, useCallback } from 'react';

import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import { IPermission } from 'src/interfaces/models';
import api from 'src/services/api';

import { ModuleTitle, TitleDivider } from './styles';

type ModuleGroups = {
  [key: string]: IPermission[];
};
type PermissionsModules = {
  [key: string]: ModuleGroups;
};

export type CheckboxPermissionsProps = {
  title?: string;
  description?: ReactNode;
  /** @default string 'permissions' */
  name?: string;
  /** Pass formRef to enable select all */
  formRef?: RefObject<FormHandles>;
};

const CheckboxPermissions: React.FC<CheckboxPermissionsProps> = ({
  title,
  description,
  name = 'permissions',
  formRef,
}) => {
  const { data: response } = api.admin.common.usePermissionsQuery();

  const modules: PermissionsModules = {};
  response?.data.forEach((permission) => {
    const currentModule = modules[permission.module] || {};
    const currentGroup = currentModule[permission.group] || [];

    currentGroup.push(permission);

    currentModule[permission.group] = currentGroup;
    modules[permission.module] = currentModule;
  });

  const handleSelectPermissions = useCallback(
    (checked: boolean, permissions: IPermission[]) => {
      permissions.forEach((permission) => {
        formRef?.current?.setFieldValue(`${name}[${permission.id}]`, checked);
      });
    },
    [formRef, name],
  );

  return (
    <Grid container spacing={2}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            {title}
          </Typography>
        </Grid>
      )}

      {description && (
        <Grid item container xs={12} justifyContent="center">
          <Typography variant="subtitle2" align="justify">
            {description}
          </Typography>
        </Grid>
      )}

      {Object.keys(modules).map((moduleKey) => (
        <Grid item key={moduleKey} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <TitleDivider variant="middle" textAlign="left">
                  <ModuleTitle variant="h6">{moduleKey}</ModuleTitle>
                </TitleDivider>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                {Object.keys(modules[moduleKey]).map((groupKey) => (
                  <Grid item xs={12} md={6} xl={4} key={groupKey}>
                    <Card>
                      <CardHeader
                        subheader={
                          formRef ? (
                            <CheckBox
                              name={`${groupKey}-all`}
                              size="small"
                              color="default"
                              label={groupKey}
                              onChange={(e) =>
                                handleSelectPermissions(
                                  e.target.checked,
                                  modules[moduleKey][groupKey],
                                )
                              }
                            />
                          ) : (
                            groupKey
                          )
                        }
                      />
                      <CardContent>
                        {modules[moduleKey][groupKey]
                          .sort((a, b) => a.id - b.id)
                          .map((permission) => (
                            <CheckBox
                              key={permission.id}
                              name={`${name}[${permission.id}]`}
                              label={permission.name}
                              size="small"
                            />
                          ))}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default CheckboxPermissions;
