import React from 'react';

import Apexchart from 'src/components/Apexchart';
import { Dashboard } from 'src/interfaces/models/Dashboard';

type ReasonsGainChartProps = {
  data?: Dashboard['reasonsGain'];
};

const ReasonsGainChart: React.FC<ReasonsGainChartProps> = ({ data }) => {
  return (
    <Apexchart
      type="bar"
      options={{
        title: {
          text: 'Ganho',
          align: 'center',
        },
        chart: { id: 'reasons-gain' },
        yaxis: {
          // min: 1,
          // stepSize: 1,
        },
        xaxis: {
          categories: data?.labels || [''],
        },
        dataLabels: {
          dropShadow: {
            enabled: true,
          },
        },

        legend: {
          formatter() {
            return '';
          },
        },
      }}
      series={data?.series || []}
    />
  );
};

export default ReasonsGainChart;
