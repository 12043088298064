import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Grid, Tooltip, Typography } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridSelectionModel,
} from '@mui/x-data-grid';
import { isAfter } from 'date-fns';
import { columnDateTime } from 'src/components/Table/types';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { Funnel, Opportunity } from 'src/modules/attendance/@types/models';
import ChangeStatusOpportunityDialog from 'src/modules/attendance/components/ChangeStatusOpportunityDialog';
import OpportunityUnread from 'src/modules/attendance/components/OpportunityUnread';
import TransferOpportunitiesAttendantDialog from 'src/modules/attendance/components/TransferOpportunitiesAttendantDialog';
import { OPPORTUNITY_STATUS_LABEL } from 'src/modules/attendance/constants';
import { opportunityStatusTextColor } from 'src/modules/attendance/helpers';
import ActionsButton from 'src/modules/attendance/pages/Private/Opportunities/Content/ViewList/ActionsButton';
import StepCell from 'src/modules/attendance/pages/Private/Opportunities/Content/ViewList/StepCell';
import attendanceApi from 'src/modules/attendance/services/api';
import { formatDate } from 'src/utils/helpers';

import { OpportunitiesContext } from '../../context';
import { StyledTable } from './styles';

type ViewListProps = {
  funnel: Funnel;
};

const ViewList: React.FC<ViewListProps> = ({ funnel }) => {
  const {
    data: response,
    isLoading,
    handlePageChange,
    handlePageSizeChange,
    refetch,
  } = useApiQueryPagination<Opportunity>(
    (queryData) =>
      attendanceApi.admin.funnels.opportunities.useFunnelOpportunitiesQuery(
        funnel.id,
        queryData,
      ),
    {
      preload: ['step'],
      order_by: [{ column: 'last_interest_at', direction: 'desc' }],
    },
  );
  const { needsReload, setNeedsReload, setOpportunitiesTotals } =
    useContext(OpportunitiesContext);
  const [rowsSelected, setRowsSelected] = useState<GridSelectionModel>([]);
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [changeOpportunityStatus, setChangeOpportunityStatus] =
    useState<Opportunity>();
  const history = useHistory();
  const location = useLocation();

  function handleCellClick({ row, field }: GridCellParams<Opportunity>) {
    switch (field) {
      case '__check__':
      case 'step.name':
        return;
      case 'status':
        setChangeOpportunityStatus(row);
        break;
      default:
        history.push(`${location.pathname}/${funnel.id}/${row.id}`);
        break;
    }
  }

  const columns: GridColDef<Opportunity>[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Título',
      flex: 1,
      minWidth: 200,
      valueGetter({ row }) {
        return row.name;
      },
    },
    {
      field: 'lead.name',
      headerName: 'Lead',
      flex: 1,
      minWidth: 200,
      valueGetter({ row }) {
        return row.lead?.name;
      },
    },
    {
      field: 'step.name',
      headerName: 'Etapa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell({ row }) {
        return (
          <StepCell funnelId={funnel.id} funnelStepId={row.funnel_step_id} />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      renderCell({ row }) {
        return (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            gap={1}
            color={opportunityStatusTextColor(row)}
          >
            <Typography fontSize="inherit">
              {OPPORTUNITY_STATUS_LABEL[row.status]}
            </Typography>

            <OpportunityUnread opportunity={row} />

            {row.is_waiting && row.wait_until && (
              <Tooltip
                title={`Em espera até ${formatDate(
                  row.wait_until,
                  'dd/MM/yyyy HH:mm',
                )}`}
              >
                {isAfter(new Date(), new Date(row.wait_until)) ? (
                  <PriorityHighIcon fontSize="inherit" color="error" />
                ) : (
                  <AccessTimeIcon fontSize="inherit" />
                )}
              </Tooltip>
            )}
          </Grid>
        );
      },
    },
    {
      field: 'last_interest_at',
      headerName: 'Últ. Interesse',
      ...columnDateTime,
    },
    {
      field: 'attendant.name',
      headerName: 'Atendente',
      headerAlign: 'center',
      align: 'center',
      valueGetter({ row }) {
        return row.attendant?.name;
      },
    },
    {
      field: 'last_attendance_at',
      headerName: 'Últ. Atendimento',
      ...columnDateTime,
      minWidth: 130,
    },
  ];

  useEffect(() => {
    async function handleReload() {
      await refetch();
      setNeedsReload(false);
    }

    if (needsReload) handleReload();
  }, [needsReload, refetch, setNeedsReload]);

  useEffect(() => {
    setOpportunitiesTotals({
      qty: response?.data.data.length || 0,
      sum: response?.data.data.reduce((sum, opp) => sum + opp.amount, 0) || 0,
    });
  }, [response, setOpportunitiesTotals]);

  return (
    <Grid container direction="column" flex={1} spacing={1}>
      <Grid item>
        <ActionsButton
          onTransferClick={() => setTransferModalOpen(true)}
          disabled={!rowsSelected.length}
        />
      </Grid>

      <Grid item flexGrow={1}>
        <StyledTable
          components={{ Toolbar: null }}
          rows={response?.data.data || []}
          pageSize={response?.data.meta.per_page}
          rowCount={response?.data.meta.total}
          columns={columns}
          loading={isLoading}
          pagination
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowClassName={() => 'cursor-pointer'}
          onCellClick={handleCellClick}
          onSelectionModelChange={setRowsSelected}
          selectionModel={rowsSelected}
          disableSelectionOnClick
          checkboxSelection
        />
      </Grid>

      <TransferOpportunitiesAttendantDialog
        open={transferModalOpen}
        onClose={() => setTransferModalOpen(false)}
        funnelId={funnel.id}
        opportuntyIds={rowsSelected}
      />

      {changeOpportunityStatus && (
        <ChangeStatusOpportunityDialog
          open={!!changeOpportunityStatus}
          onClose={() => setChangeOpportunityStatus(undefined)}
          funnelId={funnel.id}
          opportunity={changeOpportunityStatus}
        />
      )}
    </Grid>
  );
};

export default ViewList;
