import { BASE_PERMISSIONS } from 'src/constants/permissions/base';
import { IRouteInterface } from 'src/interfaces/IRoutes';
import { AttendanceRouteList } from 'src/modules/attendance/routes/list';
import { whatsappRouteList } from 'src/modules/whatsapp/routes/list';

import AuthPages from '../pages/Auth';
import PrivatePages from '../pages/Private';
import PublicPages from '../pages/Public';

export const authRoutes: IRouteInterface[] = [
  {
    exact: true,
    component: AuthPages.Login,
    label: 'Login',
    path: '/',
    type: 'auth',
  },
  {
    component: AuthPages.ForgotPassword,
    exact: true,
    label: 'Esqueci minha senha',
    path: '/esqueci-minha-senha',
    type: 'auth',
  },
  {
    component: AuthPages.RecoverPassword,
    label: 'Recuperar senha',
    path: '/recuperar-senha/:token',
    type: 'auth',
  },
];

export const privateRoutes: IRouteInterface[] = [
  {
    exact: true,
    component: () => null,
    label: 'Início',
    path: '/inicio',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Dashboard,
    label: 'Início',
    path: '/dashboard/:selectedTab?',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Profile,
    label: 'Perfil',
    path: '/perfil',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Users.List,
    label: 'Usuários',
    path: '/usuarios',
    type: 'private',
    permissions: [BASE_PERMISSIONS.USERS.LIST],
  },
  {
    component: PrivatePages.Users.Save,
    label: 'Novo Usuário',
    path: '/usuarios/novo',
    type: 'private',
    permissions: [BASE_PERMISSIONS.USERS.STORE],
  },
  {
    component: PrivatePages.Users.Save,
    label: 'Editar Usuário',
    path: '/usuarios/:id',
    type: 'private',
    permissions: [BASE_PERMISSIONS.USERS.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.Roles.List,
    label: 'Funções',
    path: '/funcoes',
    type: 'private',
    permissions: [BASE_PERMISSIONS.ROLES.LIST],
  },
  {
    component: PrivatePages.Roles.Save,
    label: 'Nova Função',
    path: '/funcoes/novo',
    type: 'private',
    permissions: [BASE_PERMISSIONS.ROLES.STORE],
  },
  {
    component: PrivatePages.Roles.Save,
    label: 'Editar Função',
    path: '/funcoes/:id',
    type: 'private',
    permissions: [BASE_PERMISSIONS.ROLES.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.Departments.List,
    label: 'Departamentos',
    path: '/departamentos',
    type: 'private',
    permissions: [BASE_PERMISSIONS.DEPARTMENTS.LIST],
  },
  {
    component: PrivatePages.Departments.Save,
    label: 'Novo Departamento',
    path: '/departamentos/novo',
    type: 'private',
    permissions: [BASE_PERMISSIONS.DEPARTMENTS.STORE],
  },
  {
    component: PrivatePages.Departments.Save,
    label: 'Editar Departamento',
    path: '/departamentos/:id',
    type: 'private',
    permissions: [BASE_PERMISSIONS.DEPARTMENTS.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.Leads.List,
    label: 'Leads',
    path: '/leads',
    type: 'private',
    permissions: [BASE_PERMISSIONS.LEADS.LIST],
  },
  {
    component: PrivatePages.Leads.Save,
    label: 'Novo Lead',
    path: '/leads/novo',
    type: 'private',
    permissions: [BASE_PERMISSIONS.LEADS.STORE],
  },
  {
    component: PrivatePages.Leads.Save,
    label: 'Editar Lead',
    path: '/leads/:id',
    type: 'private',
    permissions: [BASE_PERMISSIONS.LEADS.UPDATE],
  },
];

export const publicRoutes: IRouteInterface[] = [
  {
    component: PublicPages.NotFound,
    label: 'Página não encontrada',
    path: '*',
    type: 'public',
  },
];

export default [
  ...authRoutes,
  ...privateRoutes,
  ...AttendanceRouteList,
  ...whatsappRouteList,
  ...publicRoutes,
];
