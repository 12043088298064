export const BASE_PERMISSIONS = {
  USERS: {
    LIST: 'list-users',
    STORE: 'store-users',
    UPDATE: 'update-users',
    DESTROY: 'destroy-users',
    RESTORE: 'restore-users',
  },
  ROLES: {
    LIST: 'list-roles',
    STORE: 'store-roles',
    UPDATE: 'update-roles',
    DESTROY: 'destroy-roles',
    RESTORE: 'restore-roles',
  },
  DEPARTMENTS: {
    LIST: 'list-departments',
    STORE: 'store-departments',
    UPDATE: 'update-departments',
    DESTROY: 'destroy-departments',
    RESTORE: 'restore-departments',
  },
  LEADS: {
    LIST: 'list-leads',
    STORE: 'store-leads',
    UPDATE: 'update-leads',
    DESTROY: 'destroy-leads',
    RESTORE: 'restore-leads',
  },
};
