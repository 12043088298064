import styled from 'styled-components';

import MuiArchiveIcon from '@mui/icons-material/Archive';
import MuiCheckIcon from '@mui/icons-material/Check';
import MuiCloseIcon from '@mui/icons-material/Close';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MuiHourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

export const HelpOutlineIcon = styled(MuiHelpOutlineIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const CheckIcon = styled(MuiCheckIcon)`
  color: ${({ theme }) => theme.palette.success.main};
`;

export const ArchiveIcon = styled(MuiArchiveIcon)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const CloseIcon = styled(MuiCloseIcon)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const PendingIcon = styled(MuiHourglassEmptyIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
`;
