import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import Button from 'src/components/Button';
import { Opportunity } from 'src/modules/attendance/@types/models';
import attendanceApi from 'src/modules/attendance/services/api';
import { handleErrors } from 'src/utils/errors';

export type DestroyOpportunityDialogProps = {
  funnelId: number;
  opportunity: Opportunity;
  onClose: () => void;
  onSuccess?: () => void;
} & DialogProps;

const DestroyOpportunityDialog: React.FC<DestroyOpportunityDialogProps> = ({
  funnelId,
  opportunity,
  onSuccess,
  onClose,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const [canDelete, setShouldDelete] = useState(false);

  function handleClose() {
    if (loading) return;

    onClose();
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await attendanceApi.admin.funnels.opportunities.destroyFunnelOpportunity(
        funnelId,
        opportunity.id,
      );

      toast.success('Oportunidade Deletada!');

      if (onSuccess) onSuccess();

      onClose();
    } catch (error) {
      handleErrors(error, 'Erro ao Deletar Oportunidade.');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (rest.open) setShouldDelete(false);
  }, [rest.open]);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} {...rest}>
      <DialogTitle align="center">Excluir Oportunidade</DialogTitle>
      <DialogContent>
        <Grid container gap={2}>
          {/* <Grid item xs={12}> */}
          <DialogContentText>
            Confirma a exclusão da oportunidade de {opportunity.lead?.name}?
          </DialogContentText>
          {/* </Grid> */}

          {/* <Grid item xs={12}> */}
          <TextField
            color="error"
            fullWidth
            placeholder="Digite 'Excluir' sem aspas"
            size="small"
            onChange={(e) => setShouldDelete(e.target.value === 'Excluir')}
          />
          {/* </Grid> */}

          {canDelete && (
            <DialogContentText variant="body1" color="error">
              <i>
                Informações vinculadas a esse registro poderão ser excluidas,{' '}
                <strong>e não podem ser revertidas!</strong>
              </i>
            </DialogContentText>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Button
              startIcon={<CloseIcon />}
              color="error"
              onClick={onClose}
              disabled={loading}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<DeleteIcon />}
              color="error"
              variant="contained"
              loading={loading}
              onClick={handleDelete}
              disabled={!canDelete}
            >
              Excluir
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DestroyOpportunityDialog;
