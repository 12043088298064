import yup from 'src/libs/yup';
import { dateOperatorSchema } from 'src/validators/DefaultFilter.schema';

const DashboardFilterSchema = yup.object().shape({
  funnels: yup.array().of(yup.number()).label('Funil'),
  attendants: yup.array().of(yup.number()).label('Atendentes'),
  period: dateOperatorSchema.optional().label('Período'),
});

export default DashboardFilterSchema;
