import { FC, useCallback, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';

import { ListItemCollapseProps } from './interfaces';
import { Collapse } from './styles';

const ListItemCollapse: FC<ListItemCollapseProps> = ({
  children,
  primary,
  secondary,
  icon,
  collapseProps,
}) => {
  const [isOpen, setIsOpen] = useState(collapseProps?.in || false);

  const handleClick = useCallback(() => setIsOpen((value) => !value), []);

  return (
    <>
      <MuiListItemButton onClick={handleClick}>
        {icon && <MuiListItemIcon>{icon}</MuiListItemIcon>}

        <MuiListItemText primary={primary} secondary={secondary} />

        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </MuiListItemButton>
      <Collapse unmountOnExit timeout="auto" {...collapseProps} in={isOpen}>
        {children}
      </Collapse>
    </>
  );
};

export default ListItemCollapse;
