import { FC } from 'react';

import { STATUSES } from 'src/constants';

import {
  ArchiveIcon,
  CheckIcon,
  CloseIcon,
  HelpOutlineIcon,
  PendingIcon,
} from './styles';

interface StatusIconProps {
  status: string;
}

const StatusIcon: FC<StatusIconProps> = ({ status }) => {
  switch (status) {
    case STATUSES.ACTIVE:
      return <CheckIcon />;
    case STATUSES.ARCHIVED:
      return <ArchiveIcon titleAccess="Arquivado" />;
    case STATUSES.INACTIVE:
      return <CloseIcon />;
    case STATUSES.PENDING:
      return <PendingIcon titleAccess="Pendente" />;
    default:
      return <HelpOutlineIcon />;
  }
};

export default StatusIcon;
