import { PaletteMode } from '@mui/material';
import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { ptBR as gridPtBR } from '@mui/x-data-grid';
import { ptBR as datePtBR } from '@mui/x-date-pickers';
// import type {} from '@mui/x-date-pickers/themeAugmentation';

export function getTheme(mode: PaletteMode) {
  return createTheme(
    {
      palette: { mode: mode },
    },
    gridPtBR,
    datePtBR,
    corePtBR,
  );
}
