export const WHATSAPP_PERMISSIONS = {
  Z_API_INSTANCES: {
    LIST: 'list-z-api-instances',
    STORE: 'store-z-api-instances',
    UPDATE: 'update-z-api-instances',
    DESTROY: 'destroy-z-api-instances',
    RESTORE: 'restore-z-api-instances',
  },
  OMNICHANNEL: {
    LIST: 'list-omnichannel',
    LIST_ALL_CHATS: 'list-omnichannel-all-chats',
    LIST_FREE_CHATS: 'list-omnichannel-free-chats',
  },
};
