import { FC } from 'react';

import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { ButtonProps } from './interfaces';

const Button: FC<ButtonProps> = ({
  children,
  loading,
  startIcon,
  disabled,
  ...rest
}) => {
  const progressColor = !!rest.color ? rest.color : 'primary';

  return (
    <MuiButton
      startIcon={
        loading ? (
          <CircularProgress color={progressColor} size={24} />
        ) : (
          startIcon
        )
      }
      disabled={disabled || loading}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
